<template>
  <div>
    <p
      class="flag-notification"
      :class="{ 'flag-notification--error': errorFlag, ' flag-notification--success': successFlag }"
      v-if="errorFlag || successFlag"
    >
      {{ message }}
    </p>
    <div class="pa-12">
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <page-title>
            Días de vigencia de la solicitud
          </page-title>
          <span>
            Máxima cantidad de días que tendrán tus postulantes para responder un Cuestionario desde
            el momento en que reciba la solicitud.
          </span>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <span class="form-label">Días</span>
          <div>
            <v-select
              v-model="expirationDays"
              :items="options"
              outlined
              dense
              persistent-hint
              hint="De 3 a 7 días"
            />
          </div>
        </v-col>
      </v-row>
      <div class="mt-6 d-flex justify-start">
        <btn
          :loading="false"
          @click="updateSettings"
          :disabled="!validSave"
          color="primary"
        >
          Guardar cambios
        </btn>
      </div>
    </div>
  </div>
</template>

<script>
import { getExpirationDays, updateExpirationDays } from '../../../../../api/kf-settings';
import btn from '../../../../ui/btn.vue';
import PageTitle from '../../../../ui/page-title';

export default {
  components: {
    btn,
    PageTitle,
  },
  data() {
    return {
      validSave: true,
      successFlag: false,
      errorFlag: false,
      message: '',
      options: [3, 4, 5, 6, 7],
      expirationDays: null,
    };
  },
  props: {
    role: { type: String, default: () => '' },
  },
  beforeMount() {
    this.setExpirationDays();
  },
  methods: {
    setExpirationDays() {
      getExpirationDays()
        .then(res => {
          this.expirationDays = res.expirationDays;
        })
        .catch(() => {
          this.errorFlag = true;
          this.message = 'No se pudieron obtener los días de vigencia. Recarga la página';
        });
    },
    updateSettings() {
      const params = {
        expirationDays: this.expirationDays,
      };
      this.errorFlag = false;
      this.successFlag = false;
      this.validSave = false;

      updateExpirationDays(params)
        .then(() => {
          this.errorFlag = false;
          this.successFlag = true;
          this.message = 'Días de vigencia actualizados exitosamente';
        })
        .catch(() => {
          this.successFlag = false;
          this.errorFlag = true;
          this.message = 'Hubo un error al actualizar los días de vigencia. Intenta nuevamente';
        });

      this.validSave = true;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>

/* eslint-disable max-params */
/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import getJWTHeaders from '../utils/jwt';

export function create(stageId, applicantId, assignMs, applicantOrigin, current = true, orderNumber = 0) {
  return axios.post('/api/v1/applicant_stages', decamelizeKeys({
    applicantStage: {
      stageId,
      applicantId,
      assignMs,
      applicantOrigin,
      current,
      orderNumber,
    },
  }))
    .then(res => camelizeKeys(res));
}

export function getAll({ stageId, page }) {
  return axios.get(`/api/v1/applicant_stages?stage=${stageId}&page=${page}`)
    .then(res => camelizeKeys(res));
}

export function getMicroserviceData(id, jwtToken) {
  return axios.get(`/api/v1/applicant_stages/${id}`, {
    headers: getJWTHeaders(jwtToken),
  })
    .then(res => camelizeKeys(res));
}

export function filterBy(params, stage, page) {
  const paramsToString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');

  return axios.get(`/api/v1/applicant_stages?stage=${stage}&page=${page}&${paramsToString}`)
    .then(res => camelizeKeys(res));
}

export function discard(applicantStageId, discardReason) {
  return axios.post(`/api/v1/applicant_stages/${applicantStageId}/discard`, decamelizeKeys({
    applicantStage: {
      discardReason,
    },
  }))
    .then(res => camelizeKeys(res));
}

export function move(applicantStageId, destinationStageId, orderNumber, microserviceStageId) {
  return axios.post(`/api/v1/applicant_stages/${applicantStageId}/move`, decamelizeKeys({
    applicantStage: {
      destinationStageId,
      orderNumber,
      microserviceStageId,
    },
  }))
    .then(res => camelizeKeys(res));
}

export function arrMove(applicantsIds, destinationStageId, orderNumber, microserviceStageId) {
  const moreThanTwo = applicantsIds.length > 2;
  let url = moreThanTwo ? '/api/v1/applicant_stages/move_large_batch' : '/api/v1/applicant_stages/move_small_batch';

  return axios.post(url, decamelizeKeys({
    applicantStage: {
      ids: applicantsIds,
      destinationStageId,
      orderNumber,
      microserviceStageId,
    },
  }))
    .then(res => camelizeKeys(res));
}

export function update(applicantStageId, destinationStageId, microserviceStageId, params = {}) {
  return axios.put(`/api/v1/applicant_stages/${applicantStageId}`, decamelizeKeys({
    applicantStage: {
      destinationStageId,
      microserviceStageId,
      ...decamelizeKeys(params),
    },
  }))
    .then(res => camelizeKeys(res));
}

export function unhire(params) {
  return axios.patch(`/api/v1/applicant_stages/${params.id}`, decamelizeKeys({
    applicantStage: params,
  }))
    .then(res => camelizeKeys(res));
}

export function getDefaultDiscardReasons() {
  return axios.get('/api/v1/default_discard_reasons')
    .then(res => camelizeKeys(res));
}

export function getEmailStatus(testAssignmentId) {
  return axios.get(`/api/v1/emails/${testAssignmentId}`)
    .then(res => res.data.status);
}

export default {
  data() {
    return {
      isDisclaimerShowing: false,
    };
  },
  methods: {
    showDisclaimer() {
      this.isDisclaimerShowing = true;
    },
    closeDisclaimer() {
      this.isDisclaimerShowing = false;
    },
  },
};

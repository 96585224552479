import { render, staticRenderFns } from "./custom-email.vue?vue&type=template&id=003ed50e&scoped=true"
import script from "./custom-email.vue?vue&type=script&lang=js"
export * from "./custom-email.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "003ed50e",
  null
  
)

export default component.exports
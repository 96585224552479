<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <ms-icons
            :ms-id="3"
            height="40"
            width="40"
            class="text-haunter"
          />
        </v-col>
        <v-col
          cols="12"
          md="11"
          class="d-flex align-center"
        >
          <span>Carga Curricular</span>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <settings-kv />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import SettingsKv from '../../../../keyvitae/components/kv-settings.vue';
import MsIcons from '../../../../ms-icons.vue';

export default {
  components: {
    SettingsKv,
    MsIcons,
  },
  props: {
    currentUser: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>

</style>

import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';

import processes from './processes';
import process from './process';
import tour from './tour';
import toast from './toast';
import ecommerce from './ecommerce';
import buk from './buk';
import talana from './talana';
import integrations from './integrations';
import kc_plans from './kc_plans';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    processes,
    process,
    tour,
    toast,
    ecommerce,
    buk,
    talana,
    integrations,
    kc_plans,
  },
});

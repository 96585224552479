<template>
  <component
    :is="iconSelected"
    :width="width"
    :height="height"
  />
</template>
<script>
import Ms1 from './ms-icons/ms-1.vue';
import Ms2 from './ms-icons/ms-2.vue';
import Ms3 from './ms-icons/ms-3.vue';
import Ms4 from './ms-icons/ms-4.vue';
import Ms5 from './ms-icons/ms-5.vue';
import Ms6 from './ms-icons/ms-6.vue';
import Ms7 from './ms-icons/ms-7.vue';
import Ms8 from './ms-icons/ms-8.vue';

export default {
  props: {
    msId: {
      type: Number,
      required: true,
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: 'auto',
    },
  },
  components: {
    Ms1,
    Ms2,
    Ms3,
    Ms4,
    Ms5,
    Ms6,
    Ms7,
    Ms8,
  },
  computed: {
    iconSelected() {
      switch (this.msId) {
      case 1:
        return Ms1;
      case 2:
        return Ms2;
      case 3:
        return Ms3;
      case 4:
        return Ms4;
      case 5:
        return Ms5;
      case 6:
        return Ms6;
      case 7:
        return Ms7;
      case 8:
        return Ms8;
      default: return Ms1;
      }
    },
  },
};
</script>

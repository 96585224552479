<template>
  <div class="pa-6">
    <page-title>Correos de Evaluaciones</page-title>
    <page-subtitle>
      <div>
        <v-col
          cols="12"
          md="9"
        >
          Aquí puedes configurar los correos que se enviarán a tus postulantes con las diferentes
          solicitudes de evaluación. Estos correos se envían automáticamente cuando asignes evaluaciones.
        </v-col>
      </div>
    </page-subtitle>
    <v-expansion-panels class="mb-10">
      <v-expansion-panel>
        <v-expansion-panel-header class="border-top-blue py-1">
          <v-row class="my-0">
            <v-col
              cols="12"
              md="1"
            >
              <v-icon
                color="darkgray-dark"
              >
                {{ mdiCardAccountMail }}
              </v-icon>
            </v-col>
            <v-col
              cols="12"
              md="11"
              class="d-flex align-center"
            >
              <span class="text-subtitle-1-talana darkgray-dark--text">
                Edición de remitente
              </span>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <page-subtitle>
            <div>
              <v-col
                cols="12"
                md="9"
              >
                Personaliza el remitente de los correos que recibirán tus postulantes. <br>
                Escoge el nombre con el que se identificarán los emails de tu organización
              </v-col>
            </div>
          </page-subtitle>
          <v-form
            ref="form"
            lazy-validation
            v-model="valid"
          >
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <label for="name">Nombre</label>
                  <v-text-field
                    type="text"
                    id="name"
                    outlined
                    dense
                    v-model="name"
                    :rules="[rules.required]"
                    hint="*Si tu proceso es confidencial, el remitente llevará el nombre de fantasía."
                    persistent-hint
                  />
                </v-col>
              </v-row>
              <button
                class="btn btn-primary"
                @click="saveSender"               
              >
                Guardar
              </button>
              <button
                class="btn btn-outline-primary"
                @click="restoreSender"
              >
                Reestablecer
              </button>
            </v-container>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-tabs
      class="d-flex justify-center mb-6"
      background-color="transparent"
      color="var(--haunter-purple)"
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-if="complements.includes('km')"
        class="letter-spacing-0 gap-2"
      >
        <ms-icons
          :ms-id="5"
          height="40"
          width="40"
        />
        Generales
      </v-tab>
      <v-tab
        v-if="complements.includes('ks')"
        class="letter-spacing-0 gap-2"
      >
        <ms-icons
          :ms-id="1"
          height="40"
          width="40"          
        />
        Evaluaciones Psicométricas
      </v-tab>
      <v-tab
        v-if="complements.includes('kf')"
        class="letter-spacing-0 gap-2"
      >
        <ms-icons
          :ms-id="8"
          height="40"
          width="40"
        />
        Cuestionarios
      </v-tab>
      <v-tab
        v-if="complements.includes('kg')"
        class="letter-spacing-0 gap-2"
      >
        <ms-icons
          :ms-id="6"
          height="40"
          width="40"
        />
        Evaluaciones Gamificadas
      </v-tab>
      <v-tab
        v-if="complements.includes('ki')"
        class="letter-spacing-0 gap-2"
      >
        <ms-icons
          :ms-id="4"
          height="40"
          width="40"
        />
        Videoentrevistas
      </v-tab>
      <v-tab
        v-if="complements.includes('kv')"
        class="letter-spacing-0 gap-2"
      >
        <ms-icons
          :ms-id="3"
          height="40"
          width="40"
        />
        Carga Curricular
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-if="complements.includes('km')">
        <km-templates
          class="pa-6"
        />
      </v-tab-item>
      <v-tab-item v-if="complements.includes('ks')">
        <ks-email-templates
          class="pa-6"
          :company="company"
        />
      </v-tab-item>
      <v-tab-item v-if="complements.includes('kf')">
        <kf-email-templates
          class="pa-6"
          :company="company"
        />
      </v-tab-item>
      <v-tab-item v-if="complements.includes('kg')">
        <kg-email-templates
          class="pa-6"
          :company="company"
        />
      </v-tab-item>
      <v-tab-item v-if="complements.includes('ki')">
        <ki-email-templates
          class="pa-6"
          :company="company"
        />
      </v-tab-item>
      <v-tab-item v-if="complements.includes('kv')">
        <kv-email-templates
          class="pa-6"
          :company="company"
        />
      </v-tab-item>
    </v-tabs-items>
    <snackbar
      :type="'success'"
      :title="'¡Éxito!'"
      :snackbar="success"
      :text="message"
      @toggle="toggleSnackbar"
    />
    <snackbar
      :type="'error'"
      :title="'Error'"
      :snackbar="error"
      :text="message"
      @toggle="toggleSnackbar"
    />
  </div>
</template>

<script>
import { mdiCardAccountMail } from '@mdi/js';
import { updateSenderEmail, restoreSenderEmail } from '../../../api/key-clouding-api';
import MsIcons from '../../ms-icons.vue';
import PageTitle from '../../ui/page-title.vue';
import PageSubtitle from '../../ui/page-subtitle.vue';
import Snackbar from '../../ui/snackbar.vue';
import KsEmailTemplates from './ks/ks-email-templates.vue';
import KgEmailTemplates from './kg/kg-email-templates.vue';
import KiEmailTemplates from './ki/ki-email-templates.vue';
import KvEmailTemplates from './kv/kv-email-templates.vue';
import KfEmailTemplates from './kf/kf-email-templates.vue';
import KmTemplates from '../../keymailer/index';

export default {
  components: {
    KsEmailTemplates,
    KgEmailTemplates,
    KiEmailTemplates,
    KvEmailTemplates,
    KfEmailTemplates,
    PageTitle,
    PageSubtitle,
    Snackbar,
    MsIcons,
    KmTemplates,
  },
  props: {
    complements: {
      type: Array,
      default: () => [],
    },
    company: {
      type: Object,
      required: true,
    },
    currentUserId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      valid: true,
      success: false,
      error: false,
      message: '',
      mdiCardAccountMail,
      tab: null,
      rules: {
        required: value => !!value || 'Este campo es obligatorio.',
      },
    };
  },
  beforeMount() {
    this.setInitialSender();
  },
  methods: {
    setInitialSender() {
      if (this.company.custom_email_sender) {
        this.name = this.company.email_sender_name;
      } else {
        this.name = this.company.razon_social;
      }
    },
    saveSender() {
      if (this.$refs.form.validate()) {
        const params = {
          'company_id': this.company.id,
          emailSenderName: this.name,
        };
        updateSenderEmail(params)
          .then((res) => {
            if (res.isSuccess) {
              this.success = true;
              this.message = 'Remitente actualizado exitosamente';
            } else {
              this.error = true;
              this.message = 'Hubo un error con la solicitud, inténtalo más tarde';
            }
          });
      }
    },
    restoreSender() {
      const params = {
        'company_id': this.company.id,
        userId: this.currentUserId,
      };
      restoreSenderEmail(params)
        .then((res) => {
          if (res.isSuccess) {
            this.success = true;
            this.message = 'Remitente restaurado exitosamente';
            this.name = this.company.razon_social;
          } else {
            this.error = true;
            this.message = 'Hubo un error con la solicitud, inténtalo más tarde';
          }
        });
    },
    toggleSnackbar(bool) {
      this.success = bool;
      this.error = bool;
    },
  },
};
</script>

<style lang="scss" scoped>
  .border-top-blue {
    border-top: 5px solid var(--haunter-purple);
  }
</style>

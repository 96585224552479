import axios from 'axios';
import { camelizeKeys } from 'humps';

function getSettings() {
  return axios
    .get('/api/v1/ks/ks_settings')
    .then(res => camelizeKeys(res.data))
    .catch(err => {
      throw new Error(err);
    });
}

function updateSetting(name, value) {
  return axios
    .patch('/api/v1/ks/ks_settings/update_setting', {
      setting: {
        name,
        value,
      },
    })
    .then(res => camelizeKeys(res.data));
}

function updateInputSetting(days, subject, reuse_test_duration) {
  return axios
    .patch('/api/v1/ks/ks_settings/update_input_setting', {
      setting: {
        days,
        subject,
        reuse_test_duration,
      },
    });
}

function sendEmailTest(email) {
  return axios
    .post('/api/v1/ks/ks_settings/send_email_test', {
      user: {
        email,
      },
    });
}

function getEmail() {
  return axios
    .get('/api/v1/ks/ks_settings/email')
    .then(res => camelizeKeys(res.data));
}

function updateEmailContent(content) {
  return axios
    .patch('/api/v1/ks/ks_settings/update_email', { email: { content } })
    .then(res => camelizeKeys(res.data));
}

export default {
  getSettings,
  updateSetting,
  updateInputSetting,
  sendEmailTest,
  getEmail,
  updateEmailContent,
};

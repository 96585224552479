const route = '/api/v1/buk';
export const getRoles = async ({ page }) => {
  try {
    const response = await fetch(`${route}/get_roles_buk?page=${page}`);
    let data = await response.json();
    const total_pages = data.pagination.total_pages
    if (total_pages > 1) {
      for (let step = 2; step <= total_pages; step++) {
        const response = await fetch(`${route}/get_roles_buk?page=${step}`);
        const data_json = await response.json()
        data.data = [...data.data, ...data_json.data]
      }
    }

    return data;
  } catch (error) {
    return {
      error,
    };
  }
};

export const getCompanies = async ({ page }) => {
  try {
    const response = await fetch(`${route}/get_companies_buk?page=${page}`);
    let data = await response.json();
    const total_pages = data.pagination.total_pages
    if (total_pages > 1) {
      for (let step = 2; step <= total_pages; step++) {
        const response = await fetch(`${route}/get_companies_buk?page=${step}`);
        const data_json = await response.json()
        data.data = [...data.data, ...data_json.data]
      }
    }

    return data;
  } catch (error) {
    return {
      error,
    };
  }
};

export const getAreas = async ({ page }) => {
  try {
    const response = await fetch(`${route}/get_areas_buk?page=${page}`);
    let data = await response.json();
    const total_pages = data.pagination.total_pages
    if (total_pages > 1) {
      for (let step = 2; step <= total_pages; step++) {
        const response = await fetch(`${route}/get_areas_buk?page=${step}`);
        const data_json = await response.json()
        data.data = [...data.data, ...data_json.data]
      }
    }

    return data;
  } catch (error) {
    return {
      error,
    };
  }
};

export const getBukCompanyIntegration = async () => {
  try {
    const response = await fetch(`${route}/get_company_integration/`);
    const data = await response.json();

    return data;
  } catch (error) {
    return {
      error,
    };
  }
};

export const createProcess = async (params) => {
  try {
    const response = await fetch(`${route}/create_process_buk`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    return await response.json();
  } catch (error) {
    return {
      code: 500,
      error,
    };
  }
};

export const setToken = async (params) => {
  try {
    const response = await fetch(`${route}/set_token_buk`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    return await response.json();
  } catch (error) {
    return {
      error,
    };
  }
};

export const getBukData = async ({ companyId }) => {
  try {
    const response = await fetch(`${route}/get_buk_data?company_id=${companyId}`);

    return await response.json();
  } catch (error) {
    return {
      error,
    };
  }
};

export const toggleBukState = async () => {
  try {
    const response = await fetch(`${route}/toggle_buk_state`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  } catch (error) {
    return {
      error,
    };
  }
};

export const getProcesses = async ({ page }) => {
  try {
    const response = await fetch(`${route}/get_selection_process_buk?page=${page}`);
    let data = await response.json();
    const total_pages = data.pagination.total_pages
    if (total_pages > 1) {
      for (let step = 2; step <= total_pages; step++) {
        const response = await fetch(`${route}/get_selection_process_buk?page=${step}`);
        const data_json = await response.json()
        data.data = [...data.data, ...data_json.data]
      }
    }

    return data;
  } catch (error) {
    return {
      error,
    };
  }
};

export const getProcessBukBySuiteId = async (id) => {
  try {
    const response = await fetch(`${route}/get_buk_selection_process?process_id=${id}`);
    const data = await response.json();

    return data;
  } catch (error) {
    return {
      error,
    };
  }
};

export const getHiredApplicant = async (params) => {
  try {
    const response = await
    fetch(`${route}/get_buk_applicant?applicant_id=${params.applicantId}&company_id=${params.companyId}&selection_process_id=${params.processId}`);
    const data = await response.json();

    return data;
  } catch (error) {
    return {
      error,
    };
  }
};

import { render, staticRenderFns } from "./kf-settings.vue?vue&type=template&id=494163e8&scoped=true"
import script from "./kf-settings.vue?vue&type=script&lang=js"
export * from "./kf-settings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "494163e8",
  null
  
)

export default component.exports
import { camelizeKeys, decamelizeKeys } from 'humps';

const route = '/api/v1/integrations/talana';

export const requestTalana = async (location) => {
  try {
    const response = await fetch(`${route}/send_email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ location }),
    });

    return await response.json();
  } catch (error) {
    return {
      error,
    };
  }
};

export const toggleTalanaState = async () => {
  try {
    const response = await fetch(`${route}/changeState`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  } catch (error) {
    return {
      error,
    };
  }
};

export const getTalanaState = async () => {
  try {
    const response = await fetch(`${route}/integrate`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  } catch (error) {
    return { error };
  }
};

export const getTalanaRazonSocial = async () => {
  try {
    const response = await fetch(`${route}/get_rs`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  } catch (error) {
    return { error };
  }
};

export const getTalanaJobTitle = async () => {
  try {
    const response = await fetch(`${route}/jobTitle`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  } catch (error) {
    return { error };
  }
};

export const getTalanaJornadaLaboral = async () => {
  try {
    const response = await fetch(`${route}/jornadaLaboral`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  } catch (error) {
    return { error };
  }
};

export const getTalanaTipoContrato = async () => {
  try {
    const response = await fetch(`${route}/tipoContrato`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  } catch (error) {
    return { error };
  }
};

export const getApplicants = async (params) => {
  try {
    const response = await fetch(`${route}/get_applicants`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(decamelizeKeys(params)),
    });
    const data = await response.json();

    return camelizeKeys(data);
  } catch (error) {
    return { error };
  }
};

export const getTalanaSucursales = async () => {
  try {
    const response = await fetch(`${route}/sucursal`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  } catch (error) {
    return { error };
  }
};

export const getTalanaUnidadOrganizacional = async () => {
  try {
    const response = await fetch(`${route}/unidadOrganizacional`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  } catch (error) {
    return { error };
  }
};

export const getTalanaCentroCosto = async () => {
  try {
    const response = await fetch(`${route}/centroCosto`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  } catch (error) {
    return { error };
  }
};

export const getTalanaTipoPrevision = async () => {
  try {
    const response = await fetch(`${route}/prevision`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  } catch (error) {
    return { error };
  }
};

export const getTalanaAFP = async () => {
  try {
    const response = await fetch(`${route}/afp`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  } catch (error) {
    return { error };
  }
};

export const getTalanaJefeDirecto = async () => {
  try {
    const response = await fetch(`${route}/get_jefe_directo`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  } catch (error) {
    return { error };
  }
};

export const createTalanaPersona = async (params) => {
  try {
    const response = await fetch(`${route}/createPersonas`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(decamelizeKeys(params)),
    });

    return await response.json();
  } catch (error) {
    return { error };
  }
};

export const getTalanaPaises = async () => {
  try {
    const response = await fetch(`${route}/pais`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  } catch (error) {
    return { error };
  }
};

export const checkTalanaEmpoyees = async (params) => {
  try {
    const response = await fetch(`${route}/checkEmployee`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(decamelizeKeys(params)),
    });

    return await response.json();
  } catch (error) {
    return { error };
  }
};

export const getTalanaActivatedServices = async () => {
  try {
    const response = await fetch(`${route}/activated_services`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  } catch (error) {
    return { error };
  }
};

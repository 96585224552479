import { extend } from 'vee-validate';
import xlsx from 'xlsx';
import { required, regex, length, email, min, max, between, size } from 'vee-validate/dist/rules';
import parsePhoneNumber from 'libphonenumber-js';

extend('required', required);

extend('regex', regex);

extend('email', email);

extend('length', length);

extend('min', min);

extend('max', max);

extend('between', between);

extend('size', size);

// eslint-disable-next-line max-len, vue/max-len
const EMAIL_VALID_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const MAX_APPLICANTS_PROCESS = 2500;

extend('extension', {
  validate(value, args) {
    if (value && value.name) {
      const regex = new RegExp(`${args.format}$`);
      if (regex.test(value.name)) {
        return true;
      }

      return 'Extensión de archivo no válida';
    }

    return true;
  },
  params: ['format'],
});

/* eslint-disable max-statements */
/* eslint-disable no-use-before-define */
extend('validsheet', async (value) => {
  try {
    const applicants = await getApplicants(value);
    const rowsWithInvalidEmails = [];
    const rowsWithMissingName = [];

    applicants.forEach((row, i) => {
      if (!EMAIL_VALID_REGEX.test(row['Correo electrónico'])) {
        rowsWithInvalidEmails.push({ ...row, rowNumber: i + 2 });
      }

      if (!row.Nombre || !row.Apellido) {
        rowsWithMissingName.push({ ...row, rowNumber: i + 2 });
      }
    });

    const errorMessages = [];

    if (applicants.length > MAX_APPLICANTS_PROCESS) {
      errorMessages.push(
        `No se pueden cargar más de ${MAX_APPLICANTS_PROCESS} postulantes en este proceso`,
      );
    }

    const invalidEmails = [];

    if (rowsWithInvalidEmails.length) {
      rowsWithInvalidEmails.map((r) => invalidEmails.push(r['Correo electrónico']));
      errorMessages.push(
        `Hay ${rowsWithInvalidEmails.length} postulante${plural(rowsWithInvalidEmails.length)} sin correo válido.
        Revisa el formato de estos y asegúrate que no contengan espacios en blanco antes o después del correo:
        ${invalidEmails.join(' ')}`,
      );
    }

    if (rowsWithMissingName.length) {
      errorMessages.push(
        `Hay ${rowsWithMissingName.length} postulante${plural(rowsWithMissingName.length)} sin nombre y/o apellido`,
      );
    }

    const invalidPhones = [];

    applicants.forEach((row) => {
      const rawPhone = String(row['Teléfono'] ? row['Teléfono'] : '')?.trim();
      if (!rawPhone) return;
      const phoneNumber = parsePhoneNumber(`${rawPhone.charAt(0) === '+' ? '' : '+'}${rawPhone}`);
      if (!phoneNumber?.isValid()) {
        invalidPhones.push(rawPhone);
      }
    });

    if (invalidPhones.length > 0) {
      errorMessages.push(
        `Hay ${invalidPhones.length} postulante${plural(invalidPhones.length)} sin télefono válido.
        los numeros telefonicos son los siguientes:
        ${invalidPhones.join(', ')}`,
      );
    }

    return errorMessages.length > 0 ? errorMessages.join('. ') : true;
  } catch (err) {
    return 'La plantilla no es válida.';
  }
});

extend('notduplicates', {
  validate(value, { existing }) {
    if (value.id && (existing && existing.length > 0)) {
      return !(existing === value.id || existing.includes(value.id));
    }

    return true;
  },
  params: ['existing'],
  message: 'La evaluación ya existe',
});

extend('moreThan', {
  validate(value, { floor, method }) {
    return (method(value) >= floor);
  },
  params: ['floor', 'method'],
  message: 'Debes tener más de {floor} caracteres',
});

extend('lessThan', {
  validate(value, { limit, method }) {
    return (method(value) <= limit);
  },
  params: ['limit', 'method'],
  message: 'No puedes superar los {limit} caracteres',
});

extend('lesserThan', {
  validate(value, { limit, method }) {
    return (method(value) <= limit);
  },
  params: ['limit', 'method'],
  message: 'Debes ingresar un valor menor a {limit}',
});

extend('greaterThan', {
  validate(value, { floor, method }) {
    return (method(value) > floor);
  },
  params: ['floor', 'method'],
  message: 'Debes ingresar un valor mayor a {floor}',
});

function getApplicants(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (ev) => {
      let binary = '';
      const bytes = new Uint8Array(ev.target.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      const wb = xlsx.read(binary, { type: 'binary' });

      if (wb.Sheets && wb.Sheets.Postulantes) {
        const fixedSheet = updateSheetRange(wb.Sheets.Postulantes);
        const applicants = xlsx.utils.sheet_to_json(fixedSheet);

        resolve(applicants);
      } else {
        reject('No sheet');
      }
    };

    reader.readAsArrayBuffer(file);
  });
}

function updateSheetRange(sheet) {
  // https://github.com/SheetJS/sheetjs/wiki/General-Utility-Functions#updating-worksheet-range
  const range = { s: { r: Infinity, c: Infinity }, e: { r: 0, c: 0 } };
  Object.keys(sheet).filter((x) => x.charAt(0) !== '!').map(xlsx.utils.decode_cell)
    .forEach((x) => {
      range.s.c = Math.min(range.s.c, x.c);
      range.s.r = Math.min(range.s.r, x.r);
      range.e.c = 4; // We remove the instructions from the new sheet
      range.e.r = Math.max(range.e.r, x.r);
    });
  sheet['!ref'] = xlsx.utils.encode_range(range);

  return sheet;
}

function plural(number) {
  return number === 1 ? '' : 's';
}

extend('matchPassword', {
  validate(value, { password }) {
    return value === password;
  },
  params: ['password'],
  message: 'Las contraseñas deben coincidir',
});

import axios from 'axios'
import { camelizeKeys } from 'humps'

export function loginTestLaborum(params) {
  return axios.post('/api/v1/company/scraping', params)
    .then(res => camelizeKeys(res.data));
}

export function loginTestTrabajando(params) {
  return axios.post('/api/v1/company/trabajando_scraping', params)
    .then(res => camelizeKeys(res.data));
}

export function loginTestChiletrabajos(params) {
  return axios.post('/api/v1/company/login_chiletrabajos', params)
    .then(res => res.data);
}

export function saveCredentials(params) {
  return axios.post('/api/v1/integrations/int_companies/create', params)
    .then(res => camelizeKeys(res.data));
}

export function getIntConfigs() {
  return axios.get('/api/v1/integrations/int_companies')
    .then(res => camelizeKeys(res.data));
}

export function createOrUpdateIntSelectionProcess(processId, intCompanyId, offer, portal) {
  return axios.post('/api/v1/integrations/int_selection_processes/create_or_update',
    { 'selection_process_id': processId, 'int_company_id': intCompanyId,
      'json_process_external': offer, 'portal': portal },
  )
    .then(res => camelizeKeys(res.data));
}

export function savePostLink(params) {
  return axios.patch('/api/v1/integrations/int_selection_processes/set_offer', params)
    .then(res => camelizeKeys(res.data));
}

// export function getlaborumPostLinked(processId, intCompanyId) {
//   return axios.post('/api/v1/integrations/int_selection_processes/linked_offer',
//     { 'selection_process_id': processId, 'int_company_id': intCompanyId },
//   )
//     .then(res => camelizeKeys(res.data));
// }

export function laborumPostLinkTest(params) {
  return axios.post('/api/v1/company/laborum_check_offer', params)
    .then(res => camelizeKeys(res.data));
}

export function trabajandoPostLinkTest(params) {
  return axios.post('/api/v1/company/trabajando_check_offer', params)
    .then(res => camelizeKeys(res.data));
}

export function chiletrabajosPostLinkTest(params) {
  return axios.post('/api/v1/company/check_offer_chiletrabajos', params)
    .then(res => camelizeKeys(res.data));
}

export function changeIntCompaniesState(params) {
  return axios.patch('/api/v1/integrations/int_companies/change_state', params)
    .then(res => camelizeKeys(res.data));
}

export function editCredentials(params) {
  return axios.patch('/api/v1/integrations/int_companies/edit', params)
    .then(res => camelizeKeys(res.data));
}

export function datosFicha() {
  return axios.get('/api/v1/integrations/datos_ficha')
    .then(res => camelizeKeys(res.data));
}

export function getIntProcess(params) {
  return axios.post('/api/v1/integrations/int_selection_processes/get_int_process',
    { 'selection_process_id': params.processId, 'int_company_id': params.intCompanyId })
    .then(res => camelizeKeys(res));
}

export function getIntSelectionProcess(processId, intCompanyId) {
  return axios.post('/api/v1/integrations/int_selection_processes/get_int_process',
    { 'selection_process_id': processId, 'int_company_id': intCompanyId })
    .then(res => camelizeKeys(res.data));
}

export function publishLaborumOffer(processId, intCompanyIdLaborum) {
  return axios.post('/api/v1/company/activate_offer',
    { 'selection_process_id': processId, 'int_company_id': intCompanyIdLaborum })
    .then(res => camelizeKeys(res))
}

export function publishTrabajandoOffer(processId, intCompanyIdTrabajando) {
  return axios.post('/api/v1/company/trabajando_activate_offer',
    { 'selection_process_id': processId, 'int_company_id': intCompanyIdTrabajando })
    .then(res => camelizeKeys(res));
}

export function publishChiletrabajosOffer(processId, intCompanyIdChiletrabajos) {
  return axios.post('/api/v1/company/publish_chiletrabajos',
    { 'selection_process_id': processId, 'int_company_id': intCompanyIdChiletrabajos })
    .then(res => camelizeKeys(res));
}

export function getTrabajandoCombos() {
  return axios.get('/api/v1/integrations/datos_ficha_trabajando')
    .then(res => camelizeKeys(res.data.data));
}

export function getChiletrabajosCombos() {
  return axios.get('/api/v1/integrations/datos_ficha_chiletrabajos')
    .then(res => camelizeKeys(res.data.data));
}

export function createUniqueCard(params) {
  return axios.post('/api/v1/integrations/unique_card/create', params)
    .then(res => camelizeKeys(res.data));
}

export function getUniqueCard(params) {
  return axios.post('/api/v1/integrations/unique_card/get', params)
    .then(res => camelizeKeys(res.data));
}

export function setLinkedinId(params) {
  return axios.post('/api/v1/integrations/set_linkedin_id', params)
    .then(res => camelizeKeys(res.data));
}

export function editLinkedinId(params) {
  return axios.patch('/api/v1/integrations/change_linkedin_id', params)
    .then(res => camelizeKeys(res.data));
}

export function changeLinkedinStatus(params) {
  return axios.patch('/api/v1/integrations/change_linkedin_status', params)
    .then(res => camelizeKeys(res.data));
}

<template>
  <ul class="input-errors">
    <li
      v-for="error in errors"
      :key="error"
      class="input-error"
    >
      {{ error }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

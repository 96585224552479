<template>
  <div>
    <div class="d-flex justify-center">
      <v-slide-group
        show-arrows
        mandatory
        active-class="botón-color-talana"
        v-model="selected"
        class="mb-6"
      >
        <v-slide-item
          v-for="n in templatesOptions"
          :key="n.value"
          v-slot="{ active, toggle }"
        >
          <v-tooltip
            max-width="200"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 letter-spacing-0"
                :input-value="active"
                active-class="boton-color-talana white--text"
                depressed
                rounded
                @click="toggle"
              >
                {{ n.value }}
              </v-btn>
            </template>
            <span>{{ tabTooltip[n.value] }}</span>
          </v-tooltip>
        </v-slide-item>
      </v-slide-group>
    </div>
    <assignment-template
      v-if="selected === 0 && kgTemplates"
      :kg-templates="kgTemplates"
      :category="1"
      :company="company"
      @update="getTemplates"
    />
    <pending-template
      v-if="selected === 1 && kgTemplates"
      :kg-templates="kgTemplates"
      :category="2"
      :company="company"
      @update="getTemplates"
    />
    <expiration-template
      v-if="selected === 2 && kgTemplates"
      :kg-templates="kgTemplates"
      :category="3"
      :company="company"
      @update="getTemplates"
    />
    <answered-template
      v-if="selected === 3 && kgTemplates"
      :kg-templates="kgTemplates"
      :category="4"
      :company="company"
      @update="getTemplates"
    />
  </div>
</template>

<script>
import AssignmentTemplate from './components/assignment-template.vue';
import ExpirationTemplate from './components/expiration-template.vue';
import PendingTemplate from './components/pending-template.vue';
import AnsweredTemplate from './components/answered-template.vue';
import { getKgEmailTemplates } from '../../../../api/keygaming/email-templates';

export default {
  components: {
    AssignmentTemplate,
    ExpirationTemplate,
    PendingTemplate,
    AnsweredTemplate,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      templatesOptions: [
        { value: 'Asignación' },
        { value: 'Recordatorio' },
        { value: 'Expiración' },
        { value: 'Rendido' },
      ],
      selected: null,
      kgTemplates: null,
      tabTooltip: {
        'Asignación': `Se enviará a tus postulantes para informarles que tienen una nueva
          solicitud por realizar dentro de tu proceso`,
        'Recordatorio': `Funciona a modo de recordatorio, para postulantes que tengan una solicitud
          pendiente aún no realizada.`,
        'Expiración': `Transcurridos los días de vigencia que elegiste, este correo informa que la
          solicitud pendiente ya no puede ser realizada.`,
        'Rendido': 'Tras la realización de la solucitud, el postulante será informado que la recibiste.',
      },
    };
  },
  methods: {
    getTemplates() {
      getKgEmailTemplates()
        .then((res) => {
          this.kgTemplates = res.emailTemplates;
        });
    },
  },
  beforeMount() {
    this.getTemplates();
  },
};
</script>

<style>

</style>

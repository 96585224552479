<template>
  <div class="pa-6">
    <page-title>Configuración de evaluaciones</page-title>
    <v-expansion-panels>
      <ks-config
        v-if="complements.includes('ks')"
        :current-user="currentUser"
      />
      <kg-config v-if="complements.includes('kg')" />
      <kv-config
        v-if="complements.includes('kv')"
        :current-user="currentUser"
      />
      <ki-config v-if="complements.includes('ki')" />
      <kf-config v-if="complements.includes('kf')" />
    </v-expansion-panels>
  </div>
</template>

<script>
import KsConfig from './components/ks/ks-config.vue';
import KvConfig from './components/kv/kv-config.vue';
import KgConfig from './components/kg/kg-config.vue';
import KiConfig from './components/ki/ki-config.vue';
import KfConfig from './components/kf/kf-config.vue';
import PageTitle from '../../ui/page-title.vue';

export default {
  components: {
    KsConfig,
    KvConfig,
    KgConfig,
    KiConfig,
    KfConfig,
    PageTitle,
  },
  props: {
    currentUser: {
      type: Object,
      required: true,
    },
    complements: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
</style>

<template>
  <modal
    :open="open"
    :persistent="false"
    :icon="mdiEmail"
    max-width="1200"
    @close-modal="$emit('close-modal')"
    title="¿Cómo me ayuda Mailing?"
  >
    <div class="pa-10">
      <v-row>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column"
        >
          <div class="flex justify-center">
            <v-icon
              large
              class="mt-1 justify-center"
              color="var(--haunter-purple)"
            >
              {{ mdiMail }}
            </v-icon>
          </div>
          <div class="flex flex-column justify-center">
            <span class="text-center font-weight-bold">Qué</span>
            <p class="mt-4">
              Comunícate con tus postulantes en diferentes procesos de selección, cuándo quieras y cómo quieras.
            </p>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column"
        >
          <div class="flex justify-center">
            <v-icon
              x-large
              class="mt-1 justify-center"
              color="var(--haunter-purple)"
            >
              {{ mdiEmailEdit }}
            </v-icon>
          </div>
          <div class="flex flex-column justify-center">
            <span class="text-center font-weight-bold">Cómo</span>
            <p class="mt-4">
              Crea plantillas en esta sección. Luego, las podrás enviar desde tus  procesos de selección,
              cuando elijas la opción "Enviar Correos a postulantes".
            </p>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column"
        >
          <div class="flex justify-center">
            <v-icon
              x-large
              class="mt-1 justify-center"
              color="var(--haunter-purple)"
            >
              {{ mdiEmailCheck }}
            </v-icon>
          </div>
          <div class="flex flex-column justify-center">
            <span class="text-center font-weight-bold">Para qué</span>
            <p class="mt-4">
              Agiliza las comunicaciones con tu talento. Mantener a tus postulantes informados
              beneficia el valor de tu marca empleadora (Employer Branding).
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </modal>
</template>
<script>
import { mdiEmail, mdiEmailEdit, mdiMail, mdiEmailCheck } from '@mdi/js';
import modal from './../../../ui/modal.vue';

export default {
  components: {
    modal,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiEmail,
      mdiMail,
      mdiEmailEdit,
      mdiEmailCheck,
      disclaimerVisible: true,
    };
  },
};
</script>

import axios from 'axios';
import { camelizeKeys } from 'humps';

function getExpirationDays() {
  return axios
    .get('/api/v1/kg/kg_settings')
    .then(res => camelizeKeys(res.data))
    .catch(err => {
      throw new Error(err);
    });
}

function updateExpirationDays(days) {
  return axios
    .patch('/api/v1/kg/kg_settings/1', { 'expirationDays': days })
    .then(res => camelizeKeys(res.data))
    .catch(err => {
      throw new Error(err);
    });
}

export default {
  getExpirationDays,
  updateExpirationDays,
};

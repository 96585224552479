<template>
  <v-chip
    :outlined="outlined"
    :small="small"
    :large="large"
    :x-large="xLarge"
    :class="`body-2-talana kchip d-flex justify-center ${classProp} ${typeCheck(type)}`"
    :style="styleProp"
    :label="label"
  >
    <slot />
  </v-chip>
</template>
<script>
export default {
  props: {
    /** Le asigna un color, opciones validas:
     *
     * 'default',
     *
     * 'success',
     *
     * 'warning',
     *
     * 'info'
     *
     * ❗type: String
     */
    type: {
      type: String,
      default: 'default',
      validator: (prop) => [
        'default',
        'success',
        'warning',
        'info',
      ].includes(prop),
    },
    /** Define si el chip será outlined, si es false asume filled
      *
      * ❗type: Boolean
      */
    outlined: {
      type: Boolean,
      default: false,
    },
    /** Clases que se añadirán a 'class' del chip, Ej: Márgenes, paddings, tamaños de letra, etc
     *
     * ❗type: String
     *
    */
    classProp: {
      type: String,
      default: '',
    },
    /** Usar solamente bajo la necesidad de reemplazar una propiedad de Vuetify con '!important'
     *
     * ❗type: String
    */
    styleProp: {
      type: String,
      default: '',
    },
    /** Asigna tamaño small de Vuetify al chip
     *
     * ❗type: Boolean
     *
     */
    small: {
      type: Boolean,
      default: false,
    },
    /** Asigna tamaño large de Vuetify al chip
     *
     * ❗type: Boolean
     *
    */
    large: {
      type: Boolean,
      default: false,
    },
    /** Asigna tamaño xl de Vuetify al chip
     *
     * ❗type: Boolean
     *
    */
    xLarge: {
      type: Boolean,
      default: false,
    },
    /** La forma del chip pasa a ser cuadrada
     *
     * ❗type: Boolean
     *
    */
    label: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    typeCheck(type) {
      switch (type) {
      case 'default':
        return 'bg-primary';
      case 'success':
        return 'bg-success';
      case 'warning':
        return 'bg-warning';
      case 'info':
        return 'bg-info';
      default:
        return 'bg-info';
      }
    },
  },
};
</script>

import { createProcess,
  getAreas,
  getBukData,
  getCompanies,
  getProcesses,
  getRoles,
  setToken,
  toggleBukState,
  getProcessBukBySuiteId,
  getHiredApplicant } from '../api/buk-api';

const initialState = {
  companies: [],
  roles: [],
  areas: [],
  processes: [],
  error: null,
  loading: false,
  loadingAreas: false,
  loadingCompanies: false,
  loadingRoles: false,
  state: false,
  token: '',
  loginUser: '',
  pagination: {},
  loadingMore: false,
  atsBukProcess: null, // este es el proceso de buk para mostrar en el preview
  hiredApplicant: false,
};

export const mutations = {
  setCompanies(state, payload) {
    state.companies = payload;
  },
  setRoles(state, payload) {
    state.roles = payload;
  },
  setAreas(state, payload) {
    state.areas = payload;
  },
  setProcesses(state, payload) {
    state.processes = [...state.processes, ...payload];
  },
  setPagination(state, payload) {
    state.pagination = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setLoadingRoles(state, payload) {
    state.loadingRoles = payload;
  },
  setLoadingCompanies(state, payload) {
    state.loadingCompanies = payload;
  },
  setLoadingAreas(state, payload) {
    state.loadingAreas = payload;
  },
  setLoadingMore(state, payload) {
    state.loadingMore = payload;
  },
  setState(state, payload) {
    state.state = payload;
  },
  setToken(state, payload) {
    state.token = payload;
  },
  setCompanyUrl(state, payload) {
    state.loginUser = payload;
  },
  setAtsBukProcess(state, payload) {
    state.atsBukProcess = payload;
  },
  setHiredApplicant(state, payload) {
    state.hiredApplicant = payload;
  },
};

export const actions = {
  createBukProcess({ commit }, payload) {
    commit('setLoading', true);

    return new Promise((resolve, reject) => {
      createProcess(payload).then(res => {
        commit('setLoading', false);
        if (res.code === 200) {
          this.commit('setAtsBukProcess', res.dataAts);
          resolve(res);
        }
      }).catch(err => {
        reject({ code: 500, is_succes: false, error: err });
        commit('setLoading', false);
      });
    });
  },
  getBukProcesses({ commit, state }, payload) {
    if (!payload.isLoadMore) commit('setLoading', true);
    commit('setLoadingMore', true);

    if (state.processes.length === 0 || payload.isLoadMore) {
      if (!payload.isLoadMore) commit('setLoading', true);
      getProcesses(payload)
        .then(res => {
          if (res) {
            commit('setProcesses', res.data);
            commit('setPagination', res.pagination);

            if (!payload.isLoadMore) commit('setLoading', false);
            commit('setLoadingMore', false);
          }
        }).catch(e => {
          commit('setError', e);
          if (!payload.isLoadMore) commit('setLoading', false);
          commit('setLoadingMore', false);
        });
    } else {
      if (!payload.isLoadMore) commit('setLoading', false);
      commit('setLoadingMore', false);
    }
  },
  getProcessBukByATSId({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getProcessBukBySuiteId(payload).then(res => {
        if (res.code === 200) {
          commit('setAtsBukProcess', res.data);
        }
        resolve(res);
      }).catch(e => {
        console.error(e);
        reject({ e });
      });
    });
  },
  getBukRoles({ commit, state }, payload) {
    commit('setLoadingRoles', true);
    if (state.roles.length === 0) {
      commit('setLoadingRoles', true);
      getRoles(payload)
        .then(res => {
          if (res) {
            commit('setRoles', res);
            commit('setLoadingRoles', false);
          }
        }).catch(e => {
          commit('setError', e);
          commit('setLoadingRoles', false);
        });
    } else {
      commit('setLoadingRoles', false);
    }
  },
  getBukCompanies({ commit, state }, payload) {
    commit('setLoadingCompanies', true);
    if (state.companies.length === 0) {
      commit('setLoadingCompanies', true);
      getCompanies(payload)
        .then(res => {
          if (res) {
            commit('setCompanies', res);
            commit('setLoadingCompanies', false);
          }
        }).catch(e => {
          commit('setError', e);
          commit('setLoadingCompanies', false);
        });
    } else {
      commit('setLoadingCompanies', false);
    }
  },
  getBukAreas({ commit, state }, payload) {
    commit('setLoadingAreas', true);
    if (state.areas.length === 0) {
      commit('setLoadingAreas', true);
      getAreas(payload)
        .then(res => {
          if (res) {
            commit('setAreas', res);
            commit('setLoadingAreas', false);
          }
        }).catch(e => {
          commit('setError', e);
          commit('setLoadingAreas', false);
        });
    } else {
      commit('setLoadingAreas', false);
    }
  },
  setBukToken({ commit }, payload) {
    commit('setLoading', true);

    return new Promise((resolve, reject) => {
      setToken(payload)
        .then(res => {
          if (res.is_success) {
            commit('setLoading', false);
            resolve(res);
          } else {
            throw new Error('no response available');
          }
        }).catch(e => {
          commit('setError', e);
          commit('setLoading', false);

          reject({ e });
        });
    });
  },
  getBukInformation({ commit }, payload) {
    commit('setLoading', true);

    getBukData(payload)
      .then(res => {
        if (res.is_success) {
          commit('setToken', res.data.token);
          commit('setCompanyUrl', res.data.login_user);
          commit('setState', res.data.state === 1);
          commit('setLoading', false);
        } else {
          throw new Error('no response available');
        }
      }).catch(e => {
        commit('setError', e);
        commit('setLoading', false);
      });
  },
  toggleBukState({ commit, state }) {
    commit('setLoading', true);
    const PREV_STATE = state.state;

    return new Promise((resolve, reject) => {
      toggleBukState()
        .then(res => {
          if (res.is_success) {
            commit('setLoading', false);
            commit('setState', res.data.state === 1);
            resolve(res);
          } else {
            commit('setState', PREV_STATE);
            throw new Error('no response available');
          }
        }).catch(e => {
          commit('setError', e);
          commit('setLoading', false);
          commit('setState', PREV_STATE);

          reject({ e });
        });
    });
  },
  getHiredApplicant({ commit }, payload) {
    getHiredApplicant(payload)
      .then(res => {
        commit('setHiredApplicant', res.is_success);
      }).catch(e => {
        commit('setError', e);
      });
  },
};

export const getters = {
  getBukCompanies(state) {
    return state.companies;
  },
  getBukRoles(state) {
    return state.roles;
  },
  getBukAreas(state) {
    return state.areas;
  },
  getBukProcesses(state) {
    return state.processes;
  },
  getBukPagination(state) {
    return state.pagination;
  },
  getBukError(state) {
    return state.error;
  },
  getBukToken(state) {
    return state.token;
  },
  getBukUrl(state) {
    return state.loginUser;
  },
  getBukState(state) {
    return state.state;
  },
  getAtsBukProcess(state) {
    return state.atsBukProcess;
  },
  getHiredApplicant(state) {
    return state.hiredApplicant;
  },
  getBukLoading(state) {
    return state.loading || state.loadingAreas || state.loadingCompanies || state.loadingRoles;
  },
};
export default {
  state: { ...initialState },
  mutations,
  actions,
  getters,
};

import { toggleTalanaState,
  getTalanaState,
  requestTalana,
  getTalanaRazonSocial,
  getTalanaJobTitle,
  getTalanaJornadaLaboral,
  getTalanaTipoContrato,
  getTalanaSucursales,
  getTalanaUnidadOrganizacional,
  getTalanaCentroCosto,
  getTalanaTipoPrevision,
  getTalanaAFP,
  getTalanaJefeDirecto,
  createTalanaPersona,
  getTalanaPaises,
  checkTalanaEmpoyees } from '../api/talana-api';

const initialState = {
  loading: false,
  state: false,
  requested: false,
  token: '',
  location: null,
  razonesSociales: [],
  jobTitles: [],
  jornadasLaborales: [],
  tiposContratos: [],
  sucursales: [],
  unidadesOrganizacionales: [],
  centrosCostos: [],
  formasPago: [],
  previsiones: [],
  afps: [],
  jefesDirectos: [],
  paises: [],
  personas: [],
  employees: [],
  intConfirmationModal: false,
};

export const mutations = {
  setTalanaLoading(state, payload) {
    state.loading = payload;
  },
  setTalanaState(state, payload) {
    state.state = payload;
  },
  setTalanaToken(state, payload) {
    state.token = payload;
  },
  setTalanaLocation(state, payload) {
    state.location = payload;
  },
  setTalanaRazonesSociales(state, payload) {
    state.razonesSociales = payload;
  },
  setTalanaJobTitle(state, payload) {
    state.jobTitles = payload;
  },
  setTalanaJornadasLaborales(state, payload) {
    state.jornadasLaborales = payload;
  },
  setTalanaTiposContratos(state, payload) {
    state.tiposContratos = payload;
  },
  setTalanaSucursales(state, payload) {
    state.sucursales = payload;
  },
  setTalanaCentroCosto(state, payload) {
    state.centrosCostos = payload;
  },
  setTalanaFormasPago(state, payload) {
    state.formasPago = payload;
  },
  setTalanaUnidadesOrganizacionales(state, payload) {
    state.unidadesOrganizacionales = payload;
  },
  setTalanaPrevisiones(state, payload) {
    state.previsiones = payload;
  },
  setTalanaAFPs(state, payload) {
    state.afps = payload;
  },
  setTalanaJefesDirectos(state, payload) {
    state.jefesDirectos = payload;
  },
  setTalanaPaises(state, payload) {
    state.paises = payload;
  },
  setTalanaPersonas(state, payload) {
    state.personas = payload;
  },
  addTalanaEmployees(state, payload) {
    state.employees.push(payload);
  },
  changeTalanaEmployeeState(state, payload) {
    state.employees.find(employee => employee.applicant_id === payload).is_success = true;
  },
  setTalanaRequestState(state, payload) {
    state.requested = payload;
  },
  setIntConfirmationModal(state) {
    state.intConfirmationModal = !state.intConfirmationModal;
  },
};

export const actions = {
  async requestTalana({ commit }, payload) {
    commit('setTalanaLoading', true);
    try {
      const res = await requestTalana(payload);
      if (res.is_success) {
        commit('setTalanaState', true);
      } else {
        throw new Error('no response available');
      }
    } catch (e) {
      commit('setError', e);
    }
    commit('setTalanaLoading', false);
  },
  async toggleTalanaState({ commit, state }) {
    commit('setTalanaLoading', true);
    const PREV_STATE = state.state;

    try {
      const res = await toggleTalanaState();
      if (res.code === 200) {
        commit('setTalanaState', res.data.state === 1);
      } else {
        throw new Error('no response available');
      }
    } catch {
      commit('setTalanaState', PREV_STATE);
    }

    commit('setTalanaLoading', false);
  },
  async getTalanaInformation({ commit }, payload) {
    commit('setTalanaLoading', true);
    try {
      const res = await getTalanaState(payload);
      if (res.code === 200) {
        commit('setTalanaToken', res.data.token);
        commit('setTalanaState', res.data.state === 1);
        commit('setTalanaRequestState', res.data.state === 2 && !res.data.token);
        commit('setTalanaLocation', res.data.location);
      }
    } catch (e) {
      commit('setError', e);
    }
    commit('setTalanaLoading', false);
  },
  async getTalanaRS({ commit }) {
    commit('setTalanaLoading', true);
    try {
      const res = await getTalanaRazonSocial();
      if (res.talana) {
        commit('setTalanaRazonesSociales', res.talana);
      } else {
        throw new Error('no response available');
      }
    } catch (e) {
      commit('setError', e);
    }
    commit('setTalanaLoading', false);
  },
  async getTalanaJobTitles({ commit }) {
    commit('setTalanaLoading', true);
    try {
      const res = await getTalanaJobTitle();
      if (res.talana) {
        commit('setTalanaJobTitle', res.talana);
      } else {
        throw new Error('no response available');
      }
    } catch (e) {
      commit('setError', e);
    }
    commit('setTalanaLoading', false);
  },
  async getTalanaJornadasLaborales({ commit }) {
    commit('setTalanaLoading', true);
    try {
      const res = await getTalanaJornadaLaboral();
      if (res.talana) {
        commit('setTalanaJornadasLaborales', res.talana);
      } else {
        throw new Error('no response available');
      }
    } catch (e) {
      commit('setError', e);
    }
    commit('setTalanaLoading', false);
  },
  async getTalanaTiposContratos({ commit }) {
    commit('setTalanaLoading', true);
    try {
      const res = await getTalanaTipoContrato();
      if (res.talana) {
        commit('setTalanaTiposContratos', res.talana);
      } else {
        throw new Error('no response available');
      }
    } catch (e) {
      commit('setError', e);
    }
    commit('setTalanaLoading', false);
  },
  async getTalanaSucursales({ commit }) {
    commit('setTalanaLoading', true);
    try {
      const res = await getTalanaSucursales();
      if (res.talana) {
        commit('setTalanaSucursales', res.talana);
      } else {
        throw new Error('no response available');
      }
    } catch (e) {
      commit('setError', e);
    }
    commit('setTalanaLoading', false);
  },
  async getTalanaUnidadesOrganizacionales({ commit }) {
    commit('setTalanaLoading', true);
    try {
      const res = await getTalanaUnidadOrganizacional();
      if (res.talana) {
        commit('setTalanaUnidadesOrganizacionales', res.talana);
      } else {
        throw new Error('no response available');
      }
    } catch (e) {
      commit('setError', e);
    }
    commit('setTalanaLoading', false);
  },
  async getTalanaCentroCosto({ commit }) {
    commit('setTalanaLoading', true);
    try {
      const res = await getTalanaCentroCosto();
      if (res.talana) {
        commit('setTalanaCentroCosto', res.talana);
      } else {
        throw new Error('no response available');
      }
    } catch (e) {
      commit('setError', e);
    }
    commit('setTalanaLoading', false);
  },
  async getTalanaPrevisiones({ commit }) {
    commit('setTalanaLoading', true);
    try {
      const res = await getTalanaTipoPrevision();
      if (res.talana) {
        commit('setTalanaPrevisiones', res.talana);
      } else {
        throw new Error('no response available');
      }
    } catch (e) {
      commit('setError', e);
    }
    commit('setTalanaLoading', false);
  },
  async getTalanaAFPs({ commit }) {
    commit('setTalanaLoading', true);
    try {
      const res = await getTalanaAFP();
      if (res.talana) {
        commit('setTalanaAFPs', res.talana);
      } else {
        throw new Error('no response available');
      }
    } catch (e) {
      commit('setError', e);
    }
    commit('setTalanaLoading', false);
  },
  async getTalanaJefesDirectos({ commit }) {
    commit('setTalanaLoading', true);
    try {
      const res = await getTalanaJefeDirecto();
      if (res.talana) {
        commit('setTalanaJefesDirectos', res.talana);
      } else {
        throw new Error('no response available');
      }
    } catch (e) {
      commit('setError', e);
    }
    commit('setTalanaLoading', false);
  },
  async getTalanaPaises({ commit }) {
    commit('setTalanaLoading', true);
    try {
      const res = await getTalanaPaises();
      if (res.talana) {
        commit('setTalanaPaises', res.talana);
      } else {
        throw new Error('no response available');
      }
    } catch (e) {
      commit('setError', e);
    }
    commit('setTalanaLoading', false);
  },
  async createTalanaPersona({ commit }, payload) {
    commit('setTalanaLoading', true);
    try {
      const res = await createTalanaPersona(payload);
      if (res.talana) {
        commit('setTalanaPersonas', res.talana);
      } else {
        throw new Error('no response available');
      }
    } catch (e) {
      commit('setError', e);
    }
    commit('setTalanaLoading', false);
  },
  async checkTalanaEmployees({ commit }, payload) {
    try {
      const res = await checkTalanaEmpoyees(payload);
      commit('addTalanaEmployees',
        { is_success: res.is_success, applicant_id: payload.applicantId, applicant_stage_id: payload.applicantStageId },
      );
    } catch (e) {
      commit('setError', e);
    }
  },
};

export const getters = {
  getTalanaLoading(state) {
    return !(state.razonesSociales.length > 0 && state.jobTitles.length > 0 && state.jornadasLaborales.length > 0 &&
      state.tiposContratos.length > 0 && state.centrosCostos.length > 0 && state.sucursales.length > 0 &&
      state.previsiones.length > 0 && state.afps.length > 0 &&
      state.jefesDirectos.length > 0);
  },
  getTalanaToken(state) {
    return state.token;
  },
  getTalanaState(state) {
    return state.state;
  },
  getTalanaLocation(state) {
    return state.location;
  },
  getTalanaRazonesSociales(state) {
    return state.razonesSociales;
  },
  getTalanaJobTitles(state) {
    return state.jobTitles;
  },
  getTalanaJornadasLaborales(state) {
    return state.jornadasLaborales;
  },
  getTalanaTiposContratos(state) {
    return state.tiposContratos;
  },
  getTalanaCentroCosto(state) {
    return state.centrosCostos;
  },
  getTalanaSucursales(state) {
    return state.sucursales;
  },
  getTalanaUnidadesOrganizacionales(state) {
    return state.unidadesOrganizacionales;
  },
  getTalanaPrevisiones(state) {
    return state.previsiones;
  },
  getTalanaAFPs(state) {
    return state.afps;
  },
  getTalanaJefesDirectos(state) {
    return state.jefesDirectos;
  },
  getTalanaPaises(state) {
    return state.paises;
  },
  getTalanaPersonas(state) {
    return state.personas;
  },
  getTalanaEmployees(state) {
    return state.employees;
  },
  getTalanaRequestState(state) {
    return state.requested;
  },
  getIntConfirmationModal(state) {
    return state.intConfirmationModal;
  },
};
export default {
  state: { ...initialState },
  mutations,
  actions,
  getters,
};

<script>
import { mdiClose } from '@mdi/js'
import IconSVG from 'components/ui/IconSVG'

export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    /** Ícono que tendrá el modal en la parte superior (Estándar diseño)
     * Se pasa la variable del import por prop
     * ❗Se debe importar el miembro del módulo @mdi/js que se quiera usar.
     */
    icon: {
      type: String,
      default: null,
    },
    /** Título del modal */
    title: {
      type: String,
      default: 'Título',
    },
    maxWidth: {
      type: String,
      default: '',
    },
    minWidth: {
      type: String,
      default: '',
    },
    /** Oculta el overlay del modal */
    hideOverlay: {
      type: Boolean,
      default: false,
    },
    /** Evita que el modal se cierre al clickear por fuera de este*/
    persistent: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: undefined,
    },
    scrollable: {
      type: Boolean,
      required: false,
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: {
    IconSVG,
  },
  data() {
    return {
      mdiClose,
    }
  },
}
</script>

<template>
  <v-dialog
    v-model="open"
    :scrollable="scrollable"
    :max-width="maxWidth"
    :min-width="minWidth"
    :persistent="persistent"
    :hide-overlay="hideOverlay"
    :content-class="`${classes} rounded-4 m-2 m-sm-3`"
    :width="width"
    @keydown.esc="!persistent ? $emit('close-modal'): false"
    @click:outside="!persistent ? $emit('close-modal'): false"
  >
    <v-card
      :height="height"
    >
      <div class="d-flex align-center gap-2 p-3 title-border">
        <IconSVG v-if="icon" :path="icon" />
        <h5 class="modal-title fw-semibold">{{ title }}</h5>
        <v-icon
          class="me-auto"
          color="var(--t-gray-500)"
          size="28"
          style="position: absolute; right: 16px;"
          @click="$emit('close-modal')"
        >
          {{ mdiClose }}
        </v-icon>
      </div>
      <slot />
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.modal-title {
  font-size: 18px;
}

.title-border {
  border-bottom: 1px solid var(--t-gray-200);
}

:deep(.v-dialog) {
  margin: 1rem;
}
</style>

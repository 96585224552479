import { getKcPlans } from '../api/ecommerce';
import {
  companyHasStock,
  processHasStock,
  applicantsOnProcess,
  companyAllRestrictions,
} from '../api/kc-plans';

const initialState = {
  kcPlans: null,
  moveModalAlert: false,
  hasKgCompanyRestriction: false,
  hasKgProcessRestriction: false,
  companyStock: 0,
  processStock: null,
  processNumber: null,
  applicantsCompanyNumber: 0,
  applicantsProcessNumber: null,
  applicantsBenchModal: false,
  maxTechnicalApplicantsProcess: null,
  totalApplicantsOnProcess: null,
  hasApplicantsCompanyRestriction: false,
  hasApplicantsProcessRestriction: false,
  hasProcessNumberRestriction: false,
  allCompanyRestrictions: [],
  applicantToBenchModal: false,
};

export const mutations = {
  setKcPlans(state, payload) {
    state.kcPlans = payload;
  },
  setMoveModalAlert(state) {
    state.moveModalAlert = !state.moveModalAlert;
  },
  setKgCompanyRestriction(state, payload) {
    state.hasKgCompanyRestriction = payload;
  },
  setApplicantsCompanyRestriction(state, payload) {
    state.hasApplicantsCompanyRestriction = payload;
  },
  setKgProcessRestriction(state, payload) {
    state.hasKgProcessRestriction = payload;
  },
  setApplicantsProcessRestriction(state, payload) {
    state.hasApplicantsProcessRestriction = payload;
  },
  setProcessNumberRestriction(state, payload) {
    state.hasProcessNumberRestriction = payload;
  },
  setCompanyStock(state, payload) {
    state.companyStock = payload;
  },
  setProcessStock(state, payload) {
    state.processStock = payload;
  },
  setProcessNumber(state, payload) {
    state.processNumber = payload;
  },
  setApplicantsCompanyNumber(state, payload) {
    state.applicantsCompanyNumber = payload;
  },
  setApplicantsProcessNumber(state, payload) {
    state.applicantsProcessNumber = payload;
  },
  setApplicantsBenchModal(state) {
    state.applicantsBenchModal = !state.applicantsBenchModal;
  },
  setMaxTechnicalApplicantsProcess(state, payload) {
    state.maxTechnicalApplicantsProcess = payload;
  },
  setTotalApplicantsOnProcess(state, payload) {
    state.totalApplicantsOnProcess = payload;
  },
  setAllCompanyRestrictions(state, payload) {
    state.allCompanyRestrictions = payload;
  },
  setApplicantToBenchModal(state) {
    state.applicantToBenchModal = !state.applicantToBenchModal;
  },
};

export const actions = {
  // Obtiene todas las restricciones según el plan de la organización
  getAllRestrictions({ state, commit, dispatch }, payload) {
    companyAllRestrictions(payload)
      .then(res => {
        const restrictions = res.data.data.restrictions;
        commit('setAllCompanyRestrictions', restrictions);
        restrictions.forEach(r => {
          const restriction = `${r.serviceTypeId}-${r.levelId}`;
          if (!state.hasApplicantsProcessRestriction && payload.origen === 'process') {
            dispatch('getApplicantsProcessNumber',
              { selectionProcessId: payload.selectionProcessId, serviceTypeId: r.serviceTypeId });
            dispatch('getApplicantsCompanyNumber',
              { companyId: payload.companyId, serviceTypeId: r.serviceTypeId });
          }
          switch (restriction) {
          case '9-1':
            commit('setApplicantsProcessRestriction', true);
            if (payload.origen === 'process') {
              dispatch('getApplicantsProcessNumber',
                { selectionProcessId: payload.selectionProcessId, serviceTypeId: r.serviceTypeId });
              dispatch('getApplicantsCompanyNumber',
                { companyId: payload.companyId, serviceTypeId: r.serviceTypeId });
            } else if (payload.origen === 'billing') {
              dispatch('getApplicantsCompanyNumber',
                { companyId: payload.companyId, serviceTypeId: r.serviceTypeId });
            }
            break;
          case '9-2':
            commit('setApplicantsCompanyRestriction', true);
            dispatch('getApplicantsCompanyNumber',
              { companyId: payload.companyId, serviceTypeId: r.serviceTypeId });
            break;
          case '7-2':
            commit('setProcessNumberRestriction', true);
            dispatch('getProcessNumber', { companyId: payload.companyId, serviceTypeId: r.serviceTypeId });
            break;
          case '5-1':
            commit('setKgProcessRestriction', true);
            if (payload.origen === 'process') {
              dispatch('getProcessStock',
                { selectionProcessId: payload.selectionProcessId, serviceTypeId: r.serviceTypeId });
            }
            break;
          case '5-2':
            commit('setKgCompanyRestriction', true);
            dispatch('getCompanyStock',
              { companyId: payload.companyId, serviceTypeId: r.serviceTypeId });
            break;

          default:
            break;
          }
        });
      });
  },
  getKcPlans({ commit }) {
    getKcPlans()
      .then(res => {
        commit('setKcPlans', res.data.kc_plans);
      });
  },
  getCompanyStock({ commit }, payload) {
    companyHasStock(payload)
      .then(res => {
        if (res.data.data.stock === null) {
          commit('setCompanyStock', 0);
        } else {
          commit('setCompanyStock', res.data.data.stock.quantityAvailable);
        }
      });
  },
  getProcessStock({ commit }, payload) {
    processHasStock(payload)
      .then(res => commit('setProcessStock', res.data.data.stock.quantityTotal));
  },
  getProcessNumber({ commit }, payload) {
    companyHasStock(payload)
      .then(res => commit('setProcessNumber', res.data.data.stock.quantityAvailable));
  },
  getApplicantsCompanyNumber({ commit }, payload) {
    companyHasStock(payload)
      .then(res => {
        if (res.data.data.stock === null) {
          commit('setApplicantsCompanyNumber', 0);
        } else {
          commit('setApplicantsCompanyNumber', res.data.data.stock.quantityAvailable);
        }
      });
  },
  getApplicantsProcessNumber({ commit, state }, payload) {
    processHasStock(payload)
      .then(res => {
        if (res.data.data.stock) {
          commit('setApplicantsProcessNumber', res.data.data.stock.quantityTotal);
        } else {
          commit('setApplicantsProcessNumber', state.maxTechnicalApplicantsProcess);
        }
      });
  },
  getTotalApplicantsOnProcess({ commit }, payload) {
    applicantsOnProcess(payload)
      .then(res => commit('setTotalApplicantsOnProcess', res.data.data.applicants));
  },
};

export const getters = {
  getKcPlans(state) {
    return state.kcPlans;
  },
  getMoveModalAlert(state) {
    return state.moveModalAlert;
  },
  getCompanyRestriction(state) {
    return state.hasKgCompanyRestriction;
  },
  getApplicantsCompanyRestriction(state) {
    return state.hasApplicantsCompanyRestriction;
  },
  getKgProcessRestriction(state) {
    return state.hasKgProcessRestriction;
  },
  getApplicantsProcessRestriction(state) {
    return state.hasApplicantsProcessRestriction;
  },
  getProcessNumberRestriction(state) {
    return state.hasProcessNumberRestriction;
  },
  getCompanyStock(state) {
    return state.companyStock;
  },
  getProcessStock(state) {
    return state.processStock;
  },
  getProcessNumber(state) {
    return state.processNumber;
  },
  getApplicantsCompanyNumber(state) {
    return state.applicantsCompanyNumber;
  },
  getApplicantsProcessNumber(state) {
    return state.applicantsProcessNumber;
  },
  getMaxTechnicalApplicantsProcess(state) {
    return state.maxTechnicalApplicantsProcess;
  },
  applicantsBenchModal(state) {
    return state.applicantsBenchModal;
  },
  getTotalApplicantsOnProcess(state) {
    return state.totalApplicantsOnProcess;
  },
  getAllCompanyRestrictions(state) {
    return state.allCompanyRestrictions;
  },
  applicantToBenchModal(state) {
    return state.applicantToBenchModal;
  },
};
export default {
  state: { ...initialState },
  mutations,
  actions,
  getters,
};

import axios from 'axios';
import { camelizeKeys } from 'humps';

export async function getExpirationDays() {
  return axios
    .get('/api/v1/kf/kf_settings')
    .then(res => camelizeKeys(res.data));
}

export async function updateExpirationDays(params) {
  return axios
    .patch('/api/v1/kf/kf_settings/1', params)
    .then(res => camelizeKeys(res.data));
}

<template>
  <div class="pa-6">
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        Etiquetas
        <editor-menu-bar
          :editor="bodyEditor"
          v-slot="{ commands }"
          class="pa-6 mt-3"
        >
          <div>
            <button
              v-for="(t, i) in tags"
              :key="i"
              @click="commands.mention({ id: i, label: t })"
              class="mb-3 ml-3"
            >
              <chip
                :class-prop="'white--text letter-spacing-0 caption'"
                :type="'info'"
                class="pointer"
              >
                {{ formatVariable(t) }}
              </chip>
            </button>
          </div>
        </editor-menu-bar>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <label for="body">Cuerpo del mensaje</label>
        <editor-content
          :editor="bodyEditor"
          class="email-body my-3"
        />
        <editor-menu-bubble
          :editor="bodyEditor"
          @hide="hideLinkMenu"
          v-slot="{ commands, isActive, getMarkAttrs, menu }"
        >
          <div
            :class="{ 'is-active': menu.isActive }"
            :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
          >
            <form
              v-if="linkMenuIsActive"
              @submit.prevent="setLinkUrl(commands.link, linkUrl)"
            >
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    v-model="linkUrl"
                    placeholder="https://"
                    ref="linkInput"
                    @keydown.esc="hideLinkMenu"
                  />
                </v-col>
                <v-col>
                  <button
                    @click="setLinkUrl(commands.link, linkUrl)"
                    type="button"
                  >
                    <v-icon large>
                      {{ mdiCheckBold }}
                    </v-icon>
                  </button>
                  <button
                    @click="setLinkUrl(commands.link, null)"
                    type="button"
                  >
                    <v-icon large>
                      {{ mdiDelete }}
                    </v-icon>
                  </button>
                </v-col>
              </v-row>
            </form>

            <template v-else>
              <button
                @click="showLinkMenu(getMarkAttrs('link'))"
                :class="{ 'is-active': isActive.link() }"
              >
                <v-icon
                  large
                  v-if="!isActive.link()"
                >
                  {{ mdiLink }}
                </v-icon>
                <v-icon
                  large
                  v-else
                >
                  {{ mdiPencil }}
                </v-icon>
              </button>
            </template>
          </div>
        </editor-menu-bubble>
      </v-col>
    </v-row>
    <div class="d-flex justify-end">
      <btn
        outlined
        :loading="false"
        :width="'auto'"
        @click="$emit('close-modal')"
      >
        Cancelar
      </btn>
      <btn
        class="ml-3"
        :loading="false"
        :width="'auto'"
        @click="$emit('sendText', jsonToMessage(bodyEditor.getJSON()))"
      >
        <div>
          Guardar
        </div>
      </btn>
    </div>
  </div>
</template>

<script>
import { mdiLink, mdiPencil, mdiDelete, mdiCheckBold } from '@mdi/js';
import { Mention, Link } from 'tiptap-extensions';
import * as _ from 'lodash';
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap';
import Btn from '../../../../../ui/btn.vue';
import Chip from '../../../../../ui/chip.vue';

export default {
  components: {
    EditorMenuBar,
    EditorContent,
    EditorMenuBubble,
    Btn,
    Chip,
  },
  props: {
    currentText: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      text: this.currentText,
      tags: ['nombre_proceso', 'nombre_organizacion'],
      bodyEditor: null,
      linkUrl: null,
      linkMenuIsActive: false,
      mdiLink,
      mdiPencil,
      mdiDelete,
      mdiCheckBold,
    };
  },
  methods: {
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      this.$nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl(command, url) {
      command({ href: url });
      this.hideLinkMenu();
    },
    formatVariable(variable) {
      let formattedVariable = variable.replace(/_/gi, ' ');
      formattedVariable = `+ ${formattedVariable[0].toUpperCase()}${formattedVariable.slice(1)}`;

      return formattedVariable;
    },
    // eslint-disable-next-line max-statements
    jsonToMessage(jsonMsg) {
      /*
       * Pasa la versión JSON del texto en el editor de tiptap a un texto plano
       * para que KeyMailer lo entienda
       */
      let unformattedMsg = '';
      for (const paragraph of jsonMsg.content) {
        // Agregar salto de línea por cada párrafo
        unformattedMsg += '\n';
        if (!paragraph.content) {
          continue;
        }
        // Rodear variables con {{}}, el resto se copia sin modificar
        for (const element of paragraph.content) {
          if (element.marks && element.marks.length > 0) {
            unformattedMsg += `<a target="_blank" href="${element.marks[0].attrs.href}">${element.text}</a>`;
          } else if (element.type === 'text') {
            unformattedMsg += element.text;
          } else if (element.type === 'mention') {
            unformattedMsg += `{{${element.attrs.label}}}`;
          }
        }
      }

      return unformattedMsg.slice(1);
    },
    messageToHTML(msg) {
      /*
       * Pasa texto plano de los templates de KeyMailer a un formato en HTML
       * para desplegar en el editor de tiptap
       */
      let formattedMsg = msg;

      const variablesRegex = /{{[^{}]+}}/g;
      if (!_.isEmpty(msg.match(variablesRegex))) {
        const foundVariables = msg.match(variablesRegex).map(v => v.replace(/[{}]/gi, ''));
        // Reemplazar texto rodeado en {{}} por HTML con estilo de etiqueta
        for (const v of foundVariables) {
          const idx = v;
          formattedMsg = formattedMsg.replace(`{{${v}}}`, `<span data-mention-id="${idx}">${v}</span>`);
        }
      }
      // Reemplazar saltos de líneas por tags de párrafo
      formattedMsg = `<p>${formattedMsg.replace(/\n/g, '</p><p>')}</p>`;

      return formattedMsg;
    },
  },
  beforeMount() {
    this.bodyEditor = { content: this.messageToHTML(this.text) };
  },
  beforeUnmount() {
    this.bodyEditor.destroy();
  },
  mounted() {
    this.bodyEditor = new Editor({
      content: this.bodyEditor?.content,
      editable: true,
      extensions: [
        new Mention({
          matcher: { char: '' },
          mentionClass: 'email-tag',
        }),
        new Link(),
      ],
    });
  },
};
</script>

<style lang="scss">
.email-body {
  min-height: 150px;
  border-radius: .5rem;
  background-color: #f2f2f2;
  width: 100%;
  overflow: auto;
  font-size: 13px;
  padding: 10px;

  &:focus,
  &:hover {
    outline: none;
  }
}

.ProseMirror {
  a {
    color: #68CEF8;
  }
  min-height: 150px;
  padding: 10px;

  &:focus {
    outline: none;
  }
}

.email-tag {
  background-color: #c7daf0;
  border: 0;
  border-radius: 1rem;
  padding: .1rem .625rem;
  font-size: 12px;
}

.pointer {
  cursor: pointer;
}
</style>

/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import { camelizeKeys } from 'humps';

export function getKfEmailTemplates() {
  return axios
    .get('/api/v1/kf/email_templates')
    .then(res => camelizeKeys(res.data.data));
}

export function updateKfTemplate(params) {
  return axios
    .patch(`/api/v1/kf/email_templates/category/${params.category}`, params)
    .then(res => camelizeKeys(res.data));
}

export function createKfTemplate(params) {
  return axios
    .post('/api/v1/kf/email_templates/create', params)
    .then(res => camelizeKeys(res.data));
}

export function changeKfStateTemplate(category) {
  return axios
    .patch(`/api/v1/kf/email_templates/change_state/${category}`)
    .then(res => camelizeKeys(res.data));
}

<template>
  <div class="setting">
    <div class="row">
      <div class="row__title-container col">
        <p class="h5 mb-2 fw-bolder">
          {{ title }}
        </p>
        <p class="row__subtitle">
          {{ subtitle }}
        </p>
      </div>
      <div
        class="row__switch-container col-auto"
        v-if="setting.canModify"
      >
        <p
          class="row__mobile-alert"
          v-if="mobile && setting.value"
        >
          Rendición excluye pruebas AMP y MCI
        </p>
        <label class="row__switch switch">
          <input
            class="switch__checkbox"
            type="checkbox"
            :class="{ 'switch__checkbox--checked': setting.value }"
            @click="$emit('toggle-settings', setting)"
          >
          <span class="switch__slider" />
        </label>
      </div>
    </div>
    <TipBox
      class="tip-box"
      text="Autorizar rendición desde móviles podría afectar predictibilidad de los resultados al aumentar posibilidades
       de que las pruebas no sean rendidas en un ambiente de concentración"
      is-lamp
      v-if="mobile"
    />
    <hr
      class="divider"
      :class="{ 'divider--mobile': mobile }"
    >
  </div>
</template>
<script>

import TipBox from '../../global/TipBox';

export default {
  components: {
    TipBox,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      required: true,
      type: String,
    },
    setting: {
      type: Object,
      required: true,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>

.row {
  display: flex;

  &__switch-container {
    margin: auto 0 auto auto;
    display: flex;
  }

  &__switch {
    min-width: 34px;
    margin: auto;
  }

  &__mobile-alert {
    color: red;
    margin: 0;
    max-width: 210px;
    padding-right: 20px;
    text-align: right;
  }

  &__title {
    color: #263d49;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }

  &__subtitle {
    margin-top: 4px;
    margin-bottom: 0px;
    max-width: 360px;
  }
}

.tip-box {
  margin: 20px 38px 0 0;
}

@media (max-width: 600px) {
  .tip-box {
    margin: 20px 0;
  }
}

.divider {
  margin: 25px 0;
  opacity: 0.2;

  &--mobile {
    margin-bottom: 70px;
    opacity:  0.7;
  }
}
</style>

<template>
  <div
    @click="$emit('openModal')"
    class="custom-container my-0 mx-auto"
  >
    <div class="overlay-block">
      <div class="overlay-block__area" />
      <span class="overlay-block__box">
        <img
          class="overlay-block__icon"
        >
        <div>
          <v-icon>{{ mdiPencil }}</v-icon> Zona editable
        </div>
      </span>
    </div>
    <div
      class="py-4 px-9"
    >
      <div class="py-0 px-3 my-0 mx-auto text-center">
        <p v-html="messageToHTML(text)" />
      </div>
    </div>
  </div>
</template>
<script>
import * as _ from 'lodash';
import { mdiPencil } from '@mdi/js';

export default {
  props: {
    text: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      mdiPencil,
    };
  },
  methods: {
    messageToHTML(msg) {
      let formattedMsg = msg;

      const variablesRegex = /{{[^{}]+}}/g;
      if (!_.isEmpty(msg.match(variablesRegex))) {
        const foundVariables = msg.match(variablesRegex).map(v => v.replace(/[{}]/gi, ''));
        // Reemplazar texto rodeado en {{}} por HTML con estilo de etiqueta
        for (const v of foundVariables) {
          const idx = v;
          formattedMsg = formattedMsg
            .replace(`{{${v}}}`, `<span data-mention-id="${idx}"><b>[${v.replace(/_/gi, ' ')}]</b></span>`);
        }
      }
      // Reemplazar saltos de líneas por tags de párrafo
      formattedMsg = `<p>${formattedMsg.replace(/\n/g, '</p><p>')}</p>`;

      return formattedMsg;
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay-block {
  display: none;
  z-index: 99;
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;

  &__area {
    height: 100%;
    width: 100%;
    border: 3px solid #1b61e4;
    background-color: #f7f7f7;
    opacity: .8;
    z-index: 9999;
  }

  &__icon {
    color: #000;
    height: 15%;
    width: 10%;
    top: 60%;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  &__box {
    color: #000;
    padding: 6px 10px 6px 55px;
    font-size: 20px;
    position: absolute;
    top: 22%;
    left: 27%;
    right: 27%;
    border: 1px solid transparent;
    border-radius: 4px;
    display: flex;
  }
}

.custom-container {
  color: #212121;
  font-size: 18px;
  width: auto;
  background-color: #f7f7f7;
  position: relative;
}

</style>

<template>
  <div>
    <div class="d-flex justify-center">
      <v-slide-group
        show-arrows
        mandatory
        active-class="boton-color-talana"
        v-model="selected"
        class="mb-6"
      >
        <v-slide-item
          v-for="n in templatesOptions"
          :key="n.value"
          v-slot="{ active, toggle }"
        >
          <v-tooltip
            max-width="200"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 letter-spacing-0"
                :input-value="active"
                active-class="boton-color-talana white--text"
                depressed
                rounded
                @click="toggle"
              >
                {{ n.value }}
              </v-btn>
            </template>
            <span>{{ tabTooltip[n.value] }}</span>
          </v-tooltip>
        </v-slide-item>
      </v-slide-group>
    </div>

    <ki-assignment-template
      v-if="selected === 0 && kiTemplates"
      :ki-templates="kiTemplates"
      :category="1"
      :company="company"
    />
    <ki-pending-template
      v-if="selected === 1 && kiTemplates"
      :ki-templates="kiTemplates"
      :category="2"
      :company="company"
    />
    <ki-expiration-template
      v-if="selected === 2 && kiTemplates"
      :ki-templates="kiTemplates"
      :category="3"
      :company="company"
    />
    <ki-answered-template
      v-if="selected === 3 && kiTemplates"
      :ki-templates="kiTemplates"
      :category="4"
      :company="company"
    />
  </div>
</template>

<script>
import KiAssignmentTemplate from './components/ki-assignment-tempate.vue';
import KiExpirationTemplate from './components/ki-expiration-template.vue';
import KiPendingTemplate from './components/ki-pending-template.vue';
import KiAnsweredTemplate from './components/ki-answered-template.vue';
import { getKiEmailTemplates } from '../../../../api/keyinterview/email-templates';

export default {
  components: {
    KiAssignmentTemplate,
    KiExpirationTemplate,
    KiPendingTemplate,
    KiAnsweredTemplate,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      templatesOptions: [
        { value: 'Asignación' },
        { value: 'Recordatorio' },
        { value: 'Expiración' },
        { value: 'Rendido' },
      ],
      selected: null,
      kiTemplates: null,
      tabTooltip: {
        'Asignación': `Se enviará a tus postulantes para informarles que tienen una nueva
          solicitud por realizar dentro de tu proceso`,
        'Recordatorio': `Funciona a modo de recordatorio, para postulantes que tengan una solicitud
          pendiente aún no realizada.`,
        'Expiración': `Transcurridos los días de vigencia que elegiste, este correo informa que la
          solicitud pendiente ya no puede ser realizada.`,
        'Rendido': 'Tras la realización de la solucitud, el postulante será informado que la recibiste.',
      },
    };
  },
  methods: {
    getTemplates() {
      getKiEmailTemplates()
        .then((res) => {
          this.kiTemplates = res.emailTemplates;
        });
    },
  },
  beforeMount() {
    this.getTemplates();
  },
};
</script>

<style>

</style>

<template>
  <div>
    <p
      class="flag-notification"
      :class="{ 'flag-notification--error': errorFlag, ' flag-notification--success': successFlag }"
      v-if="errorFlag || successFlag"
    >
      {{ message }}
    </p>
    <div class="pa-12">
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <page-title>
            Días de vigencia de la solicitud
          </page-title>
          <span>
            Días que tendrán los postulantes para enviar
            su información y/o CV desde el momento en que se recibe la solicitud Curricular.
          </span>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <span class="form-label">Días</span>
          <div>
            <v-select
              v-model="expirationDays"
              :items="options"
              outlined
              dense
              persistent-hint
              hint="De 3 a 7 días"
            />
          </div>
        </v-col>
      </v-row>
      <div class="mt-6 d-flex justify-start">
        <btn
          :disabled="false"
          :loading="false"
          @click="updateExpirationDays"
          color="primary"
        >
          Guardar cambios
        </btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mdiHelpCircleOutline } from '@mdi/js';
import apiCalls from '../../../api/kv-settings';
import btn from '../../ui/btn.vue';
import HandleDisclaimer from './../../../mixins/handle-disclaimer';
import PageTitle from '../../ui/page-title.vue';

export default {
  mixins: [HandleDisclaimer],
  components: {
    btn,
    PageTitle,
  },
  beforeMount() {
    this.setSettings();
  },
  methods: {
    back() {
      this.$emit('input', false);
    },
    setSettings() {
      apiCalls.getKvExpirationDays()
        .then(res => {
          this.expirationDays = res.expirationDays;
        })
        .catch(() => {
          this.errorFlag = true;
          this.message = 'No es posible mostrar las cofiguraciones. Intenta nuevamente';
        });
    },
    changeExpirationDays(days) {
      this.expirationDays = days;
    },
    updateExpirationDays() {
      this.cleanFlags();
      const days = this.expirationDays;
      apiCalls.updateKvExpirationDays(days)
        .then(() => {
          this.successFlag = true;
          this.message = 'La configuración fue cambiada exitosamente';
          this.setSettings();
          window.scrollTo(0, 0);
        })
        .catch(() => {
          this.errorFlag = true;
          this.message = 'La configuración no pudo ser modificada. Intenta nuevamente';
          window.scrollTo(0, 0);
        });
    },
    cleanFlags() {
      this.errorFlag = false;
      this.successFlag = false;
      this.message = '';
    },
  },
  data() {
    return {
      mdiHelpCircleOutline,
      options: [3, 4, 5, 6, 7],
      expirationDays: null,
      errorFlag: false,
      successFlag: false,
      message: '',
    };
  },
};
</script>
<style lang="scss" scoped>

</style>

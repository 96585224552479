import Vue from 'vue/dist/vue.esm'
import * as selectionProcessApi from '../api/selection-process'
import * as applicantStagesApi from '../api/applicant-stage'
import * as stagesApi from '../api/stage'
import {
  getIntProcess,
  createOrUpdateIntSelectionProcess,
  publishLaborumOffer,
  publishTrabajandoOffer,
  publishChiletrabajosOffer,
  changeLinkedinStatus,
} from '../api/offer-diffusion'

const APPLICANTS_PAGINATION_SIZE = 50

const initialState = {
  /*
    'stages' Es el arreglo maestro de etapas del process board. Contiene datos de cada stage y a su vez
    cada stage contiene sus applicantStages, además de un arreglo totalApplicantStages con el total de ids de postulantes (sin paginar), 
    En el caso de usar filtros, el front usará filtered.stages (clon de stages con la misma estructura)
  */
  stages: [], 
  filtered: {
    active: false,
    stages: [],
    query: {},
    scroll: false,
  },
  actionableApplicantStagesIds: [],
  actionModalType: null,
  showMSModal: false,
  // Movement data
  selectedApplicantStages: [],
  movingApplicantCardsData: {
    currentStageId: null,
    destinationStageId: null,
    destinationCardOrder: null,
    undoStages: {},
    undoApplicantStagesIds: [],
  },
  movingLoadingStatus: false,
  loading: false,
  error: null,
  processSelected: false,
  availableMicroservices: [],
  showCloseProcess: false,
  intProcessSelectedLaborum: {
    state: false,
  },
  intProcessSelectedTrabajando: {
    state: false,
  },
  intProcessSelectedChiletrabajos: {
    state: false,
  },
  selectionProcess: {
    name: null,
    endDate: null,
    vacancies: null,
    users: null,
    areaId: null,
    jobAreaId: null,
    positionId: null,
    positionLevelId: null,
    educationLevelId: null,
    academicSituationId: null,
    jobDescription: null,
    jobRequirements: null,
    jobBenefits: null,
    jobModality: null,
    jobType: null,
    confidentialCompanyName: null,
    countryId: null,
    commune: null,
    region: null,
    cvFirstStage: false,
    ownerId: null,
  },
  selectionProcessCopyId: null,
  automatizationCopyCheckbox: null,
  // Oferta KeyJobs
  kjOffer: {
    hasKj: false,
    modality: null,
    country: null,
    teletrabajo: null,
    region: null,
    commune: null,
    description: null,
    demands: null,
    benefit: null,
    isOfferActive: false,
    url: null,
    directUrl: null,
    errors: null,
    linkedin: false,
  },
  directKjUrl: null,
  laborumOffer: {
    hasLaborum: false,
    title: null,
    description: null,
    accessibleJob: false,
    country: null,
    province: null,
    city: null,
    area: null,
    subarea: null,
    level: null,
    jobType: null,
    confidential: false,
    modality: null,
    vacancies: null,
    isOfferActive: false,
    url: null,
    errors: null,
  },
  trabajandoOffer: {
    hasTrabajando: false,
    confidential: false,
    fantasyName: null,
    description: null,
    demands: null,
    jobTypeId: null,
    jobAreaId: null,
    companyActivityId: null,
    workDayId: null,
    educationLevelId: null,
    academicSituationId: null,
    countryId: null,
    regionId: null,
    commune: null,
    workplace: null,
    isOfferActive: false,
    url: null,
    errors: null,
  },
  trabajandoScraping: {
    title: null,
    description: null,
    jobType: null,
    vacancies: null,
    confidential: false,
    modality: null,
    country: null,
    address: null,
    neighborhood: null,
    region: null,
    companyActivity: null,
    hierarchy: null,
    workDay: null,
    salary: null,
    requirements: null,
    skill: null,
    skillOption: null,
    minimumStudies: null,
    academicSituation: null,
    validity: null,
  },
  chiletrabajosOffer: {
    title: null,
    vacancy: null,
    publishedBy: null,
    phone: '',
    offerEmail: null,
    startOfOffer: '',
    offerDuration: null,
    salary: null,
    city: null,
    category: null,
    jobType: null,
    private: null,
    hideEmail: null,
    emailNotification: null,
    inclusionLaw: null,
    description: null,
    plan: 'free',
  },
  loadingPublishLaborum: false,
  loadingPublishTrabajando: false,
  loadingPublishChiletrabajos: false,
  kcUserId: null,
  completedAction: false,
  locked: false,
  companyCountryId: null,
  applicantsSortBy: 'alphabetical',
  applicantsSortDirection: 'ascending',
  trabajandoLoginType: null,
  setCredentialsModal: false,
  countriesList: [],
  batchAction: null,
  hasBatchUploadError: false,
}

export const mutations = {
  // Stages
  setStages(state, stagesArray) {
    state.stages = stagesArray;
  },
  setOrAddStage(state, stageData) {
    if (stageData.id) {
      const index = state.stages.findIndex(stage => stage.id === stageData.id);
      // si ya existe reemplaza la etapa
      if (index !== -1)
        state.stages[index] = stageData
      else
        state.stages.push(stageData)
    }
  },
  removeStage(state, stageId) {
    const index = state.stages.findIndex(stage => stage.id === stageId);
    if (index !== -1) {
      state.stages.splice(index, 1);
    }
  },
  updateStage(state, updatedStage) {
    const index = state.stages.findIndex(stage => stage.id === updatedStage.id);
    if (index !== -1) {
      const existingStage = state.stages[index];
      state.stages[index] = { ...existingStage, ...updatedStage };
    }
  },
  // ApplicantStages
  /*
    Se encarga de setear los applicantStages para el board
  */
  setApplicantStagesToStage(state, { stageId, applicantStages }) {
    const stage = state.stages.find(stage => stage.id === stageId)
    Vue.set(stage, 'applicantStages', applicantStages)
  },
  addApplicantStagesPagination(state, { stageId, applicantStages, currentPage, totalPages }) {
    const stages = !state.filtered.active ? state.stages : state.filtered.stages
    const stage = stages.find(stage => stage.id === stageId)

    if(stage) {
      stage.applicantStages.push(...applicantStages)
      this.commit('setPaginationDataStage', { stageId, currentPage, totalPages })
    } 
  },
  async addOrUpdateApplicantStage(state, { stageId, applicantStage, action }) {
    if (action !== 'update') return

    let foundApplicant = null

    const stages = !state.filtered.active ? state.stages : state.filtered.stages
    const oldStage = stages.find(stage => stage.applicantStages.map(as => {
      if (as.applicantId === applicantStage.applicantId) {
        foundApplicant = as
        return
      }
    })) // find applicant in stages

    /* FIXME: solucion parche para cuando el channel actualiza un postulante trayendo uno que es igual.
     Bug producido desde el back: se gatilla el boardUpdate con un postulante "actualizado" siendo que el postulante viene con la misma data (etapa anterior y no de destino, id viejo del applicantStage) */
    if(stageId === oldStage.id && applicantStage.id === foundApplicant?.id) return

    const newStage = stages.find(stage => stage.id === stageId)

    if(newStage) {
      const asCount = newStage.applicantStages.length
      
      if(newStage && (asCount < APPLICANTS_PAGINATION_SIZE)) { // añadir card si la paginacion no exede limite
        const asIndex = newStage.applicantStages.findIndex(as => as.applicantId === applicantStage.applicantId)
        // si ya existe reemplaza el applicantStage
        if (asIndex !== -1)
          newStage.applicantStages.splice(asIndex, 1, {
            ...newStage.applicantStages[asIndex],
            ...applicantStage
          })
        else
          newStage.applicantStages.push(applicantStage)
      }
      this.commit('addApplicantToTotalApplicants', { stageId: applicantStage.stage, applicantStageId: applicantStage.id }) // agrega el id al state TotalApplicants independiente del limite de paginacion 
    }
  },
  updateApplicantStage(state, payload) {
    const { stageId, updatedApplicantStage } = payload
    const stage = state.stages.find(stage => stage.id === stageId)

    if (stage) {
      const asIndex = stage.applicantStages.findIndex(applicantStage => applicantStage.applicantId === updatedApplicantStage.applicantId);
      
      // console.log('Actualizando id:', stage.applicantStages[asIndex]?.id, stage.applicantStages[asIndex]?.applicantName, 'newAsId: ', updatedApplicantStage?.id)
      if (asIndex !== -1) {
        stage.applicantStages.splice(asIndex, 1, {
          ...stage.applicantStages[asIndex],
          ...updatedApplicantStage
        })
      }
    }
  },
  deleteApplicantStage(state, { applicantStageId, stageId, applicantId = null }) {
    const stage = state.filtered.active ? state.filtered.stages.find(stage => stage.id === stageId) : state.stages.find(stage => stage.id === stageId)
    let index

    if(stage) {
      index = stage.applicantStages.findIndex(as => as.id === applicantStageId)
      if (index === -1) index = stage.applicantStages.findIndex(as => as.applicantId === applicantId) // Si no encuentra busca por applicantId

      if (index !== -1) stage.applicantStages.splice(index, 1)
    }

    this.commit('deleteApplicantFromTotalApplicants', { applicantStageId, stageId })
  },
  // Loadings
  setLoadingStage(state, { stageId, loading }) {
    const stages = !state.filtered.active ? state.stages : state.filtered.stages;
    const stage = stages.find(stage => stage.id === stageId);
    if (stage) stage.loading = loading;
  },
  setLoadingStatus(state, payload) {
    state.loading = payload;
  },
  setMovingLoadingStatus(state, payload) {
    state.movingLoadingStatus = payload;
  },
  setLoadingPublishLaborum(state, payload) {
    state.loadingPublishLaborum = payload;
  },
  setLoadingPublishTrabajando(state, payload) {
    state.loadingPublishTrabajando = payload;
  },
  setLoadingPublishChiletrabajos(state, payload) {
    state.loadingPublishChiletrabajos = payload;
  },
  setLoadingApplicantStages(state, { applicantStageIds, stageId, loading = true }) {
    const stage = state.stages.find(stage => stage.id === stageId)
    const applicantStages = stage.applicantStages;

    applicantStageIds.map(asId => {
      const applicantStage = applicantStages.find(as => as.id === asId)
      if(applicantStage) Vue.set(applicantStage, 'loading', loading)
    })
  },
  // Modals
  setUndoApplicantStagesIds(state, payload) {
    state.movingApplicantCardsData.undoApplicantStagesIds = payload;
  },
  setActionModalType(state, payload) {
    state.actionModalType = payload;
  },
  setActionableApplicantStagesIds(state, payload) {
    state.actionableApplicantStagesIds = payload;
  },
  setCloseProcessStatus(state, payload) {
    state.showCloseProcess = payload;
  },
  setApplicantsSorting(state, payload) {
    state.applicantsSortBy = payload.sorting;
    state.applicantsSortDirection = payload.order;
  },
  setCompanyCountryId(state, payload) {
    state.companyCountryId = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  // Applicants Movement
  setMovingApplicantCardData(state, { currentStageId, destinationStageId, destinationCardOrder }) {
    state.movingApplicantCardsData = {
      ...state.movingApplicantCardsData,
      currentStageId,
      destinationStageId,
      destinationCardOrder
    }
  },
  setSelectedCards(state, selectedIdsArr) {
    state.selectedApplicantStages = selectedIdsArr
  },
  resetActionState(state) {
    state.movingApplicantCardsData.undoStages = {};
    state.movingApplicantCardsData.destinationStageId = null;
    state.selectedApplicantStages = [];
    state.actionableApplicantStagesIds = [];
  },
  // Filters
  setFilterActive(state) {
    state.filtered.active = true;
    state.filtered.stages = [];
    state.filtered.scroll = false;
  },
  setFilterScroll(state) {
    state.filtered.scroll = true;
  },
  setFilteredStage(state, { stage, applicantStages }) {
    const { id: stageId } = stage

    state.filtered.active = true

    if (stageId) {
      const fitleredStageData = {
        id: stageId,
        ...stage,
        applicantStages,
      }

      // find & replace existant
      const stageIndex = state.filtered.stages.findIndex(stage => stage.id === stageId)
      if (stageIndex !== -1) {
        state.filtered.stages.splice(stageIndex, 1, fitleredStageData)
      } else {
        state.filtered.stages.push(fitleredStageData)
      }
    }
  },
  resetFilters(state) {
    state.filtered.active = false;
    state.filtered.stages = [];
    state.filtered.query = {};
    state.filtered.notifyChange = !state.filtered.notifyChange;
  },
  setQueryFilter(state, payload) {
    state.filtered.query = payload; 
    state.filtered.notifyChange = !state.filtered.notifyChange;
  },
  deleteFilterStages(state) {
    state.filtered.stages = [];
  },
  setAvailableMicroservices(state, payload) {
    state.availableMicroservices = payload;
  },
  setSelectionProcessCopyId(state, payload) {
    state.selectionProcessCopyId = payload;
  },
  setAutomatizationCopyCheckbox(state, payload) {
    state.automatizationCopyCheckbox = payload;
  },
  setSelectionProcessData(state, payload) {
    state.selectionProcess[payload.key] = payload.value;
  },
  setProcessSelectedData(state, payload) {
    state.processSelected[payload.key] = payload.value;
  },
  setIntProcessSelectedData(state, payload) {
    state.intProcessSelected[payload.key] = payload.value;
  },
  setKjOfferData(state, payload) {
    Vue.set(state.kjOffer, payload.key, payload.value);
  },
  setLaborumOfferData(state, payload) {
    state.laborumOffer[payload.key] = payload.value;
  },
  setTrabajandoOfferData(state, payload) {
    state.trabajandoOffer[payload.key] = payload.value;
  },
  setTrabajandoScrapingData(state, payload) {
    state.trabajandoScraping[payload.key] = payload.value;
  },
  setChiletrabajosOffer(state, payload) {
    state.chiletrabajosOffer[payload.key] = payload.value;
  },
  setKcUserId(state, payload) {
    state.kcUserId = payload;
  },
  setKiRating(state, { applicantStageId, stageId, rating }) {
    const stage = state.stages.find(stage => stage.id === stageId)
    const applicantStage = stage.applicantStages.find(as => as.id === applicantStageId)
    applicantStage.tabData[0].tabLabel3 = `${(100 * rating) / 5}%`;
    applicantStage.tabData[0].tabLabel2 = '';
  },
  // Process
  setProcessSelected(state, payload) {
    state.processSelected = payload;
    if (payload.offerData !== null) state.kjOffer = payload.offerData;
    if (payload.trabajandoOfferData !== null) state.trabajandoOffer = payload.trabajandoOfferData;
  },
  setDirectKjUrl(state, payload) {
    state.directKjUrl = payload
    state.kjOffer.directUrl = payload
  },
  setLaborumState(state, payload) {
    state.intProcessSelectedLaborum.state = payload.state;
  },
  setTrabajandoState(state, payload) {
    state.intProcessSelectedTrabajando.state = payload.state;
  },
  setChiletrabajosState(state, payload) {
    state.intProcessSelectedChiletrabajos.state = payload.state;
  },
  setYapoState(state, payload) {
    state.intProcessSelectedYapo.state = payload.state;
  },
  setLinkedOfferLaborum(state, payload) {
    state.intProcessSelectedLaborum.linkedOffer = payload;
  },
  setLinkedOfferTrabajando(state, payload) {
    state.intProcessSelectedTrabajando.linkedOffer = payload;
  },
  setLinkedOfferChiletrabajos(state, payload) {
    state.intProcessSelectedChiletrabajos.linkedOffer = payload;
  },
  setIntProcessSelected(state, payload) {
    if (!(Object.keys(payload.data.data).length === 0)) {
      const service = payload.data.data.intSelectionProcess.intConfigId;
      let jsonExternal;
      switch (service) {
      case 1:
        state.intProcessSelectedLaborum = payload.data.data.intSelectionProcess;
        jsonExternal = payload.data.data.intSelectionProcess.jsonProcessExternal;
        if (jsonExternal !== null) state.laborumOffer = jsonExternal;
        break;
      case 2:
        state.intProcessSelectedTrabajando = payload.data.data.intSelectionProcess;
        jsonExternal = payload.data.data.intSelectionProcess.jsonProcessExternal;
        if (jsonExternal !== null) state.trabajandoScraping = jsonExternal;
        break;
      case 7:
        state.intProcessSelectedChiletrabajos = payload.data.data.intSelectionProcess;
        jsonExternal = payload.data.data.intSelectionProcess.jsonProcessExternal;
        if (jsonExternal !== null) state.chiletrabajosOffer = jsonExternal;
        break;
      default:
        break;
      }
    }
  },
  setCompletedAction(state, payload) {
    state.completedAction = payload;
  },
  setLocked(state, payload) {
    state.locked = payload;
  },
  setPaginationDataStage(state, { stageId, currentPage, totalPages }) {
    const stage = state.filtered.active ? state.filtered.stages.find(stage => stage.id === stageId) : state.stages.find(stage => stage.id === stageId)
    stage.paginationData = { currentPage, totalPages }
  },
  /* Arreglo Ids de ApplicantsStages totales de una etapa (no paginados) */
  setTotalApplicants(state, { stageId, applicantStageIds }) {
    const stage = state.filtered.active ? state.filtered.stages.find(stage => stage.id === stageId) : state.stages.find(stage => stage.id === stageId)
    Vue.set(stage, 'totalApplicants', applicantStageIds)
  },
  addApplicantToTotalApplicants(state, { stageId, applicantStageId }) {
    const stage = state.filtered.active ? state.filtered.stages.find(stage => stage.id === stageId) : state.stages.find(stage => stage.id === stageId)
  
    if(stage) stage.totalApplicants = [...stage.totalApplicants, applicantStageId]
    // console.log('%c+ adding', 'color: purple', applicantStageId,'to totals in', stage.name, stageId)
  },
  deleteApplicantFromTotalApplicants(state, { stageId, applicantStageId }) {
    const stage = state.filtered.active ? state.filtered.stages.find(stage => stage.id === stageId) : state.stages.find(stage => stage.id === stageId)
  
    if (stage) {
      const index = stage.totalApplicants.findIndex(id => id === applicantStageId)
      
      if (index !== -1) {
        stage.totalApplicants = stage.totalApplicants.filter(id => id !== applicantStageId)
      }
    }
    // console.log('%c- deleting', 'color: mediumpurple', applicantStageId,'from totals in', stage.name, stageId)
  },
  /* 
    Busca postulante entre las etapas, lo agrega al array de potulantes del nuevo stage,
    y lo elimina del array de la etapa anterior
  */
  moveApplicantToStage(state, { currentStageId, newStageId, oldAsId, applicantStage = null, applicantStageId = null }) {
    let currentStage = null
    let newStage = null
    
    currentStage = state.stages.find(stage => stage.id === currentStageId)

    // Busca la nueva etapa
    newStage = state.stages.find(stage => stage.id === newStageId)
    
    // Elimina al postulante de la etapa anterior si se encontró la etapa
    if (currentStage)
      this.commit('deleteApplicantStage', { applicantStageId: oldAsId, stageId: currentStageId, applicantId: applicantStage.applicantId })

    const adjustedIndex = currentStage.applicantStages.length + applicantStage?.orderNumber

    /* 
      Verifica si se encontraron ambas etapas e inserta en la nueva. 
      Antes verifica si se está en la última página, si es así, verifica que esta no esté en el limite de 50, de lo contrario no agregará la tarjeta a la paginacion
    */
    if(currentStage && newStage) {
      const { currentPage, totalPages } = newStage.paginationData
      const totalApplicantCards = newStage.applicantStages.length
      if(currentPage === totalPages || !totalPages) {
        // cálculo para determinar si agregar la card o no
        const lastPageApplicantsLength = (totalApplicantCards % APPLICANTS_PAGINATION_SIZE || totalApplicantCards) || totalApplicantCards
        const isLastPageFull = lastPageApplicantsLength >= APPLICANTS_PAGINATION_SIZE
  
        if (!isLastPageFull) {
          newStage.applicantStages.splice(adjustedIndex, 0, applicantStage)
        }
      }
      this.commit('addApplicantToTotalApplicants', { stageId: newStageId, applicantStageId: applicantStage.id })
    }
  },
  moveFilteredApplicantToStage(state, { applicantStage, currentStageId, newStageId, oldAsId }) {
    let currentStage = null
    let newStage = null

    currentStage = state.filtered.stages.find(stage => stage.id === currentStageId)

    // Busca la nueva etapa
    newStage = state.filtered.stages.find(stage => stage.id === newStageId)

    // Elimina al postulante de la etapa anterior si se encontró
    if (currentStage)
      this.commit('deleteApplicantStage', { applicantStageId: oldAsId, stageId: currentStageId, applicantId: applicantStage.applicantId })

    const adjustedIndex = currentStage.applicantStages.length + applicantStage.orderNumber

    /* 
      Verifica si se encontraron ambas etapas e inserta en la nueva. 
      Antes verifica si se está en la última página, si es así, verifica que esta no esté en el limite de 50, de lo contrario no agregará la tarjeta a la paginacion
    */
    if(currentStage && newStage) {
      const { currentPage, totalPages } = newStage.paginationData
      const totalApplicantCards = newStage.applicantStages.length
      if(currentPage === totalPages || !totalPages) {
        // cálculo para determinar si agregar la card o no
        const lastPageApplicantsLength = (totalApplicantCards % APPLICANTS_PAGINATION_SIZE || totalApplicantCards) || totalApplicantCards
        const isLastPageFull = lastPageApplicantsLength >= APPLICANTS_PAGINATION_SIZE

        if (!isLastPageFull) {
          newStage.applicantStages.splice(adjustedIndex, 0, applicantStage)
        }
      }
      this.commit('addApplicantToTotalApplicants', { stageId: newStageId, applicantStageId: applicantStage.id })
    }
  },
  setTrabajandoLoginType(state, payload) {
    state.trabajandoLoginType = payload;
  },
  setCredentialsModal(state) {
    state.setCredentialsModal = !state.setCredentialsModal;
  },
  setCountriesList(state, payload) {
    state.countriesList = payload;
  },
  setBatchAction(state, payload) {
    state.batchAction = payload;
  },
  orderStages(state) {
    if(state.filtered.active)
      state.filtered.stages = state.filtered.stages.sort((a, b) => a.orderNumber - b.orderNumber)
    else
      state.stages = state.stages.sort((a, b) => a.orderNumber - b.orderNumber)
  },
  orderApplicants(state) {
    if(state.filtered.active)
      state.filtered.stages.map(stage => {
        stage.applicantStages = stage.applicantStages.filter(as => as.current)
          .sort((a, b) => (a.currentOrderNumber || a.orderNumber) - (b.currentOrderNumber || b.orderNumber))
      })
    else
      state.stages.map((stage) => {
        stage.applicantStages = stage.applicantStages.filter(as => as.current)
          .sort((a, b) => (a.currentOrderNumber || a.orderNumber) - (b.currentOrderNumber || b.orderNumber))
      });
  }
};

export const actions = {
  /*
    Obtiene datos del proceso desde la API, gatilla función que obtiene postulantes
  */
  async getProcessSelected({ commit, dispatch }, processId) {
    commit('setLoadingStatus', true)
    commit('setError', false)

    const process = await selectionProcessApi.getProcess(processId)

    // KJ direct url by slug (get slug from offer url)
    const url = await selectionProcessApi.getKJOfferUrlBySlug(process?.offerData?.url)
    commit('setDirectKjUrl', url)

    // PROCESO
    commit('setProcessSelected', process)

    // ETAPAS
    commit('setStages', []); // evitar duplicados en llamadas posteriores
    await dispatch('setStagesAndApplicantStages', { stages: process.stages })

    // ya que vienen desordenados desde el servicio
    commit('orderStages')

    commit('setLoadingStatus', false)
  },
  /*
    Setea el stage en el arreglo de stages y obtiene ApplicantStages desde la API
  */
  async setStagesAndApplicantStages({ commit, rootState }, {stages}) {
    stages.map(async (stage) => {
      /* 
        Para que no se rompa el front se vacían los applicantStage de c/ etapa (ya que espera array de objetos ApplicantStage, no array de ids) 
        se reemplazará con array de applicantStages reales.
        Setear loading de la etapa.
      */
      const withCleanArraysApplicantStages = { ...stage, applicantStages: [], totalApplicants: [], loading: true }
      commit('setOrAddStage', withCleanArraysApplicantStages) // add to store

      if (!rootState.tour.onTour) {
        applicantStagesApi.getAll({ stageId: stage.id, page: 1 })
          .then((res) => {
            const { currentPage, totalPages } = res.data.paginatedResponse
            commit('setPaginationDataStage', { stageId: stage.id, currentPage, totalPages })
            const filtradosIds = []
            // Setear estado de asignacion de c/postulante
            res.data.totalStates.forEach((asState, index) => {
              if (asState !== 'reassigning') filtradosIds.push(res.data.totalIds[index])
            })

            commit('setTotalApplicants', { stageId: stage.id, applicantStageIds: res.data.totalIds })

            const applicantStages = res.data.paginatedResponse.data
            commit('setApplicantStagesToStage', { stageId: stage.id, applicantStages })
            commit('setLoadingStage', { stageId: stage.id, loading: false })
          })
      }
    })
  },
  async getIntProcessSelected({ commit }, payload) {
    commit('setError', false);
    commit('setIntProcessSelected', await getIntProcess(payload))
  },
  getSelectionProcess({ commit, dispatch }, payload) { // en realidad hace exactamente lo mismo que getProcessSelected
    commit('setLoadingStatus', true);
    commit('setError', false);

    return selectionProcessApi.getSelectionProcess(payload)
      .then((res) => {
        const { stages } = res.data.selectionProcess
        dispatch('setStagesAndApplicantStages', { stages })
      })
      .catch((err) => {
        commit('setError', err)
      })
      .finally(() => {
        commit('setLoadingStatus', false)
      })
  },
  moveStage({ commit, dispatch, state }, payload) {
    const filteredStages = state.stages
      .map(stage => ({ id: stage.id, orderNumber: stage.orderNumber }))
      .filter(stage => stage.id !== payload.stageId)
      .sort((a, b) => a.orderNumber - b.orderNumber)

    commit('setError', false)

    return selectionProcessApi.moveStage(payload.id, payload.stageId, payload.orderNumber)
      .then((res) => {
        const { stages } = res.data.selectionProcess
        dispatch('setStagesAndApplicantStages', { stages })
      })
      .catch(err => {
        commit('setError', err);
        filteredStages.forEach(stage => {
          commit('updateStage', { id: payload.stageId, orderNumber: stage.orderNumber })
        })
      })
  },
  /*
    Obtiene desde service applicantStages paginacion por stageId
  */
  async getAndSetApplicantStages({ commit, rootState }, { stageId, page }) {
    // por etapa
    await applicantStagesApi.getAll({ stageId, page }).then((res) => {
      const { currentPage, totalPages } = res.data.paginatedResponse
      commit('setPaginationDataStage', { stageId, currentPage, totalPages: totalPages })
      const filtradosIds = []
      // estado de asignacion de c/postulante
      res.data.totalStates.forEach((asState, index) => {
        if (asState !== 'reassigning') filtradosIds.push(res.data.totalIds[index])
      });

      commit('setTotalApplicants', { stageId, applicantStageIds: res.data.totalIds })
      commit('setFilteredTotalApplicants', { stageId, total: filtradosIds })
    }); 
  },
  /*
    Obtiene applicantStages por stageId
  */
  getAndSetApplicantStage({ commit, rootState }, { stageId }) {
    return applicantStagesApi.getAll({stageId}).then((res) => {
      const applicantStages = res.data.paginatedResponse.data

      if (!rootState.tour.onTour)
        commit('setApplicantStagesToStage', {stageId, applicantStages});
    });
  },
  // eslint-disable-next-line max-statements
  moveApplicantStages({ commit, dispatch, state }, {
    action,
    applicantStageIds,
    originStageId,
    destinationStageId,
    orderNumber = 0,
    localOnly,
  }) {
    const actionableActions = ['added', 'moved']
    const destinationStage = state.stages.find(stage => stage.id === destinationStageId)

    if (actionableActions.includes(action)) {
      const ids = applicantStageIds

      commit('setLoadingApplicantStages', { applicantStageIds: ids, stageId: originStageId, loading: false })

      commit('setActionableApplicantStagesIds', ids)

      const undoApplicantsMovement = state.selectedApplicantStages.length ? state.selectedApplicantStages : state.stages.find(stage => stage.id === originStageId).applicantStages.filter(as => ids.includes(as.id))
      commit('setUndoApplicantStagesIds', undoApplicantsMovement)

      if (!localOnly) {
        const movingApplicantCardsData = {
          currentStageId: originStageId,
          destinationStageId: destinationStageId,
          destinationCardOrder: orderNumber,
        }

        commit('setMovingApplicantCardData', movingApplicantCardsData)

        /* Comprueba si alguno de los postulantes que se van a mover ya estuvieron antes en la etapa de destino */
        const someAlreadyBeenInStage = state.stages.find(stage => stage.id === originStageId).applicantStages
          .filter(as => ids.includes(as.id))
          .some(as => as.stageHistory.includes(destinationStageId))

        if (destinationStage.microserviceState === 'sleeping') {
          commit('setActionModalType', 'moveToSleep')
        } else if (!someAlreadyBeenInStage && originStageId !== destinationStageId && destinationStage.microservice?.id === 6) {  // Caso MS KeyGames
          commit('setActionModalType', 'moveToMsRestriction')
        } else if (!someAlreadyBeenInStage && originStageId !== destinationStageId && !destinationStage.microservice) { // Caso la etapa no está ligada a un MicroServicio
          dispatch('serverMove')
        } else if (!someAlreadyBeenInStage && originStageId !== destinationStageId) {
          commit('setActionModalType', 'move')
        } else if (originStageId !== destinationStageId && destinationStage.stageType === 'final') { // Caso etapa de contratados
          commit('setActionModalType', 'hire')
        } else {
          commit('setActionModalType', null)
          dispatch('serverMove') // servicio de movimiento postulante
        }
      }
    }
  },
  /* (Se encarga de enviar movimientos al servidor y en respuesta elimina los anteriores y agrega a los nuevos applicantStage (con el commit moveApplicantToStage), 
  si se mueven > 2 postulantes este proceso lo gatilla el channel MoveBatchApplicantStagesChannel en el componente processBoard) */
  serverMove({ state, commit }) {
    let ids = []
    
    const { currentStageId, destinationStageId, destinationCardOrder } = state.movingApplicantCardsData

    // Al mover seleccionados
    if (state.selectedApplicantStages.length > 0) {
      ids = state.selectedApplicantStages.map(as => as.id)
    // Al mover uno
    } else if (state.actionableApplicantStagesIds[0]) {
      ids.push(state.actionableApplicantStagesIds[0])
    }

    applicantStagesApi.arrMove(
      ids,
      destinationStageId,
      destinationCardOrder,
      destinationStageId,
    ).then((res) => { 
      if(ids.length > 2) {
        // console.log('%cMoviendo (large): ', 'color: slateblue', state.selectedApplicantStages.map(applicantStage => ({ applicantId: applicantStage.id, currentStageId, destinationStageId })))
        // Cuando se mueven 2 o más postulantes, el channel BoardUpdatesChannel se encarga de insertar las nuevas tarjetas (y eliminar las ant.)
      } else {
        // console.log('%cMoviendo (small): ', 'color: darkslateblue', res.data.applicantStages.map(newApplicantStage => ({ applicantId: newApplicantStage.id, currentStageId, destinationStageId })))

        res.data.applicantStages.map((newApplicantStage) => { 
          const { stage: newStageId } = newApplicantStage
          if (state.filtered.active)
            commit('moveFilteredApplicantToStage', { applicantStage: newApplicantStage, currentStageId, newStageId, oldAsId: ids[0] })
          else {
            const oldApplicantStage = state.selectedApplicantStages.find(as => as.applicantId === newApplicantStage.applicantId)
            commit('moveApplicantToStage', { currentStageId, newStageId, oldAsId: oldApplicantStage?.id || state.actionableApplicantStagesIds[0], applicantStage: newApplicantStage })
          }
        })
      }
    })
    .then(() => {
      commit('resetActionState')
      commit('setMovingLoadingStatus', false)
    })
    .catch((e) => {
      console.error(e)
      commit('setError', e)
      commit('setMovingLoadingStatus', false)
    });
  },
  changeHiringState({ commit }, payload) {
    commit('setLoadingStatus', true)
    commit('setError', false)

    return applicantStagesApi.unhire(payload)
      .then((res) => {
        commit('updateApplicantStage', res.data.applicantStage)
      })
      .catch((err) => {
        commit('setError', err)
      })
      .finally(() => {
        commit('setLoadingStatus', false)
      })
  },
  cancelMoving({ state, commit }) {
    const applicantStages = state.movingApplicantCardsData.undoApplicantStagesIds

    applicantStages.map(as => {
      commit('updateApplicantStage', { 
        stageId: as.stage,
        updatedApplicantStage: { id: as.id, currentStage: null, currentOrderNumber: null, loading: false }
      })
    })

    commit('setLoadingApplicantStages', { applicantStageIds: applicantStages.map(as => as.id), stageId: applicantStages[0].stage, loading: false })
    commit('resetActionState')
  },
  discardApplicantStages({ commit }, { ids, stageId, discardReason }) {
    commit('setLoadingStage', { stageId, loading: true })
    return Promise.all(
      ids.map(async (id) => {
        commit('updateApplicantStage', { stageId, updatedApplicantStage: { id, loading: true } })

        return applicantStagesApi.discard(id, discardReason)
          .then(() => {
            commit('deleteApplicantStage', { applicantStageId: id, stageId })
            // commit('deleteApplicantFromTotalApplicants', { applicantStageId: id, stageId })
          })
      })
    )
  },
  // Select Cards Actions
  selectCard({ state, commit }, applicantStage) {
    const currentSelected = state.selectedApplicantStages
    commit('setSelectedCards', [ ...currentSelected, applicantStage ])
  },
  deselectCard({ commit, state }, applicantStageId) {
    const newSelectedCards = state.selectedApplicantStages
      .filter(as => as.id != applicantStageId)
    commit('setSelectedCards', newSelectedCards)
  },
  toggleShowCloseProcess({ commit }, payload) {
    commit('setCloseProcessStatus', payload);
  },
  getAvailableMicroservices({ commit }, payload) {
    selectionProcessApi.getAvailableMicroservices(payload)
      .then(response => {
        commit('setAvailableMicroservices', response.microservices);
      })
      .catch(err => {
        commit('setError', err);
      });
  },
  // Process Actions
  async openProcess({ commit }, { processId }) {
    commit('setLoadingStatus', true);

    return selectionProcessApi.updateSelectionProcessState({ id: processId, state: 0 })
      .finally(() => {
        commit('setLoadingStatus', false);
      });
  },
  changeStageName({ commit }, payload) {
    commit('updateStage', { ...payload, loading: true })

    stagesApi.update(payload.id, {
      name: payload.name,
    })
      .catch(err => {
        commit('setError', err)
      })
      .finally(() => {
        commit('updateStage', { id: payload.id, loading: false })
      })
  },
  async closeProcess({ commit }, { processId, applicantStages, offerActive }) {
    commit('setLoadingStatus', true)

    if (applicantStages) {
      await Promise.all(applicantStages.map(as =>
        applicantStagesApi.update(as.id, as.stage, null, { hiringState: 1 }),
      ))
    }

    if (offerActive) selectionProcessApi.changeOfferActive({ processId, active: false })

    return selectionProcessApi.updateSelectionProcessState({ id: processId, state: 1 })
      .finally(() => {
        commit('setLoadingStatus', false)
      })
  },
  async deleteProcess({ commit }, { processId }) {
    commit('setLoadingStatus', true)

    return selectionProcessApi.updateSelectionProcessState({ id: processId, state: 5 })
      .finally(() => {
        commit('setLoadingStatus', false)
      })
  },
  async filterBoard({ commit, state }, payload) {
    commit('setSelectedCards', [])
    commit('deleteFilterStages', state)
    commit('setQueryFilter', payload)

    /* hará la llamada por cada stage y setea los applicantStages paginados */
    return Promise.all(state.processSelected.stages
      .map(stage => {
        // loading status
        commit('setLoadingStatus', true)
        stage.loading = true
        stage.totalApplicants = []

        commit('setFilteredStage', { stage, applicantStages: [] }) // setear solo info de etapas

        applicantStagesApi.filterBy(payload, stage.id, 1)
          .then(res => {
            stage.totalApplicants = res.data.totalIds
            stage.loading = false

            const { currentPage, totalPages } = res.data.paginatedResponse
            stage.paginationData = { currentPage, totalPages }
            
            // actualizar etapa con postulantes obtenidos
            const applicantStages = res.data.paginatedResponse.data
            commit('setFilteredStage', { stage, applicantStages })

            commit('orderStages')
          })
          .catch(err => {
            commit('setError', err)
          })
          .finally(() => {
            commit('setLoadingStatus', false)
          })
      })
    )
  },
  archiveStage({ commit }, payload) {
    const { id } = payload
    commit('updateStage', { id, ...payload, loading: true })
    
    stagesApi.archive(id)
    .finally(() => {
      commit('removeStage', id)
    })
    .catch(err => {
      commit('setError', err)
    })
  },
  createSelectionProcess({ commit, getters, state }) {
    commit('setLoadingStatus', true);
    commit('setError', false);
    const ps = { ...state.processSelected, ...state.selectionProcess };

    return selectionProcessApi.createSelectionProcess(
      ps,
      getters.kjOffer,
      getters.trabajandoOffer,
      state.selectionProcessCopyId,
      state.automatizationCopyCheckbox,
    )
      .catch(() => {
        commit('setLoadingStatus', false);
        commit('setError', true);
      });
  },
  updateSelectionProcess({ commit, state }, { processId, isEditProcess }) {
    commit('setLoadingStatus', true);
    commit('setError', false);

    let ps = { ...state.processSelected };
    if (isEditProcess) {
      ps = { ...ps, ...state.selectionProcess };
    }

    return selectionProcessApi.updateSelectionProcess(
      processId,
      ps,
      state.kjOffer,
      state.trabajandoOffer,
    )
      .then((res) => {
        if (res.status === 200) {
          const processUrl = `/selection_processes/${processId}`;
          window.location.replace(processUrl);
        }
      })
      .catch((err) => {
        commit('setLoadingStatus', false);
        commit('setError', true);
      });
  },
  createOrUpdateIntSelectionProcessLaborum({ commit, state, dispatch },
    { processId, intCompanyIdLaborum, portal }) {
    commit('setLoadingPublishLaborum', true);
    commit('setError', false);
    commit('setCompletedAction', false);

    return createOrUpdateIntSelectionProcess(
      processId,
      intCompanyIdLaborum,
      state.laborumOffer,
      portal,
    )
      .then(() => {
        commit('setCompletedAction', true);
        dispatch('getIntProcessSelected', { processId, intCompanyIdLaborum });
        dispatch('createOrActivateLaborum', { processId, intCompanyIdLaborum });
      })
      .catch((err) => {
        commit('setError', err);
      });
  },
  createOrUpdateIntSelectionProcessTrabajando({ commit, state, dispatch },
    { processId, intCompanyIdTrabajando, portal }) {
    commit('setLoadingPublishTrabajando', true);
    commit('setError', false);
    commit('setCompletedAction', false);

    return createOrUpdateIntSelectionProcess(
      processId,
      intCompanyIdTrabajando,
      state.trabajandoScraping,
      portal,
    )
      .then(() => {
        commit('setCompletedAction', true);
        dispatch('getIntProcessSelected', { processId, intCompanyIdTrabajando });
        dispatch('createOrActivateTrabajandoScraping', { processId, intCompanyIdTrabajando });
      })
      .catch((err) => {
        commit('setError', err);
      });
  },
  createOrUpdateIntSelectionProcessChiletrabajos({ commit, state, dispatch },
    { processId, intCompanyIdChiletrabajos, portal }) {
    commit('setLoadingPublishChiletrabajos', true);
    commit('setError', false);
    commit('setCompletedAction', false);

    return createOrUpdateIntSelectionProcess(
      processId,
      intCompanyIdChiletrabajos,
      state.chiletrabajosOffer,
      portal,
    )
      .then(() => {
        commit('setCompletedAction', true);
        dispatch('createOrActivateChiletrabajos', { processId, intCompanyIdChiletrabajos });
      })
      .catch((err) => {
        commit('setError', err);
      });
  },
  updateSelectionProcessOffer({ commit, state, dispatch }, { processId }) {
    commit('setLoadingStatus', true);
    commit('setError', false);
    commit('setCompletedAction', false);

    return selectionProcessApi.createOrUpdateOffer(
      processId,
      state.kjOffer,
      state.trabajandoOffer,
    )
      .then(() => {
        commit('setLoadingStatus', false);
        commit('setCompletedAction', true);
        dispatch('getProcessSelected', processId);
      })
      .catch((err) => {
        commit('setError', err);
      })
      .finally(() => {
        commit('setLoadingStatus', false);
      });
  },
  updateLinkedinStatus({ commit, state, dispatch }, { processId }) {
    commit('setLoadingStatus', true);
    commit('setError', false);
    commit('setCompletedAction', false);

    return changeLinkedinStatus(
      {
        linkedin: state.kjOffer.linkedin,
        selection_process_id: processId,
        id: state.kjOffer.offerId,
      },
    )
      .then((request) => {
        commit('setLoadingStatus', false);
        commit('setCompletedAction', true);
        commit('setKjOfferData', { key: 'linkedin', value: request.data.linkedin });
      })
      .catch((err) => {
        commit('setError', err);
      })
      .finally(() => {
        commit('setLoadingStatus', false);
      });
  },
  createOrActivateKj({ commit, state }, { processId }) {
    commit('setLoadingStatus', true);
    commit('setError', false);

    return selectionProcessApi.createOrActivateKjOffer( processId, state.kjOffer )
      .then(async (res) => {
        commit('setLoadingStatus', false);
        const offerData = res.data.selectionProcess.offerData;
        if (offerData) {
          // offer KJ url
          const url = await selectionProcessApi.getKJOfferUrlBySlug(offerData?.url);
          commit('setDirectKjUrl', url)
          commit('setProcessSelectedData', { key: 'offerData', value: offerData });
          commit('setKjOfferData', { key: 'isOfferActive', value: offerData.isOfferActive });
          commit('setKjOfferData', { key: 'errors', value: offerData.errors });
          return url;
        }
      })
      .catch((err) => {
        commit('setError', err);
      })
      .finally(() => {
        commit('setLoadingStatus', false);
      });
  },
  createOrActivateTrabajandoScraping({ commit }, { processId, intCompanyIdTrabajando }) {
    commit('setLoadingPublishTrabajando', true);
    commit('setError', false);

    return publishTrabajandoOffer(
      processId,
      intCompanyIdTrabajando,
    )
      .then(() => {
        commit('setLinkedOfferTrabajando', true);
      })
      .catch((err) => {
        commit('setError', err);
      });
  },
  createOrActivateLaborum({ commit }, { processId, intCompanyIdLaborum }) {
    commit('setLoadingPublishLaborum', true);
    commit('setError', false);

    return publishLaborumOffer(
      processId,
      intCompanyIdLaborum,
    )
      .then(() => {
        commit('setLinkedOfferLaborum', true);
      })
      .catch((err) => {
        commit('setError', err);
      });
  },
  createOrActivateChiletrabajos({ commit }, { processId, intCompanyIdChiletrabajos }) {
    commit('setLoadingPublishChiletrabajos', true);
    commit('setError', false);

    return publishChiletrabajosOffer(
      processId,
      intCompanyIdChiletrabajos,
    )
      .catch((err) => {
        commit('setError', err);
      });
  },
  deactivateKj({ commit, state }, { processId }) {
    commit('setLoadingStatus', true);
    commit('setError', false);

    return selectionProcessApi.deactivateKjOffer(
      processId,
      state.kjOffer,
    )
      .then((res) => {
        commit('setLoadingStatus', false);
        const offerData = res.data.selectionProcess.offerData;
        if (offerData) {
          commit('setKjOfferData', { key: 'isOfferActive', value: offerData.isOfferActive });
        }
      })
      .catch((err) => {
        commit('setError', err);
      })
      .finally(() => {
        commit('setLoadingStatus', false);
      });
  },
  createOrActivateTrabajando({ commit }, { processId }) {
    commit('setLoadingStatus', true);
    commit('setError', false);

    return selectionProcessApi.createOrActivateTrabajandoOffer(processId)
      .then((res) => {
        commit('setLoadingStatus', false);
        const offerData = res.data.selectionProcess.trabajandoOfferData;
        if (offerData) {
          this.getProcessKJUrl()
          commit('setProcessSelectedData', { key: 'trabajandoOfferData', value: offerData });
          commit('setTrabajandoOfferData', { key: 'isOfferActive', value: offerData.isOfferActive });
          commit('setTrabajandoOfferData', { key: 'errors', value: offerData.errors });
        }
      })
      .catch((err) => {
        commit('setError', err);
      })
      .finally(() => {
        commit('setLoadingStatus', false);
      });
  },
  deactivateTrabajando({ commit }, { processId }) {
    commit('setLoadingStatus', true);
    commit('setError', false);

    return selectionProcessApi.deactivateTrabajandoOffer(processId)
      .then((res) => {
        commit('setLoadingStatus', false);
        const offerData = res.data.selectionProcess.trabajandoOfferData;
        if (offerData) {
          commit('setTrabajandoOfferData', { key: 'isOfferActive', value: offerData.isOfferActive });
        }
      })
      .catch((err) => {
        commit('setError', err);
      })
      .finally(() => {
        commit('setLoadingStatus', false);
      });
  },
  changeOfferActive({ commit }, { processId, active }) {
    commit('setLoadingStatus', true);

    return selectionProcessApi.changeOfferActive({ processId, active })
      .then(() => {
        commit('setLoadingStatus', false);
      })
      .catch((err) => {
        commit('setError', err);
        commit('setLoadingStatus', false);
      });
  },
  setInitialLocked({ commit }, locked) {
    commit('setLocked', locked);
  },
};

export const getters = {
  /* 
    Procesa la data de los stages de salida que se van a renderizar en la vista,
    invoca funcion que ordena a los postulantes por orderNumber en cada etapa,
    retorna stages limpias o filtradas según corresponda
  */
  processedStages(state, getters) {
    const filteredOrCleanStages = !state.filtered.active ? state.stages : state.filtered.stages;

    const activeStages = filteredOrCleanStages.filter(stage => stage.active)

    const withOrderedApplicantStages = getters.getOrderedApplicants(activeStages)

    return withOrderedApplicantStages
  },
  processStagesMicroservices(state, g) {
    return [...new Set(g.processedStages.reduce((acc, stage) => {
      if (stage.microservice) {
        acc.push(stage.microservice.id);
      }

      return acc;
    }, []))];
  },
  getOrderedApplicants: () => (stages) => {
    const orderedApplicants = stages.map((stage) => {
      return {
        ...stage,
        applicantStages: stage.applicantStages.filter(as => as.current)
          .sort((a, b) => (a.currentOrderNumber || a.orderNumber) - (b.currentOrderNumber || b.orderNumber))
      }
    });

    return orderedApplicants
  },
  destinationStage(state) {
    return state.stages.find(stage => stage.id === state.movingApplicantCardsData.destinationStageId);
  },  
  /* 
    Los ids de postulantes totales de cada etapa (sin el limite de 50)
  */
  stageTotalApplicants: (state) => (stageId) => {
    const stage = state.stages.find(stage => stage.id === stageId)
    return stage.totalApplicants
  },
  actionableApplicantStages(state, getters) {
    return state.actionableApplicantStagesIds.map((id) => {
      const as = getters.getApplicantStageById(id);
      as.microserviceStage = state.stages.find(stage => stage.id === as.microserviceStageId);

      return as;
    });
  },
  isLocked(state) {
    return state.locked;
  },
  hasOffer(state) {
    if (state.kjOffer.isOfferActive || state.trabajandoOffer.isOfferActive ||
      state.intProcessSelectedLaborum.state === 1 ||
      (state.intProcessSelectedTrabajando.state === 1 && state.intProcessSelectedTrabajando.offerId) ||
      (state.intProcessSelectedChiletrabajos.state === 1 && state.intProcessSelectedChiletrabajos.offerId)) return true;

    return false;
  },
  filter(state) {
    return state.filtered;
  },
  isMovingLoadingStatus(state) {
    return state.movingLoadingStatus;
  },
  // Acción para obtener un applicantStage por su ID
  getApplicantStageById: (state) => (applicantStageId) => {
    for (const stage of state.stages) {
      const applicantStage = stage.applicantStages.find(as => as.id === applicantStageId);

      if (applicantStage) return applicantStage;
    }

    return null
  },
  getSelectedApplicantStagesIds(state) {
    return state.selectedApplicantStages;
  },
  availableMicroservices(state) {
    return state.availableMicroservices;
  },
  availableKeyInterviews(state) {
    return state.availableMicroservices.find(ms => ms.name === 'KeyInterview')?.metadata;
  },
  availableKeyGames(state) {
    return state.availableMicroservices.find(ms => ms.name === 'KeyGames')?.metadata;
  },
  availableKeyScorings(state) {
    return state.availableMicroservices.find(ms => ms.name === 'KeyScoring')?.metadata;
  },
  availableKeyForms(state) {
    return state.availableMicroservices.find(ms => ms.name === 'KeyForms')?.metadata;
  },
  selectionProcess(state) {
    return state.selectionProcess;
  },
  kjOffer(state) {
    return state.kjOffer;
  },
  laborumOffer(state) {
    return state.laborumOffer;
  },
  trabajandoOffer(state) {
    return state.trabajandoOffer;
  },
  trabajandoScraping(state) {
    return state.trabajandoScraping;
  },
  getChiletrabajosOffer(state) {
    return state.chiletrabajosOffer;
  },
  processSelected(state) {
    return state.processSelected;
  },
  intProcessSelectedLaborum(state) {
    return state.intProcessSelectedLaborum;
  },
  intProcessSelectedTrabajando(state) {
    return state.intProcessSelectedTrabajando;
  },
  intProcessSelectedChiletrabajos(state) {
    return state.intProcessSelectedChiletrabajos;
  },
  getCompletedAction(state) {
    return state.completedAction;
  },
  getErrorAction(state) {
    return state.error;
  },
  getCompanyCountryId(state) {
    return state.companyCountryId;
  },
  getTrabajandoLoginType(state) {
    return state.trabajandoLoginType;
  },
  getSetCredentialsModal(state) {
    return state.setCredentialsModal;
  },
  getBatchAction(state) {
    return state.batchAction;
  },
  directKjUrl(state) {
    return state.directKjUrl
  },
};

export default {
  state: { ...initialState },
  mutations,
  actions,
  getters,
};

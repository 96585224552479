import { render, staticRenderFns } from "./kg-settings.vue?vue&type=template&id=552c4dfb&scoped=true"
import script from "./kg-settings.vue?vue&type=script&lang=js"
export * from "./kg-settings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "552c4dfb",
  null
  
)

export default component.exports
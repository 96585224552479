/* eslint no-undef: 0 */
import Vue from 'vue/dist/vue.esm'
import { localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'
import { camelizeKeys } from 'humps'
import VModal from 'vue-js-modal'
import ActionCableVue from 'actioncable-vue'
import VCalendar from 'v-calendar'
import { rutInputDirective } from 'vue-dni'
import VTooltip from 'v-tooltip'
import VueCurrencyInput from 'vue-currency-input'
import Clipboard from 'v-clipboard'
import 'bootstrap/dist/js/bootstrap.min.js'
import VueHtmlToPaper from 'vue-html-to-paper'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-bootstrap.css'

// Tailwind compiled
import 'dist/output.css'

// import Talanify Next
import 'talanify-next/dist/v2.7/index.css'
import 'assets/styles/tailwind.css'
import * as Talanify from 'talanify-next/dist/v2.7'

// import Vuetify
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import VueTour from 'vue-tour'
import '../assets/styles/app.scss'
import store from '../store'

// import Global Components
import Loader from '../components/ui/loader.vue'
import { TLoader } from 'talanify-next/dist/v2.7'
import EvaluationsConfig from '../components/company/evaluations-config/container.vue'
import MailingContainer from '../components/company/mailing/mailing-container.vue'
import CompanyStock from '../components/company/company-stock.vue'

// helpers
import interceptorsSetup from '../helpers/interceptors'
import '../helpers/validation-rules'

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes',
  ],
  styles: [
    'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
  ],
  timeout: 1000,
  autoClose: true,
  windowTitle: window.document.title,
}

Vue.use(VueHtmlToPaper, options)

// Register globally each Talanify-Next component
Object.values(Talanify).forEach(component => {
  if (component.hasOwnProperty('__name')) Vue.component(component.__name, component)
})

Vue.component('App', () => ({ component: import('App') }))

Vue.component('router-link', () => ({ component: import('components/ui/RouterLink.vue') }))

Vue.component('index-ks', () => ({
  component: import('../components/keyscoring/index/index'),
  loading: Loader,
}))
Vue.component('index-ki', () => ({
  component: import('../components/keyinterview/index/index'),
  loading: Loader,
}))
Vue.component('index-kj', () => ({
  component: import('../components/keyjobs/index/index'),
  loading: Loader,
}))
Vue.component('index-kg', () => ({
  component: import('../components/keygaming/index/index'),
  loading: Loader,
}))
Vue.component('index-kv', () => ({
  component: import('../components/keyvitae/index'),
  loading: Loader,
}))
Vue.component('index-km', () => ({
  component: import('../components/keymailer/index/index'),
  loading: Loader,
}))
Vue.component('process-board', () => ({
  component: import('../components/process-board'),
  loading: Loader,
}))
Vue.component('flash-v2', () => import('../components/flash-v2/flash-v2.vue'))
Vue.component('process-form', () => ({
  component: import('../components/process-form/process-form'),
  loading: Loader,
}))
Vue.component('new-applicant-button', () => import('../components/new-applicant-button'))
Vue.component('discarded-applicants', () => import('../components/discarded-applicants'))
Vue.component('k-table', () => import('../components/k-table'))
Vue.component('k-paginator', () => import('../components/k-paginator'))
Vue.component('users-index', () => ({
  component: import('../components/users-index'),
  loading: Loader,
}))
Vue.component('Dashboard', () => ({
  component: import('modules/dashboard/views/DashboardView'),
  loading: Loader,
}))
Vue.component('close-process', () => import('../components/close-process'))
Vue.component('processes-list-view', () => import('modules/processes/views/ProcessesListView'))
Vue.component('process-header', () => import('../components/process-header'))
Vue.component('settings-ks', () => import('../components/keyscoring/settings_keyscoring/settings-ks'))
Vue.component('custom-email-ks', () => import('../components/keyscoring/settings_keyscoring/custom-email-ks'))
Vue.component('new-ks', () => import('../components/keyscoring/new_keyscoring/NewKs'))
Vue.component('new-competence', () => import('../components/keyscoring/new_keyscoring/new-competence'))
Vue.component('homologation-ks', () => import('../components/keyscoring/homologation_ks/homologationKs'))
Vue.component('show-ks', () => import('../components/keyscoring/show/show'))
Vue.component('test-homologation', () => import('../components/keyscoring/pages/test-homologation'))
Vue.component('user-form', () => import('../components/user-form'))
Vue.component('new-ki', () => import('../components/keyinterview/new/new-ki'))
Vue.component('edit-kj', () => import('../components/keyjobs/edit/edit-kj'))
Vue.component('kv-settings', () => ({
  component: import('../components/keyvitae/components/kv-settings'),
  loading: Loader,
}))
Vue.component('index-kq', () => import('../components/keyquestion/index/index'))
Vue.component('company-profile', () => ({
  component: import('../components/company-profile'),
  loading: Loader,
}))
Vue.component('applicant-details-modal', () => import('../components/applicant-details/applicant-details-modal'))
Vue.component('ApplicantsView', () => ({
  component: import('modules/applicants/views/ApplicantsView'),
  loading: Loader,
}))
Vue.component('billing-container', () => import('../components/company/billing-container'))
Vue.component('billing-completed', () => import('../components/company/billing-completed'))
Vue.component('session-container', () => import('../components/login-process/session-container'))
Vue.component('login-container', () => import('../components/login-process/login-container'))
Vue.component('registration', () => import('../components/login-process/registration.vue'))
Vue.component('forgot-password', () => import('../components/login-process/forgot-password.vue'))
Vue.component('notification-icon', () => import('../components/notification-icon'))
Vue.component('design-container', () => import('../components/design/DesignView.vue'))
Vue.component('offer-diffusion', () => ({
  component: import('../components/company/diffusion/offer-diffusion.vue'),
  loading: Loader,
}))
Vue.component('menu-icon', () => import('../components/menu-icon.vue'))
Vue.component('edit-icon', () => import('../components/edit-icon.vue'))
Vue.component('evaluations-config', EvaluationsConfig)
Vue.component('mailing-container', MailingContainer)
Vue.component('company-stock', CompanyStock)
Vue.component('index-kf', () => ({
  component: import('../components/keyform/index/index'),
  loading: Loader,
}))
Vue.component('new-kf', () => ({
  component: import('../components/keyform/new/new'),
  loading: Loader,
}))
Vue.component('sections-kf', () => ({
  component: import('../components/keyform/new/sections'),
  loading: Loader,
}))

require('vue-tour/dist/vue-tour.css')

Vue.use(VModal, { dynamic: true, injectModalsContainer: true })
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectionUrl:
    process.env.RAILS_ENV === 'production' ?
      process.env.APPLICATION_HOST.replace('https', 'wss') :
      'ws://localhost:3000/api/cable',
  connectImmediately: true,
})
Vue.use(VueToast, { position: 'top', duration: 4000, queue: true })
Vue.use(VCalendar, {
  componentPrefix: 'vc',
  color: 'purple',
})
Vue.use(VueTour)
Vue.use(VTooltip)
Vue.use(VueCurrencyInput)
Vue.use(Clipboard)
Vue.use(Vuetify)
Vue.use(PiniaVuePlugin)
Vue.filter('camelizeKeys', camelizeKeys)
Vue.directive('rut', rutInputDirective)

localize('es', es)

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    themes: {
      light: {
        'haunter-purple': '#6344dd',
        'blue-superman': '#2222BF',
        'blue-lighter': '#E9F2F8',
        'blue-light': '#87C6F1',
        'blue-default': '#59A7DE',
        'blue-dark': '#2A7AB2',
        'green-lighter': '#F3FFC8',
        'green-light': '#D0F846',
        'green-default': '#B3DA2C',
        'green-dark': '#8CB00E',
        'yellow-lighter': '#FFEFB7',
        'yellow-light': '#FFE486',
        'yellow-default': '#FFD543',
        'yellow-dark': '#FFC700',
        'purple-lighter': '#F4B1FF',
        'purple-light': '#ED7CFF',
        'purple-default': '#E44BFD',
        'purple-dark': '#DB01FF',
        'red-lighter': '#FF223A',
        'red-light': '#E90019',
        'red-default': '#C50015',
        'red-dark': '#C6291C',
        'gray-lighter': '#F5F6F8',
        'gray-one': '#506083',
        'gray-light': '#CCD5DA',
        'gray-default': '#A5B6BE',
        'gray-dark': '#8B959A',
        'darkgray-lighter': '#AAC8D8',
        'darkgray-light': '#7098AC',
        'darkgray-default': '#486A7B',
        'darkgray-dark': '#263D49',
        'primary': '#6344dd',
        'secondary': '#8CB00E',
        'danger': '#C50015',
        'warning': '#F8B200',
      },
    },
  },
})

const pinia = createPinia()

interceptorsSetup()
if ((process.env.NODE_ENV || process.env.RAILS_ENV) === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  })
}
document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('app') !== null) {
    return new Vue({
      el: '#app',
      store,
      vuetify,
      pinia,
      data() {
        return {
          openSidebar: true,
          overlay: false,
          window: {
            width: 0,
          },
        }
      },
      created() {
        // eslint-disable-next-line
        this.openSidebar = window.innerWidth > 1000
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
      },
      methods: {
        handleResize() {
          this.window.width = window.innerWidth
          // eslint-disable-next-line no-magic-numbers
          if (this.window.width <= 1000) {
            this.openSidebar = false
          } else {
            this.openSidebar = true
          }
        },
      },
    })
  }

  return null
})

<template>
  <div>
    <disclaimer
      v-if="isDisclaimerShowing"
      :open="isDisclaimerShowing"
      @close-modal="closeDisclaimer"
    />
    <div
      class="pb-0 pr-0 pl-0 pb-md-12 pr-md-12 pl-md-12"
      v-if="settingsVisible"
    >
      <km-settings
        v-model="settingsVisible"
        :signature="signatures"
        @change-signature="changeSignature"
      />
    </div>
    <div
      class="pb-0 pr-0 pl-0 pb-md-12 pr-md-12 pl-md-12"
      v-if="!settingsVisible"
    >
      <div class="km-header">
        <v-row>
          <v-col
            cols="12"
            md="2"
            lg="1"
            class="d-flex align-center justify-center"
          >
            <ms-icons
              :ms-id="5"
              height="90"
              width="90"
            />
          </v-col>
          <v-col
            cols="12"
            md="10"
            lg="11"
          >
            <page-title>
              Plantillas Generales
              <button
                class="btn btn-outline-primary btn-sm ms-3"
                @click="showDisclaimer"
              >
                <v-icon
                  small
                  color="var(--haunter-purple)"
                  v-tooltip="{
                    content:'¿Cómo me ayuda Mailing?'
                  }"
                >
                  {{ mdiHelpCircleOutline }}
                </v-icon>
                Más información
              </button>
            </page-title>
            <page-subtitle>
              Crea y diseña tus propias plantillas de correo electrónico
            </page-subtitle>
          </v-col>
        </v-row>
      </div>

      <warn-box
        v-if="boxToShow"
        :is-green="true"
        :text="boxMessage"
      />

      <div
        class="flash-message km-message"
        :class="message ? `flash-message--${message.type}` : ''"
        v-if="message"
        v-html="message.content"
      />
      <v-row class="mb-2">
        <v-col />
        <v-col
          cols="12"
          xl="6"
        >
          <v-row>
            <div class="d-flex align-center justify-end col gap-2">
              <button
                class="btn btn-outline-primary"
                @click="openSettings"
              >
                <icon-s-v-g :path="mdiPen" />
              </button>
              <btn
                :loading="false"
                @click="newTemplate"
                color="primary"
              >
                Crear plantilla
              </btn>
            </div>
            <v-col
              cols="12"
              md="4"
              xl="6"
            >
              <div class="km-filter__input-icons">
                <v-text-field
                  placeholder="Buscar por nombre y/o código"
                  hide-details="auto"
                  v-model="searchText"
                  outlined
                  dense
                  height="35"
                  solo
                >
                  <v-icon slot="append">
                    {{ mdiMagnify }}
                  </v-icon>
                </v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div>
        <k-table
          :columns="columns"
          :rows="rows"
          id="km-table"
          table-style="ms_multiplica"
          :sortable="false"
          :loading="loading"
          :empty-text="KeyMailers.length === 0 ?
            'Aún no existen templates de Mailing' : 'No se encontraron templates de Mailing'"
          action-column=""
        >
          <template #toggle="scopedProps">
            <template-toggle
              :template-data="scopedProps.row[0].content"
            />
          </template>
          <template #actions="scopedProps">
            <img
              v-if="scopedProps.row[0].content.name !== uneditableTemplate"
              src="../../../../assets/images/km/pencil.svg"
              class="km-edit"
              @click="editTemplate(scopedProps.row[0].content)"
            >
          </template>
        </k-table>
        <k-paginator
          v-if="KeyMailers.length > pageSize"
          :loading="loading"
          :page-size="pageSize"
          :total-items="totalItems"
          v-model="kmPage"
          name="Plantillas"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { mdiMagnify, mdiHelpCircleOutline, mdiPen } from '@mdi/js';
import kmSettings from './components/km-settings';
import { fetchEmailTemplates, fetchSignatures } from '../../../api/keymailer/index';
import Disclaimer from './components/disclaimer.vue';
import formatDate from '../../../filters/format-date';
import HandleDisclaimer from './../../../mixins/handle-disclaimer';
import EmailTemplateModal from '../email-template-modal';
import TemplateToggle from '../index/components/template-toggle';
import WarnBox from '../../keyscoring/global/WarnBox';
import PageTitle from '../../ui/page-title.vue';
import PageSubtitle from '../../ui/page-subtitle.vue';
import btn from '../../ui/btn.vue';
import MsIcons from '../../ms-icons.vue';
import IconSVG from '../../ui/IconSVG.vue';

const PAGE_SIZE = 5;
const DATE_FORMAT = 'D-MM-YYYY';
const MAX_NAME_LENGTH = 30;

export default {
  mixins: [HandleDisclaimer],
  components: {
    Disclaimer,
    kmSettings,
    TemplateToggle,
    WarnBox,
    PageTitle,
    MsIcons,
    btn,
    PageSubtitle,
    IconSVG
},
  data() {
    return {
      mdiMagnify,
      mdiHelpCircleOutline,
      mdiPen,
      KeyMailers: [],
      uneditableTemplate: 'En Blanco',
      columns: {
        'name': 'Nombre',
        'createdAt': 'Fecha creación',
      },
      kmPage: 1,
      showActiveKm: true,
      pageSize: PAGE_SIZE,
      loading: true,
      searchText: '',
      settingsVisible: false,
      message: null,
      signatures: null,
      boxToShow: null,
    };
  },
  beforeMount() {
    this.setKeyMailers();
    this.getSignatures();
  },
  mounted() {
    const currentUrl = window.location.href;
    if (currentUrl.includes('?')) {
      const urlData = window.location.search.slice(1).split('=');
      if (urlData[0] === 'status') {
        this.boxToShow = urlData[1];
      }
    }
  },
  computed: {
    boxMessage() {
      if (this.boxToShow === 'created') {
        return 'Plantilla creada exitosamente.';
      }

      return 'Plantilla modificada exitosamente.';
    },
    templateList() {
      return this.KeyMailers
        .filter(km => km.name.toLowerCase().includes(this.searchText.toLowerCase()));
    },
    rows() {
      return this.templateList
        .slice((this.actualPage - 1) * PAGE_SIZE, this.kmPage * PAGE_SIZE)
        .map(km => [
          { id: 'raw', content: km },
          { id: 'name', content: this.getName(km.name) },
          { id: 'createdAt', content: formatDate(km.createdAt, DATE_FORMAT) },
        ]);
    },
    totalItems() {
      return this.templateList.length;
    },
    actualPage() {
      if (this.totalItems != 0 && this.totalItems <= (this.kmPage * PAGE_SIZE)) {
        this.kmPage = Math.ceil(this.totalItems / PAGE_SIZE);

        return this.kmPage;
      }

      return this.kmPage;
    },
  },
  methods: {
    getSignatures() {
      fetchSignatures().then((res) => {
        this.signatures = res.data.signature;
      });
    },
    changeSignature(newSignature) {
      this.signatures = newSignature;
    },
    editTemplate(row) {
      const existingEmailTemplates = this.KeyMailers.slice().reverse();
      this.$modal.show(
        EmailTemplateModal,
        {
          existingEmailTemplates,
          id: row.id,
          subjectContent: row.subject,
          bodyContent: row.body,
          originalTemplateName: row.name,
          mode: 'update',
          title: 'Editar una plantilla',
          buttonText: 'Modificar plantilla',
          warnBoxMessage: 'Hubo un error al modificar la plantilla. Intente de nuevo.',
        },
        {
          height: 'auto',
          width: '900px',
          adaptive: true,
          scrollable: true,
        },
        null,
        {
          name: 'new-email-template-modal',
        },
      );
    },
    newTemplate() {
      const existingEmailTemplates = this.KeyMailers.slice().reverse();
      this.$modal.show(
        EmailTemplateModal,
        {
          existingEmailTemplates,
          mode: 'create',
          title: 'Crear nueva plantilla',
          buttonText: 'Crear plantilla',
          warnBoxMessage: 'Hubo un error en la creación de la plantilla. Intente de nuevo.',
        },
        {
          height: 'auto',
          width: '900px',
          adaptive: true,
          scrollable: true,
        },
        null,
        {
          name: 'new-email-template-modal',
        },
      );
    },
    setKeyMailers() {
      fetchEmailTemplates().then((res) => {
        this.KeyMailers = res.data.emailTemplates.sort((a, b) => {
          // Deja primero a los actualizados más recientemente
          if (a.updatedAt < b.updatedAt) {
            return 1;
          }

          return -1;
        });
        this.loading = false;
      });
    },
    openSettings() {
      this.settingsVisible = true;
    },
    creationSuccess(name) {
      this.message = {
        type: 'success',
        content: `La plantilla <strong>"${name}"</strong> fue creada exitosamente`,
      };
    },
    setErrorMessage({ type, id }) {
      const keyMailer = this.KeyMailers.find(km => km.id === id);
      const actionText = type === 'activation' ? 'activación' : 'desactivación';
      this.message = {
        type: 'alert',
        content: `Error en la ${actionText} de la plantilla <strong>"${keyMailer.name}"</strong>.
          Intenta nuevamente.`,
      };
    },
    getName(name) {
      if (name.length < MAX_NAME_LENGTH) return name;

      return `${name.slice(0, MAX_NAME_LENGTH)}...`;
    },
  },
  watch: {
    showActiveKm() {
      this.kmPage = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.km-header {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 0;

  &__icon,
  &__icon:hover {
    padding: 6.5px 12.5px;
    height: 35px;
    width: 47px;
    border-radius: 5px;
    border: 1px solid var(--haunter-purple);
    background-color: rgba(43, 121, 178, .14);
  }

  &__icon:hover {
    background-color: rgba(43, 121, 178, .3);
  }

  &__title {
    color: #263d49;
    font-size: 20px;
  }

  &__settings-icon {
    margin-right: 6px;
  }
}

.km-message {
  margin: 0;
}

.km-filter {
  margin: 30px 0;

  &__row {
    display: flex;
    float: right;
    margin-bottom: 15px;
  }

  &__input {
    width: 322px;
    height: 35px;
    border-radius: 5px;
    padding: 0 0 0 44px;
  }

  &__search-icon {
    position: absolute;
    width: 22px;
    height: 22px;
    margin-top: 6.5px;
    margin-left: 12.5px;
  }
}

.km-toggle {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;

  &__item {
    color: #007ab7;
    font-size: 16px;
    cursor: pointer;
    display: inline-block;

    &--active {
      color: #4a4a4a;
      cursor: unset;
    }

    &:not(:last-child) {
      margin-right: 42px;
    }
  }
}

.km-edit {
  padding: 6px;

  &:hover {
    cursor: pointer;
    background-color: rgba(168, 167, 164, .23);
    border-radius: 50%;
  }
}
</style>

/* eslint-disable max-statements */
import * as selectionProcessApi from '../api/selection-process';

const initialState = {
  processesOptionsToSelect: {
    processes: [],
    paginationData: {
      processesLength: 0,
      totalProcesses: 0,
      currentPage: 1,
      limit: 10,
    },
    tabsData: {
      activeCount: 0,
      closedCount: 0,
      expiredCount: 0,
    }
  },
  payload: {},
  loadingOptionsToSelect: false,
  error: null,
};

export const mutations = {
  setLoadingOptions(state, payload) {
    state.loadingOptionsToSelect = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  setProcessesOptionsToSelect(state, payload) {
    const { processes, processesLength, totalProcesses, currentPage, limit, tabsData } = payload

    state.processesOptionsToSelect.processes = processes;
    state.processesOptionsToSelect.paginationData = {
      processesLength, totalProcesses, currentPage, limit, tabsData
    }
    state.processesOptionsToSelect.tabsData = tabsData
  },
  setPayload(state, payload) {
    state.payload = payload;
  },
};

export const actions = {
  // Nuevo Proceso opciones
  getProcessesOptions({ commit, dispatch }, currentFilters) {
    commit('setLoadingOptions', true);

    return selectionProcessApi.getProcesses(currentFilters)
      .then((res) => {
        dispatch('setProcessesOptions', res);
      })
      .finally(() => commit('setLoadingOptions', false))
      .catch((err) => { commit('setError', err); })
  },
  setProcessesOptions({ commit }, payload) {
    const { data = [], total, currentPage, limit, count } = payload;
    commit('setPayload', payload);
    commit('setProcessesOptionsToSelect', {
      processes: data,
      processesLength: data.length,
      totalProcesses: total,
      currentPage: currentPage,
      limit: limit,
      tabsData: {
        activeCount: count.active,
        closedCount: count.closed,
        expiredCount: count.expired,
      }
    });
  },
};

export const getters = {
  processesOptionsData(state) {
    const { processesLength, totalProcesses, currentPage, limit } = state.processesOptionsToSelect.paginationData
    const { activeCount, closedCount, expiredCount } = state.processesOptionsToSelect.tabsData
    return {
      processes: state.processesOptionsToSelect.processes,
      processesLength: processesLength,
      totalProcesses: totalProcesses,
      currentPage: currentPage,
      limit: limit,
      activeProcesses: activeCount,
      closedProcesses: closedCount,
      expiredProcesses: expiredCount,
      payload: state.payload,
    };
  },
};

export default {
  state: { ...initialState },
  mutations,
  actions,
  getters,
};

<template>
  <div class="">
    <header class="modal__header">
      <h5 class="modal__title">
        <img
          class="modal__title-icon"
          src="../../../assets/images/km/new-template.svg"
        >
        {{ title }}
      </h5>
      <img
        class="modal__close-icon"
        src="../../../assets/images/km/close.svg"
        @click="closeModal"
      >
    </header>

    <p class="modal__explanation">
      Genera plantillas de mensajes que podrás enviar luego por email a postulantes de distintos procesos
    </p>

    <div class="modal__box-wrapper">
      <warn-box
        v-if="hasFailed"
        :is-red="true"
        :text="warnBoxMessage"
      />
    </div>

    <div class="email-template-modal__body">
      <!--
      MAIN BODY
      -->

      <div class="email-template-modal__body-main vstack gap-3">
        <div class="email-template-modal__body-template">
          <h3 class="email-template-modal__middle__title">
            Plantilla base
          </h3>
          <select
            class="input-text"
            placeholder="Selecciona"
            v-model="selectedEmailTemplate"
          >
            <option value="">Selecciona...</option>
            <option v-for="template in existingEmailTemplates" :value="template" :key="template">{{ template.name }}</option>
          </select>
        </div>

        <div class="email-template-modal__body-title">
          <h3 class="email-template-modal__middle__title">
            Nombre de plantilla
          </h3>
          <input
            type="text"
            v-model="templateName"
            class="email-template-modal__email-input email-template-modal__email-input-name"
            :class="{ 'email-template-modal__template-name-input--error': templateNameExists }"
          >
          <p
            v-if="templateNameExists"
            class="email-template-modal__template-name-tip email-template-modal__template-name-tip--error"
          >
            El nombre de la plantilla ya está en uso
          </p>
        </div>

        <div class="email-template-modal__body-subject email-template-modal__email-body">
          <h3 class="email-template-modal__middle__title">
            Asunto
          </h3>
          <editor-content
            class="email-template-modal__email-input email-template-modal__email-input-subject"
            :editor="subjectEditor"
          />
        </div>

        <div
          class="email-template-modal__body-message email-template-modal__email-body"
          @click="focusBodyEditor"
        >
          <h3 class="email-template-modal__middle__title">
            Mensaje
          </h3>
          <editor-content
            class="email-template-modal__email-input email-template-modal__email-input-body"
            :editor="bodyEditor"
          />
        </div>
      </div>

      <!--
      SIDEBAR
      -->

      <div class="email-template-modal__body-sidebar">
        <div class="email-template-modal__middle__tag-container">
          <h3 class="email-template-modal__middle__title email-template-modal__middle__tags-title">
            Etiquetas
          </h3>
          <img
            src="../../../assets/images/km/tooltip.svg"
            v-tooltip="tagsTooltipOptions"
          >
        </div>
        <div class="email-template-modal__email-tags">
          <editor-menu-bar
            class="email-template-modal__email-tags-options"
            :editor="showTagMenuOf === 'subject' ? subjectEditor : bodyEditor"
            v-slot="{ commands }"
          >
            <div>
              <button
                v-for="(variable, index) in tags"
                :key="index"
                class="email-template-modal__email-tags-button"
                @click="commands.mention({ id: index, label: variable })"
              >
                {{ formatVariable(variable) }}
              </button>
            </div>
          </editor-menu-bar>
        </div>
      </div>
    </div>

    <button
      class="btn btn-primary email-template-modal__send-button"
      @click="send"
      :disabled="loading || invalidFields"
    >
      {{ buttonText }}
    </button>
  </div>
</template>
<script>

import vSelect from 'vue-select';
import { Mention } from 'tiptap-extensions';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import { createKeyMailerTemplate, updateKeyMailerTemplate } from '../../api/keymailer/index';
import WarnBox from '../keyscoring/global/WarnBox';

export default {
  components: {
    EditorContent,
    EditorMenuBar,
    vSelect,
    WarnBox,
  },
  props: {
    existingEmailTemplates: {
      type: Array,
      required: true,
    },
    subjectContent: {
      type: String,
      required: false,
      default: '',
    },
    bodyContent: {
      type: String,
      required: false,
      default: '',
    },
    originalTemplateName: {
      type: String,
      required: false,
      default: '',
    },
    mode: {
      type: String,
      required: true,
      validator: (prop) => ['create', 'update'].includes(prop),
    },
    title: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: false,
      default: null,
    },
    warnBoxMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      tagsTooltipOptions: {
        content: `Inserta estas etiquetas para incorporar datos variables en tu mensaje o asunto. 
                  Estas serán reemplazadas por la información correspondiente de manera automática.`,
        placement: 'top',
      },
      selectedEmailTemplate: "",
      subjectEditor: null,
      bodyEditor: null,
      showTagMenuOf: 'body',
      tags: ['nombre_postulante', 'nombre_etapa', 'nombre_proceso', 'nombre_organizacion'],
      invalidFields: true,
      loading: false,
      templateName: '',
      templateNameExists: false,
      defaultTemplateName: 'En Blanco',
      hasFailed: false,
    };
  },
  mounted() {
    this.subjectEditor = new Editor({
      content: this.subjectContent,
      editable: true,
      editorProps: { handlePaste: () => true, handleKeyDown: this.handleEditorKeyDown },
      onUpdate: () => this.handleValidateFields(),
      onFocus: () => { this.showTagMenuOf = 'subject'; },
      extensions: [
        new Mention({
          matcher: { char: '' },
          mentionClass: 'email-template-modal__email-template-variables',
        }),
      ],
    });
    this.bodyEditor = new Editor({
      content: this.bodyContent,
      editable: true,
      onUpdate: () => this.handleValidateFields(),
      onFocus: () => { this.showTagMenuOf = 'body'; },
      extensions: [
        new Mention({
          matcher: { char: '' },
          mentionClass: 'email-template-modal__email-template-variables',
        }),
      ],
    });
    this.selectDefaultTemplate();
    if (this.mode === 'update') {
      this.templateName = this.originalTemplateName;
    }
  },
  methods: {
    closeModal() {
      this.$modal.hideAll();
    },
    focusBodyEditor() {
      this.bodyEditor.focus();
    },
    selectDefaultTemplate() {
      const baseTemplateName = this.originalTemplateName ? this.originalTemplateName : this.defaultTemplateName;
      for (const et of this.existingEmailTemplates) {
        if (et.name === baseTemplateName) {
          this.selectedEmailTemplate = et;
          break;
        }
      }
    },
    handleEditorKeyDown(view, event) {
      if (event.key === '{' || event.key === '}') {
        return true;
      }

      return false;
    },
    handleValidateFields() {
      this.invalidFields = this.subjectEditor.getHTML() === '<p></p>' ||
      this.bodyEditor.getHTML() === '<p></p>' || this.templateName === '';
    },
    formatVariable(variable) {
      let formattedVariable = variable.replace(/_/gi, ' ');
      formattedVariable = `+ ${formattedVariable[0].toUpperCase()}${formattedVariable.slice(1)}`;

      return formattedVariable;
    },
    messageToHTML(msg) {
      /*
       * Pasa texto plano de los templates de KeyMailer a un formato en HTML
       * para desplegar en el editor de tiptap
       */
      let formattedMsg = msg;

      const variablesRegex = /{{[^{}]+}}/g;
      const foundVariables = msg.match(variablesRegex)?.map(v => v.replace(/[{}]/gi, ''));

      // Reemplazar texto rodeado en {{}} por HTML con estilo de etiqueta
      if (foundVariables) {
        for (const v of foundVariables) {
          const idx = this.selectedEmailTemplate.variables.indexOf(v);
          formattedMsg = formattedMsg.replace(`{{${v}}}`, `<span data-mention-id="${idx}">${v}</span>`);
        }
      }

      // Reemplazar saltos de líneas por tags de párrafo
      formattedMsg = `<p>${formattedMsg.replace(/\n/g, '</p><p>')}</p>`;

      return formattedMsg;
    },
    jsonToMessage(jsonMsg) {
      /*
       * Pasa la versión JSON del texto en el editor de tiptap a un texto plano
       * para que KeyMailer lo entienda
       */
      let unformattedMsg = '';
      for (const paragraph of jsonMsg.content) {
        // Agregar salto de línea por cada párrafo
        unformattedMsg += '\n';
        if (!paragraph.content) {
          continue;
        }
        // Rodear variables con {{}}, el resto se copia sin modificar
        for (const element of paragraph.content) {
          if (element.type === 'text') {
            unformattedMsg += element.text;
          } else if (element.type === 'mention') {
            unformattedMsg += `{{${element.attrs.label}}}`;
          }
        }
      }

      return unformattedMsg.slice(1);
    },
    // eslint-disable-next-line max-statements
    send() {
      this.loading = true;
      this.hasFailed = false;

      this.checkUniqueName();

      if (this.templateNameExists) {
        this.loading = false;

        return;
      }

      const emailBody = this.jsonToMessage(this.bodyEditor.getJSON());
      const emailSubject = this.jsonToMessage(this.subjectEditor.getJSON());

      if (this.mode === 'create') {
        createKeyMailerTemplate(this.templateName, emailSubject, emailBody).then((res) => {
          if (res) {
            window.location.href = '/company/mailing?status=created';
          } else {
            this.loading = false;
            this.hasFailed = true;
          }
        });
      } else if (this.mode === 'update') {
        updateKeyMailerTemplate(this.id, this.templateName, emailSubject, emailBody).then((res) => {
          if (res) {
            window.location.href = '/company/mailing?status=updated';
          } else {
            this.loading = false;
            this.hasFailed = true;
          }
        });
      }
    },
    checkUniqueName() {
      for (const et of this.existingEmailTemplates) {
        if (et.name === this.templateName && et.name !== this.originalTemplateName) {
          this.templateNameExists = true;
          break;
        }
      }
    },
  },
  watch: {
    selectedEmailTemplate() {
      if (this.selectedEmailTemplate) {
        this.bodyEditor.setContent(this.messageToHTML(this.selectedEmailTemplate.body));
        this.subjectEditor.setContent(this.messageToHTML(this.selectedEmailTemplate.subject));
        this.handleValidateFields();
      }
    },
    templateName() {
      this.templateNameExists = false;
      this.handleValidateFields();
    },
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.email-template-modal {
  &__body {
    margin: 1em 2em;
    display: grid;
    column-gap: 3em;
    grid-template-columns: 58% auto;
    grid-template-rows: 1fr 1fr 1fr 1fr 3fr;
  }

  &__body-main {
    grid-column: 1 / 2;
    grid-row: 1 / 6;
  }

  &__body-sidebar {
    grid-column: 2 / 3;
    grid-row: 3 / 6;
    align-self: stretch;
    margin-top: 15px;
  }

  &__middle {
    margin-bottom: 30px;

    &__title {
      margin-bottom: .3rem;
      font-weight: 500;
      font-size: 16px;
    }

    &__tag-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &__tags-title {
      position: relative;
      top: 8px;
    }
  }

  &__email-tags {
    display: flex;
    align-items: flex-start;
    margin: .7em auto .3em;
    font-weight: 500;
    font-size: .875rem;
    background-color: #f2f2f2;
    height: 78%;
  }

  &__email-tags-options {
    display: flex;
    flex-wrap: wrap;
    margin-top: -.2em;
    padding: .8em .5em;
  }

  &__email-tags-button {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    height: fit-content;
    border-radius: 1rem;
    color: rgba(43, 121, 178, 1);
    padding: .2em .6em;
    margin: .7em .4em;
    background-color: rgba(43, 121, 178, .17);
    border: 0;
    cursor: pointer;

    &:focus,
    &:hover {
      background-color: #ebebeb;
      outline: none;
    }
  }

  &__email-input {
    border: 0;
    /* border-radius: .5rem; */
    background-color: #f2f2f2;
    width: 100%;
    overflow: auto;
    font-size: 13px;
    padding: 0 1em;

    &:focus,
    &:hover {
      outline: none;
    }
  }

  &__template-name-input {
    &--error {
      border: 0;
      background-color: rgba(190, 61, 61, .23);
    }
  }

  &__template-name-tip {
    font-size: .8em;
    margin-top: 4px;

    &--error {
      color: rgba(190, 61, 61, 1);
    }
  }

  &__email-input-name {
    padding: .4em 1em;
    height: 40px;
  }

  &__email-input-subject {
    max-height: 3rem;
  }

  &__email-input-body {
    height: 10rem;
  }

  &__send-button {
    float: right;
    margin: 0 2rem 2rem;
  }
}

.modal {
  &__explanation {
    margin: 1.5em 1.9em 1.8em;
  }

  &__box-wrapper {
    margin: 0 2em;
  }

  &__header {
    border-bottom: 2px solid #bdc6d8;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 1rem;
    margin-bottom: 0;
  }

  &__title-icon {
    margin-right: 4px;
  }

  &__close-icon {
    margin-right: 24px;
    position: relative;
  }
}

.tests-own {
  margin-bottom: 0;
}

.v-select {
  border: 1px solid #b4b4b4;
  border-radius: 2px;
}

.vs__dropdown-toggle {
  border: 0;
  border-radius: 0;
}

.email-template-modal__email-template-variables {
  background-color: #c7daf0;
  border: 0;
  border-radius: 1rem;
  padding: .1rem .625rem;
  font-size: 12px;
}

.ProseMirror-focused {
  outline: none;
}
</style>

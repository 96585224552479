/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

export function hasRestriction(params) {
  return axios.post('/api/v1/company/kc_plans/has_restriction', decamelizeKeys(params))
    .then(res => camelizeKeys(res));
}

export function processHasStock(params) {
  return axios.post('/api/v1/company/kc_plans/process_has_stock', decamelizeKeys(params))
    .then(res => camelizeKeys(res));
}

export function companyHasStock(params) {
  return axios.post('/api/v1/company/kc_plans/company_has_stock', decamelizeKeys(params))
    .then(res => camelizeKeys(res));
}

export function applicantsOnProcess(id) {
  return axios.get(`/api/v1/selection_processes/${id}/get_applicants_process`)
    .then(res => camelizeKeys(res));
}

export function getProcessApplicantsBench(selection_process_id) {
  return axios.get(`/api/v1/selection_processes/${selection_process_id}/get_applicant_benches`)
    .then(res => camelizeKeys(res));
}

export function moveFromBenchToProcess(selection_process_id, applicantsIds) {
  return axios.post(`/api/v1/selection_processes/${selection_process_id}/add_applicants_from_bench`,
    decamelizeKeys(applicantsIds))
    .then(res => camelizeKeys(res));
}

export function companyAllRestrictions(params) {
  return axios.post('/api/v1/company/kc_plans/company_all_restrictions', decamelizeKeys(params))
    .then(res => camelizeKeys(res));
}

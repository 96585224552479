<template>
  <div class="footer-container blue-dark--text text-center">
    <div class="cannot-modify-box footer-block">
      <div class="cannot-modify-box__background" />
    </div>
    <div class="text-subtitle1 pl-12 pr-9 mt-10 mx-auto">
      <btn
        :height="'auto'"
        :width="'auto'"
        :loading="false"
        class="rounded-md font-weight-bold pa-3"
        style="background-color: var(--haunter-purple);"
      >
        {{ buttonText[category] }}
      </btn>
    </div>
    <br>
    <div class="email-footer">
      <div>
        <p>
          No respondas a este correo, ha sido generado de manera automática.
          Talana no conoce detalles sobre el proceso en que participas.
          Para recibir más información, revisa nuestra sección de <a href="https://conoce.talana.com/postulantes">apoyo al postulante</a>
          o escribe a <a href="mailto:soporteseleccion@talana.com">soporteseleccion@talana.com</a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Btn from '../../../../../ui/btn.vue';

export default {
  components: {
    Btn,
  },
  data() {
    return {
      buttonText: {
        1: 'Rendir pruebas',
        2: 'Rendir pruebas',
        3: 'Ver mis solicitudes',
        4: 'Ver mis solicitudes',
      },
    };
  },
  props: {
    category: {
      required: true,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>

.footer-container {
  font-size: 18px;
  width: auto;
  margin: 0 auto;
  background-color: #f7f7f7;
  box-shadow: 2px 0px 0px 0px #dedede;
  position: relative;
}

.footer-container {
  background-color: #eaeef1;
  padding: 15px 0px 15px 0px;

  &__body {
    font-size: 14px;
  }

  &__footer {
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__email {
    text-decoration: none;
    color: #85b506;
  }
}

.email-footer div {
  width: 520px;
  height: auto;
  margin: 10px auto;
}

.email-footer p {
  letter-spacing: 0.02em;
  margin: 0;
  padding-top: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  color: #7C8DB0;
}
</style>

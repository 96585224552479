import dayjs from 'dayjs';
import 'dayjs/locale/es';

dayjs.locale('es');

export default function formatDate(date, format = 'DD/MM/YYYY HH:mm') {
  if (!date || !format) return null;

  return dayjs(date).format(format);
}

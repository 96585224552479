import { getIntConfigs, getUniqueCard } from '../api/offer-diffusion';

const initialState = {
  checkOfferModal: false,
  showMultipostingModal: false,
  intConfigs: [],
  intConfigData: [],
  showForm: false,
  multiposting: {
    title: null,
    vacancies: 1,
    description: '',
    country: 'Chile',
    province: 'Metropolitana Región',
    city: null,
    educationLevel: null,
    academicSituation: null,
    experience: null,
    experienceYears: '1 año',
    vigency: null,
    position: null,
    salary: null,
    email: null,
    confidential: false,
    confidentialName: 'Importante empresa',
    requirements: '',
    inclusive: false,
    cvMail: false,
    area: null,
    subarea: null,
    modality: null,
    type: null,
    hierarchy: null,
    benefits: '',
  },
  exists: false,
  intCompanies: {},
  hasKj: false,
};

export const mutations = {
  setCheckOfferModal(state) {
    state.checkOfferModal = !state.checkOfferModal;
  },
  setShowMultipostingModal(state) {
    state.showMultipostingModal = !state.showMultipostingModal;
  },
  setIntConfigs(state, payload) {
    state.intConfigs = payload;
  },
  setIntConfigsData(state, payload) {
    state.intConfigData = payload
  },
  setShowForm(state) {
    state.showForm = !state.showForm;
  },
  setMultiposting(state, payload) {
    state.multiposting[payload.key] = payload.value;
  },
  setFicha(state, payload) {
    state.multiposting = payload;
  },
  setExists(state, payload) {
    state.exists = payload;
  },
  setIntCompanies(state, payload) {
    state.intCompanies[payload.key] = payload.value;
  },
  setHasKj(state, payload) {
    state.hasKj = payload;
  },
};

export const actions = {
  async getIntConfigs({ commit }) {
    const res = await getIntConfigs();
    const int_configs = res.data.integrationCompanies.map(i => i.intConfigId);
    commit('setIntConfigs', int_configs);
    commit('setIntConfigsData', res.data.integrationCompanies.map(i => ({ id: i.intConfigId, status: i.state })))
  },

  async getUniqueCard({ commit }, payload) {
    const res = await getUniqueCard(payload);
    if (res) {
      commit('setFicha', res.data.rawData);
      commit('setExists', true);
    }
  },
};

export const getters = {
  getCheckOfferModal(state) {
    return state.checkOfferModal;
  },
  getShowMultipostingModal(state) {
    return state.showMultipostingModal;
  },
  getIntConfigs(state) {
    return state.intConfigs;
  },
  getShowForm(state) {
    return state.showForm;
  },
  multiposting(state) {
    return state.multiposting;
  },
  exists(state) {
    return state.exists;
  },
  getIntCompanies(state) {
    return state.intCompanies;
  },
  getIntCompaniesData(state) {
    return state.intConfigData
  },
  getHasKj(state) {
    return state.hasKj;
  },
};
export default {
  state: { ...initialState },
  mutations,
  actions,
  getters,
};

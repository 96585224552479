import axios from 'axios';
import { camelizeKeys } from 'humps';

function getKvExpirationDays() {
  return axios
    .get('/api/v1/kv/kv_settings')
    .then(res => camelizeKeys(res.data))
    .catch(err => {
      throw new Error(err);
    });
}

function updateKvExpirationDays(days) {
  return axios
    .patch('/api/v1/kv/kv_settings/1', { 'expirationDays': days })
    .then(res => camelizeKeys(res.data))
    .catch(err => {
      throw new Error(err);
    });
}

export default {
  getKvExpirationDays,
  updateKvExpirationDays,
};

<template>
  <div v-tooltip="tooltipText">
    <label class="email-template-status__toggle">
      <input
        type="checkbox"
        v-model="templateData.active"
        @change="changeState"
      >
      <span class="email-template-status__slider" />
    </label>
  </div>
</template>

<script>
/* import ConfirmationModal from './confirmation-modal'; */
import { activateKeyMailerTemplate, deactivateKeyMailerTemplate } from '../../../../api/keymailer/index';

export default {
  props: {
    templateData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    tooltipText() {
      if (this.templateData.active) {
        return 'Desactivar';
      }

      return 'Activar';
    },
  },
  methods: {
    changeState() {
      if (this.templateData.active) {
        activateKeyMailerTemplate(this.templateData.id).then((res) => {
          if (!res) {
            window.location.href = '/complements/keymailers';
          }
        });
      } else {
        deactivateKeyMailerTemplate(this.templateData.id).then((res) => {
          if (!res) {
            window.location.href = '/complements/keymailers';
          }
        });
      }
    },
  },
  watch: {
  },
};
</script>

<style lang="scss" scoped>

.email-template-status {
  &__toggle {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 13px;

    &-label {
      padding-right: 10px;
    }

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(189, 198, 216, .37);
    transition: .4s;
    border-radius: 34px;

    &::before {
      position: absolute;
      content: '';
      height: 13px;
      width: 13px;
      background-color: rgba(189, 198, 216, 1);
      transition: .4s;
      border-radius: 50%;
    }
  }

  &__toggle input:checked + &__slider {
    background-color: #9898FF;

    &::before {
      background-color: rgba(34, 34, 191, 1);
      transform: translateX(24px);
    }
  }
}
</style>

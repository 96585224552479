import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

export function sendEmail(data) {
  return axios.post('/api/v1/km/assign', decamelizeKeys(data))
    .then(res => camelizeKeys(res));
}

export function fetchEmailTemplates(params) {
  return axios.get('/api/v1/km/email_templates', decamelizeKeys(params))
    .then(res => camelizeKeys(res));
}

export function getSentEmails(params) {
  return axios.get('/api/v1/km/sent_emails', { params: decamelizeKeys(params) })
    .then(res => camelizeKeys(res));
}

export async function createKeyMailerTemplate(name, subject, body) {
  return axios
    .post('/api/v1/km/create_template', decamelizeKeys({ name, subject, body }))
    .then(res => camelizeKeys(res.data));
}

export async function updateKeyMailerTemplate(id, name, subject, body) {
  return axios
    .patch(`/api/v1/km/update_template/${id}`, decamelizeKeys({ name, subject, body }))
    .then(res => camelizeKeys(res.data));
}

export async function activateKeyMailerTemplate(id) {
  return axios
    .patch(`/api/v1/km/activate_template/${id}`)
    .then(res => camelizeKeys(res.data));
}

export async function deactivateKeyMailerTemplate(id) {
  return axios
    .patch(`/api/v1/km/deactivate_template/${id}`)
    .then(res => camelizeKeys(res.data));
}

export async function fetchSignatures() {
  return axios
    .get('/api/v1/km/get_signature')
    .then(res => camelizeKeys(res.data));
}

export async function createSignature(content) {
  return axios
    .post('/api/v1/km/create_signature', { signature: { content } })
    .then(res => camelizeKeys(res.data));
}

export async function updateSignature(id, content) {
  return axios
    .patch('/api/v1/km/update_signature', { signature: { id, content } })
    .then(res => camelizeKeys(res.data));
}

export async function activateSignature(id) {
  return axios
    .patch('/api/v1/km/activate_signature', { signature: { id } })
    .then(res => camelizeKeys(res.data));
}

export async function deactivateSignature(id) {
  return axios
    .patch('/api/v1/km/deactivate_signature', { signature: { id } })
    .then(res => camelizeKeys(res.data));
}

<template>
  <div>
    <h5>Stock disponible</h5>
    <p>Conoce las existencias de cada uno de tus productos, y compra más si lo requieres.</p>
    <div class="row mt-5 mx-auto" style="max-width: 1024px;">
      <div
        v-if="hasProcessNumberRestriction"
        class="col-12 col-md-6"
      >
        <company-stock-card
          :icon="mdiFilter"
          :title="'Procesos'"
          :stock="hasProcessNumberRestriction ? processNumber : -1"
        />
      </div>
      <div
        v-if="hasApplicantsRestriction"
        class="col-12 col-md-6"
      >
        <company-stock-card
          :icon="mdiAccountGroup"
          :stock="hasApplicantsRestriction ? applicantsNumber : -1"
          :title="'Postulantes'"
        />
      </div>
      <div
        class="col-12 col-md-6"
      >
        <company-stock-card
          :icon-id="6"
          :stock="getKgCompanyStock"
          :title="'Evaluaciones Gamificadas'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mdiChevronUp, mdiChevronDown, mdiFilter, mdiAccountGroup } from '@mdi/js';
import PageSubtitle from '../ui/page-subtitle';
import PageTitle from '../ui/page-title';
import CompanyStockCard from './company-stock-card';

export default {
  components: {
    PageTitle,
    PageSubtitle,
    CompanyStockCard,
  },
  data() {
    return {
      show: false,
      mdiChevronUp,
      mdiChevronDown,
      mdiFilter,
      mdiAccountGroup,
    };
  },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.getCompanyStock();
  },
  methods: {
    getCompanyStock() {
      this.$store.dispatch('getAllRestrictions', {
        companyId: this.companyId,
        origen: 'billing',
      });
    },
  },
  computed: {
    processNumber() {
      return this.$store.getters.getProcessNumber;
    },
    getKgCompanyStock() {
      return this.$store.getters.getCompanyStock;
    },
    applicantsNumber() {
      return this.$store.getters.getApplicantsCompanyNumber;
    },
    hasProcessNumberRestriction() {
      return this.$store.getters.getProcessNumberRestriction;
    },
    hasApplicantsRestriction() {
      return this.$store.getters.getApplicantsProcessRestriction;
    },
  },
};
</script>
/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

export function create(selectionProcessId, name, microserviceId,
  cpcId, orderNumberPosition, automatizationType, scoreCondition) {
  return axios.post('/api/v1/stages', decamelizeKeys({
    stage: {
      selectionProcessId,
      name,
      microserviceId,
      cpcId,
      orderNumberPosition,
      automatizationType,
      scoreCondition,
    },
  }))
    .then(res => camelizeKeys(res));
}

export function update(stageId, params) {
  return axios.put(`/api/v1/stages/${stageId}`, decamelizeKeys({
    stage: { ...params },
  }))
    .then(res => camelizeKeys(res));
}

export function archive(stageId) {
  return axios.patch(`/api/v1/stages/${stageId}/archive`)
    .then(res => camelizeKeys(res));
}

export function updateAutomatization(stageMicroserviceId, stage_microservice) {
  return axios.patch(`/api/v1/stage_microservices/${stageMicroserviceId}/auto_config`,
    decamelizeKeys({ stage_microservice }))
    .then(res => camelizeKeys(res));
}

export function getNextStage(stageId) {
  return axios.get(`/api/v1/stages/${stageId}/next`)
    .then(res => camelizeKeys(res));
}

/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

export function update({ userId, completedTour }) {
  return axios.patch(`/api/v1/sessions/${userId}`, decamelizeKeys({
    userId, completedTour,
  }))
    .then(res => camelizeKeys(res));
}

export function find({ userId, completedTour }) {
  return axios.get(`/api/v1/sessions/${userId}?completed_tour=${completedTour}`)
    .then(res => camelizeKeys(res));
}

export function register(params) {
  return axios.post('/api/v1/registrations', params).then(res => res).catch((e) => e.response.data);
}

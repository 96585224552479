<template>
  <div
    class="stock-card mx-auto bg-white rounded-3 shadow-sm"
  >
    <div class="d-flex align-items-center p-3">
      <ms-icons
        v-if="iconId > 0"
        class="me-2"
        :ms-id="iconId"
        height="25"
        width="25"
      />
      <v-icon
        v-else
        left
        color="var(--haunter-purple)"
      >
        {{ icon }}
      </v-icon>
      <h5 class="mb-0">{{ title }}</h5>
    </div>

    <v-row>
      <div
        class="col"
      >
        <v-card-text v-if="stock > 0">
          {{ stock }} {{ stock > 1 ? 'disponibles' : 'disponible' }}
        </v-card-text>
        <v-card-text
          v-else-if="stock === 0"
          class="red-dark--text font-weight-bold"
        >
          ¡No te quedan cupos disponibles!
        </v-card-text>
        <v-card-text v-else-if="stock < 0 && title === 'Postulantes'">
          2500 x proceso
        </v-card-text>
        <v-card-text v-else>
          Ilimitados
        </v-card-text>
      </div>
      <div
        class="col-auto"
      >
        <v-card-text>
          <div class="d-flex justify-end">
            <button
              class="btn btn-tonal-primary btn-sm text-capitalize"
              @click="handleRedirect"
            >
              <v-icon dense>
                {{ mdiCart }}
              </v-icon>
              Comprar más
            </button>
          </div>
        </v-card-text>
      </div>
    </v-row>
    <v-card-actions>
      <v-spacer />

      <button
        @click="show = !show"
        class="btn btn-alpha-primary btn-sm"
      >
        Más información
        <v-icon>{{ show ? mdiChevronUp : mdiChevronDown }}</v-icon>
      </button>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider class="mb-0" />
        <v-card-text>
          <card-detail :title="title" />
        </v-card-text>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { mdiChevronUp, mdiChevronDown, mdiCart } from '@mdi/js';
import MsIcons from '../ms-icons';
import CardDetail from './card-detail';

export default {
  components: {
    MsIcons,
    CardDetail,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    iconId: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: 'filter',
    },
    stock: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mdiChevronUp,
      mdiChevronDown,
      mdiCart,
      show: false,
    };
  },
  methods: {
    handleRedirect() {
      window.location.href = '/company/billing';
    },
  },
};
</script>

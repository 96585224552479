<template>
  <div class="advices-block__item advices-block__item--colorful">
    <LampIcon
      class="advices-block__item-icon"
      v-if="isLamp"
    />
    <InfoIcon
      class="advices-block__item-icon"
      v-if="isInfo"
    />
    <ExamIcon
      class="advices-block__item-icon"
      v-if="isExam"
    />
    <p
      class="advices-block__item-text advices-block__item-text--colorful"
    >
      {{ text }}
    </p>
  </div>
</template>

<script>
import LampIcon from '../icons/LampIcon';
import InfoIcon from '../icons/InfoIcon';
import ExamIcon from '../icons/ExamIcon';

export default {
  name: 'TipBox',
  components: {
    LampIcon,
    InfoIcon,
    ExamIcon,
  },
  props: {
    text: String,
    isLamp: {
      default: false,
      type: Boolean,
    },
    isInfo: {
      default: false,
      type: Boolean,
    },
    isExam: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
    @import "style/style";

    .advices-block {

        &__item {
            display: flex;
            flex: 1;
            height: fit-content;
            padding: 16px 0;

            &--colorful {
                padding: 16px 16px 16px 0;
                background-color: $advice;

                @media (max-width: 992px) {
                    max-width: 100%;
                }
            }
        }

        &__item-icon {
            margin-left: 16px;
        }

        &__item-text {
            margin: 0;
            font-size: 14px;
            color: $text-color;

            &--colorful {
                flex: 1;
                margin-left: 8px;
            }
        }

    }
</style>

<template>
  <v-snackbar
    v-model="snackbar"
    :color="backgroundColor"
    top
    transition="scroll-y-transition"
    :timeout="timeout"
    min-width="420px"
    z-index="100"
  >
    <div class="d-flex align-items-center gap-3">
      <IconSVG v-if="withIcon" :path="icon" width="34px" />
      <div>
        <h6 class="mb-0">
          {{ title }}
        </h6>
        <span :data-cy="dataCy">{{ text }}</span>
        <slot />
      </div>
    </div>

    <template v-slot:action v-if="closable">
      <button class="btn btn-alpha-primary p-0 rounded-2" @click="handleClose">
        <IconSVG :path="mdiClose" :fill="textColor"/>
      </button>
    </template>
  </v-snackbar>
</template>
<script>
import { mdiClose, mdiInformation, mdiCheckCircle } from '@mdi/js';
import IconSVG from './IconSVG';

export default {
  props: {
        /** data-cy para el snackbar
     * ❗type: String
     */
    dataCy: {
      type: String,
      required: false,
      default: ''
    },
    /** Estado del Snackbar, true es activo, false inactivo
     * ❗type: Boolean
     */
    snackbar: {
      type: Boolean,
      default: true,
    },
    /** texto del snackbar
     * ❗type: String
     */
    text: {
      type: String,
      required: false,
    },
    /** Le asigna un color, opciones validas:
     * 'success',
     * 'warning',
     * 'error'
     * ❗type: String
     */
    type: {
      type: String,
      default: 'success',
      required: true,
      validator: (prop) => [
        'success',
        'warning',
        'error',
        'info',
        'alert',
      ].includes(prop),
    },
    /** título del snackbar
     * ❗type: String
     */
    title: {
      type: String,
      required: true,
    },
    timeout: {
      type: Number,
      default: 6000
    },
    withIcon: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mdiClose
    }
  },
  methods: {
    handleClose() {
      this.$emit('toggle', false);
      this.$store.dispatch('closeToast')
    },
  },
  computed: {
    backgroundColor() {
      const TYPE_COLOR = {
        'primary': 'var(--t-primary)',
        'success': 'var(--t-success)',
        'danger': 'var(--t-danger)', // new definition
        'error': 'var(--t-danger)', // old definition
        'alert': 'var(--t-info)',
        'warning': 'var(--t-warning)',
        'info': 'var(--t-info)'
      }
      return TYPE_COLOR[this.type] || 'var(--t-info)'
    },
    textColor() {
      return !['primary', 'danger', 'error'].includes(this.type) ? 'var(--t-black)' : 'var(--t-white)'
    },
    icon() {
      const TYPE_ICON = {
        'success': mdiCheckCircle,
        'danger': mdiInformation,
      }
      return TYPE_ICON[this.type] || mdiInformation
    }
  },
  components: {
    IconSVG
  },
};
</script>
<style lang="scss" scoped>
.snackbar {
  bottom: 50px;
  position: absolute;
}

:deep(.v-snack__wrapper) {
  border-radius: 1rem;
  box-shadow: none !important;
  filter: drop-shadow(0px 8px 56px rgba(39, 39, 63, 0.16));
  flex-direction: row;
  margin-top: 30px;
  .v-snack__content {
    font-size: 1rem;
    color: v-bind(textColor);
    padding: 1.2rem;
  }
  .v-snack__action  {
    margin: 0;
    margin-right: 1rem;
    align-self: center;
  }
}
</style>

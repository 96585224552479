/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import { decamelizeKeys, camelizeKeys } from 'humps';

export function getCountries() {
  return axios.get('/api/v1/countries');
}

export function updateKcCompany(params) {
  return axios.patch(`/api/v1/company/companies/${params.id}`, decamelizeKeys(params));
}

export function updateEcommerceCompany(params) {
  return axios.patch(`/api/v1/company/companies/${params.id}/update_ecommerce`, decamelizeKeys(params));
}

export function getCompany(companyId) {
  return axios.get(`/api/v1/company/companies/${companyId}`)
    .then(res => camelizeKeys(res.data.data));
}

export function updateCompanyImages(companyId, topImg, logo) {
  return axios.post('/api/v1/company/companies/update_images', decamelizeKeys(
    {
      company: {
        companyId,
        topImg,
        logo,
      },
    },
  )).then(res => camelizeKeys(res.data.data));
}

export function getRegions() {
  return axios.get('/api/v1/regions');
}

export function companyLogoUrl(id) {
  return axios.get(`/api/ats/company/logo/${id}`);
}

export function updateSenderEmail(params) {
  return axios.patch(`/api/v1/company/companies/${params.company_id}/update_email_sender_info`, decamelizeKeys(params),
  ).then(res => camelizeKeys(res.data));
}

export function restoreSenderEmail(params) {
  return axios.patch(`/api/v1/company/companies/${params.company_id}/restore_email_sender_info`,
    decamelizeKeys(params),
  ).then(res => camelizeKeys(res.data));
}

export async function getStages(params) {
  try {
    const res = await axios.post('/api/v1/stages/get_stages', decamelizeKeys({ processesId: params }));

    return res.data;
  } catch (error) {
    console.log(error);
  }
}
const initialState = {
  show: false,
  message: '',
  title: '',
  type: 'info'
};

export const mutations = {
  setShow(state, payload) { state.show = payload },
  setMessage(state, payload) { state.message = payload; },
  setType(state, payload) { state.type = payload },
  setTitle(state, payload) { state.title = payload }
};

export const actions = {
  addToast({ commit, state }, { text, type, title = '' }) {
    commit('setShow', true)
    commit('setMessage', text)
    commit('setType', type)
    commit('setTitle', title)
  },
  closeToast({ commit }) {
    commit('setShow', false)
  }
};

export const getters = {
};

export default {
  state: { ...initialState },
  mutations,
  actions,
  getters,
};
<template>
  <div
    class="header text-h6-talana blue-dark--text my-0 mx-auto"
    style="width: auto"
  >
    <div class="cannot-modify-box header-block">
      <div class="cannot-modify-box__background" />
    </div>
    <div>
      <div class="email-header">
        <img
          class="email-header-logo"
          height="35px"
          width="94px"
          src="../../../../../../../assets/images/talana-color-logo.svg"
        >
      </div>
      <div
        class="pt-4 px-9 pb-1"
      >
        <p
          class="name-label"
        >
          Hola Nombre Postulante
        </p>
      </div>
      <div class="email-title">
        <v-col>
          <v-row>
            <div>
              {{ headerTitle[category] }}
            </div>
          </v-row>
        </v-col>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      headerTitle: {
        1: 'Tienes un nuevo cuestionario por realizar',
        2: 'Tienes pendiente un cuestionario por realizar',
        3: 'La solicitud de cuestionario ha expirado',
        4: 'Recibimos tu cuestionario',
      },
    };
  },
  props: {
    isTrabajando: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>

.header {
  background-color: #f7f7f7;
  box-shadow: 2px 0px 0px 0px #dedede;
  position: relative;
}

.email-title {
  text-align: center;
  padding: 0px 20%;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: var(--haunter-purple);
  margin-bottom: 31px;
}

.email-header{
  height: 75px;
  display: flex;
  justify-content: center;
}

.email-header-logo{
  width: 120px;
  height: 30px;
  justify-content: center;
  margin-top: 20px;
}

.name-label {
  font-size: 24px;
  color: var(--haunter-purple);
  font-weight: 700;
  margin-bottom: 31px;
  margin-top: 31px;
  line-height: 27px;
  text-align: center;
}

.email-title div div{
  margin: auto auto;
}

</style>

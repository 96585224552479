<template>
  <div>
    <v-icon>
      {{ mdiInformationOutline }}
    </v-icon>
    {{ description }}
  </div>
</template>

<script>
import { mdiInformationOutline } from '@mdi/js';
import { info } from '../../utils/stocks-info';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      description: null,
      mdiInformationOutline,
    };
  },
  mounted() {
    this.description = info[this.title];
  },
};
</script>

<style>

</style>

<template>
  <div class="setting mb-12">
    <div class="row">
      <div class="col">
        <p class="row__title">
          Período de vigencia de resultados
        </p>
        <p class="row__subtitle" style="text-align: justify;">
          Dada su vigencia metodológica, los resultados de las evaluaciones
          psicométricas rendidas por los candidatos que participen en cualquier
          proceso con Talana, permanecerán válidos por un período de 6 meses desde
          la fecha de rendición.
          El resultado (% de ajuste al cargo) tendrá la relación con las configuraciones
          realizadas por tu organización previamente en el proceso de homologación
        </p>
      </div>
      <div class="row__input-container">
        <validation-provider
          name="días"
          rules="required"
          v-slot="{ errors }"
          tag="div"
        >
          <label
            for="days"
            class="form-label"
          >
            Meses
          </label>
          <select
            v-model="localSetting.value"
            :disabled="!localSetting.canModify"
            class="process-selector"
            :class="{ 'input-text--error': errors.length }"
            @change="$emit('change-months', $event.target.value)"
          >
            <option
              v-for="month in months"
              :key="month.value"
            >
              {{ month.value }}
            </option>
          </select>
          <span
            v-if="!localSetting.canModify"
            class="text--disabled text-subtitle-2-talana"
          >No editable</span>
          <input-errors :errors="errors" />
        </validation-provider>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationProvider } from 'vee-validate';
import InputErrors from '../../../input-errors';

export default {
  components: {
    ValidationProvider,
    InputErrors,
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localSetting: { ...this.setting },
      months: [
        { value: 3 },
        { value: 6 },
        { value: 9 },
        { value: 12 },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>

.row {
  display: flex;

  &__input-container {
    margin: auto 0 auto auto;
    display: flex;
    max-width: 210px;
  }

  &__title {
    color: #263d49;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }

  &__subtitle {
    margin-top: 4px;
    margin-bottom: 0px;
    max-width: 360px;
  }
}

.process-selector {
  background-color: white;
  border: 1px solid lighten(grey, 20%);
  border-radius: 2px;
  width: 214px;
  height: 45px;
  padding: 10px;
  font-size: 16px;
}
</style>

/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

export function moveApplicantStage(selectionProcessId, applicantStage) {
  return axios.put(`/api/v1/selection_processes/${selectionProcessId}`, decamelizeKeys({
    id: selectionProcessId,
    applicantStage,
  }))
    .then(res => camelizeKeys(res));
}

export function moveStage(selectionProcessId, id, orderNumberPosition) {
  return axios.put(`/api/v1/selection_processes/${selectionProcessId}/move_stage`, decamelizeKeys({
    id: selectionProcessId,
    stage: {
      id,
      orderNumberPosition,
    },
  }))
    .then(res => camelizeKeys(res));
}

export function getProcesses(params) {
  return axios.get('/api/v1/selection_processes', decamelizeKeys({ params }))
    .then((res => camelizeKeys(res.data)));
}

export function getSelectionProcess(id) {
  return axios.get(`/api/v1/selection_processes/${id}`)
    .then(res => camelizeKeys(res));
}

export async function getProcess(id) {
  const response = await fetch(`/api/v1/selection_processes/${id}`);
  const data = await response.json();
  let { selection_process: selectionProcess } = data
  
  let directUrl = null;
  // agrega url
  if(selectionProcess?.offer_data?.url){
    directUrl = await getKJOfferUrlBySlug(selectionProcess.offer_data?.url)
    
    selectionProcess.offer_data = {
      ...selectionProcess.offer_data, 
      directUrl
    }
  }

  return camelizeKeys(selectionProcess);
}

export async function getKJOfferUrlBySlug(slugUrl) {
  try {
    const regex = /(?<=offerLink\/)[^\]]+/g;
    if(slugUrl?.match(regex)){
      // extraer slug
      const offerSlug = slugUrl.match(regex)[0];
      //endpoint KJOffer url
      const fetchOfferUrl = await fetch(`/get_offer_url/${offerSlug}`);
      const offerUrl = await fetchOfferUrl.json();
      
      return offerUrl?.data;
    }
  } catch (error) {
    console.error(error);
  }
}

export function updateSelectionProcessState({ id, state }) {
  return axios.patch(`/api/v1/selection_processes/${id}/state`, decamelizeKeys({
    id,
    state,
  })).then(res => camelizeKeys(res));
}

export function getAvailableMicroservices(id) {
  return axios.get(`/api/v1/selection_processes/${id}/available_microservices`)
    .then((res => camelizeKeys(res.data)));
}

export function createSelectionProcess(selectionProcess, kjOffer, trabajandoOffer, copyId, automatizationCopyCheckbox) {
  return axios.post('/api/v1/selection_processes', decamelizeKeys({
    selectionProcess,
    kjOffer,
    trabajandoOffer,
    copyId,
    automatizationCopyCheckbox,
  })).then(res => camelizeKeys(res)).catch(() => {
    throw new Error('Server Error');
  });
}

export function updateSelectionProcess(id, selectionProcess, kjOffer, trabajandoOffer) {
  return axios.patch(`/api/v1/selection_processes/${id}`, decamelizeKeys({
    selectionProcess: { ...selectionProcess },
    kjOffer,
    trabajandoOffer,
  })).then(res => camelizeKeys(res)).catch(() => {
    throw new Error('Server Error');
  });
}

export function createOrUpdateOffer(id, kjOffer, trabajandoOffer) {
  return axios
    .patch(`/api/v1/selection_processes/${id}/update_offer`, decamelizeKeys({ kjOffer, trabajandoOffer }))
    .then(res => camelizeKeys(res));
}

export function createOrActivateKjOffer(id, kjOffer) {
  return axios
    .patch(`/api/v1/selection_processes/${id}/activate_kj`, decamelizeKeys({ kjOffer }))
    .then(res => camelizeKeys(res));
}

export function deactivateKjOffer(id, kjOffer) {
  return axios
    .patch(`/api/v1/selection_processes/${id}/deactivate_kj`, decamelizeKeys({ kjOffer }))
    .then(res => camelizeKeys(res));
}

export function createOrActivateTrabajandoOffer(id) {
  return axios
    .patch(`/api/v1/selection_processes/${id}/activate_trabajando`)
    .then(res => camelizeKeys(res));
}

export function deactivateTrabajandoOffer(id) {
  return axios
    .patch(`/api/v1/selection_processes/${id}/deactivate_trabajando`)
    .then(res => camelizeKeys(res));
}

export function changeOfferActive({ processId, active }) {
  return axios.patch(`/api/v1/selection_processes/${processId}/offer`, decamelizeKeys({
    active,
  }))
    .then(res => camelizeKeys(res));
}

export function moveApplicantStages(processId, applicantStagesId, destinationStageId, orderNumber) {
  return axios.patch(`/api/v1/selection_processes/${processId}/move_applicant_stages`, decamelizeKeys({
    applicantStagesId,
    destinationStageId,
    orderNumber,
  }))
    .then(res => camelizeKeys(res))
    .catch(err => console.log(err));
}

export function lockProcess(selectionProcessId) {
  return axios.patch(`/api/v1/selection_processes/${selectionProcessId}/lock_process`, decamelizeKeys({
    selectionProcessId,
  })).then(res => camelizeKeys(res));
}

export function unlockProcess(selectionProcessId) {
  return axios.patch(`/api/v1/selection_processes/${selectionProcessId}/unlock_process`, decamelizeKeys({
    selectionProcessId,
  })).then(res => camelizeKeys(res));
}

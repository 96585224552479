import { render, staticRenderFns } from "./btn.vue?vue&type=template&id=16f09b40&scoped=true"
import script from "./btn.vue?vue&type=script&lang=js"
export * from "./btn.vue?vue&type=script&lang=js"
import style0 from "./btn.vue?vue&type=style&index=0&id=16f09b40&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16f09b40",
  null
  
)

export default component.exports
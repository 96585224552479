/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import { camelizeKeys } from 'humps';

export function getKgEmailTemplates() {
  return axios
    .get('/api/v1/kg/email_templates')
    .then(res => camelizeKeys(res.data.data));
}

export function updateTemplate(params) {
  return axios
    .patch(`/api/v1/kg/email_templates/category/${params.category}`, params)
    .then(res => camelizeKeys(res.data));
}

export function createTemplate(params) {
  return axios
    .post('/api/v1/kg/email_templates/create', params)
    .then(res => camelizeKeys(res.data));
}

export function changeKgStateTemplate(category) {
  return axios
    .patch(`/api/v1/kg/email_templates/change_state/${category}`)
    .then(res => camelizeKeys(res.data));
}

<template>
  <div class="setting">
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <page-title>
          Días para la expiración de las pruebas
        </page-title>
        <span>
          Modifica días para la expiración de las pruebas.
        </span>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <span class="form-label">Días</span>
        <div>
          <v-select
            v-model="localSetting.value"
            :items="options"
            :disabled="!setting.canModify"
            outlined
            dense
            persistent-hint
            hint="De 3 a 7 días"
            @input="$emit('change-days', localSetting.value)"
          />
        </div>
      </v-col>
    </v-row>
    <hr class="divider">
  </div>
</template>
<script>
import PageTitle from '../../../ui/page-title';

export default {
  components: {
    PageTitle,
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localSetting: { ...this.setting },
      options: [3, 4, 5, 6, 7],
    };
  },
};
</script>

<style lang="scss" scoped>

.divider {
  margin: 25px 0;
  opacity: 0.2;
}

</style>

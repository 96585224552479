<template>
  <div
    class="mt-3 mx-auto mb-0 pa-10"
  >
    <label for="subject">Asunto del correo</label>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="subject"
            outlined
            dense
            id="subject"
            :rules="[rules.required]"
          />
        </v-form>
      </v-col>
      <v-col
        cols="12"
        md="2"
        class="mb-5"
      >
        <button
          class="btn btn-primary"
          @click="saveSubject"
          :disabled="!valid"
        >
          Guardar
        </button>
      </v-col>
      <v-col>
        <button
          class="btn btn-tonal-primary"
          :disabled="!active || loading"
          @click="deactive"
        >
          Restaurar correo original
        </button>
      </v-col>
    </v-row>

    <div class="white rounded">
      <div class="small mb-3">
        <div>
          {{ `De: ${company.custom_email_sender ? company.email_sender_name : company.razon_social},
          [infoseleccion@talana.com]` }}
        </div>
        <div>
          Asunto: {{ subject }}
        </div>
      </div>
      <custom-email-header
        :is-trabajando="company.trabajando"
        :category="category"
      />
      <modal
        :open="open"
        @close-modal="handleModal"
        :title="'Edición de mail'"
        :height="'auto'"
      >
        <edit-email-modal
          :current-text="emailText"
          @close-modal="handleModal"
          @sendText="deliverText"
        />
      </modal>
      <custom-text
        @openModal="handleModal"
        :text="emailText"
      />
      <custom-email-footer :category="category" />
      <snackbar
        :type="'success'"
        :title="'¡Éxito!'"
        :snackbar="success"
        :text="message"
        @toggle="toggleSnackbar"
      />
      <snackbar
        :type="'error'"
        :title="'Error'"
        :snackbar="error"
        :text="message"
        @toggle="toggleSnackbar"
      />
    </div>
  </div>
</template>

<script>
import CustomEmailHeader from './custom-email-header.vue';
import CustomText from './custom-text.vue';
import CustomEmailFooter from './custom-email-footer.vue';
import EditEmailModal from './edit-email-modal.vue';
import Modal from '../../../../../ui/modal.vue';
import Snackbar from '../../../../../ui/snackbar.vue';
import {
  updateTemplate,
  createTemplate,
  changeKgStateTemplate,
} from '../../../../../../api/keygaming/email-templates';

export default {
  components: {
    CustomEmailHeader,
    CustomText,
    CustomEmailFooter,
    EditEmailModal,
    Modal,
    Snackbar,
  },
  props: {
    company: {
      required: true,
      type: Object,
    },
    kgAssignment: {
      type: Object,
      default: () => {},
    },
    kgPending: {
      type: Object,
      default: () => {},
    },
    kgExpiration: {
      type: Object,
      default: () => {},
    },
    kgAnswered: {
      type: Object,
      default: () => {},
    },
    category: {
      type: Number,
      required: true,
    },
    created: {
      type: Boolean,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emailText: '',
      error: false,
      message: '',
      success: false,
      open: false,
      subject: null,
      loading: false,
      assignmentSubject: 'Tienes una nueva evaluación gamificada por realizar',
      assigmentBody: `Esta solicitud es parte del proceso de selección para el cargo de
      <b>{{nombre_proceso}}</b> en <b>{{nombre_organizacion}}</b>`,
      pendingSubject: 'Tienes una Evaluación Gamificada pendiente por realizar',
      pendingBody: `Como parte del proceso {{nombre_proceso}} de {{nombre_organizacion}}
          en el que te encuentras, te recordamos que debes realizar una Evaluación Gamificada lo
          antes posible, ingresando a través del siguiente botón:`,
      expirationSubject: 'No recibimos tu Evaluación Gamificada a tiempo',
      expirationBody: 'Podrás verla en tu sección de Solicitudes como Expirada',
      answeredSubject: '¡Recibimos tu Evaluación Gamificada!',
      answeredBody: `La organización {{nombre_organizacion}} recibió a tiempo tu Evaluación Gamificada
          para el proceso {{nombre_proceso}}. Puedes ver esta tarea ya realizada en la siguiente página:`,
      valid: true,
      rules: {
        required: (value) => !!value || 'Debe ingresar un asunto.',
      },
    };
  },
  beforeMount() {
    this.setInitialText();
  },
  mounted() {
    this.categoryName = { 1: 'assignment', 2: 'pending', 3: 'expiration', 4: 'answered' };
  },
  methods: {
    handleModal() {
      this.open = !this.open;
    },
    toggleSnackbar(bool) {
      this.success = bool;
      this.error = bool;
    },
    setInitialText() {
      switch (this.category) {
      case 1:
        if (!this.created || this.kgAssignment.state === 'deactived') {
          this.subject = this.assignmentSubject;
          this.emailText = this.assigmentBody;
        } else {
          this.subject = this.kgAssignment.subject ? this.kgAssignment.subject : this.assignmentSubject;
          this.emailText = this.kgAssignment.body ? this.kgAssignment.body : this.assigmentBody;
        }
        break;
      case 2:
        if (!this.created || this.kgPending.state === 'deactived') {
          this.subject = 'Tienes una Evaluación Gamificada pendiente por realizar';
          this.emailText = this.pendingBody;
        } else {
          this.subject = this.kgPending.subject ? this.kgPending.subject : this.pendingSubject;
          this.emailText = this.kgPending.body ? this.kgPending.body : this.pendingBody;
        }
        break;
      case 3:
        if (!this.created || this.kgExpiration.state === 'deactived') {
          this.subject = this.expirationSubject;
          this.emailText = this.expirationBody;
        } else {
          this.subject = this.kgExpiration.subject ? this.kgExpiration.subject : this.expirationSubject;
          this.emailText = this.kgExpiration.body ? this.kgExpiration.body : this.expirationBody;
        }
        break;
      case 4:
        if (!this.created || this.kgAnswered.state === 'deactived') {
          this.subject = this.answeredSubject;
          this.emailText = this.answeredBody;
        } else {
          this.subject = this.kgAnswered.subject ? this.kgAnswered.subject : this.answeredSubject;
          this.emailText = this.kgAnswered.body ? this.kgAnswered.body : this.answeredBody;
        }
        break;

      default:
        break;
      }
    },
    deliverText(text) {
      const params = {
        body: text,
        category: this.created ? this.category : this.categoryName[this.category],
        subject: this.subject,
      };
      if (this.created) {
        updateTemplate(params)
          .then(res => {
            if (res.isSuccess) {
              this.success = true;
              this.message = 'Email actualizado exitosamente';
              this.emailText = text;
              this.open = !this.open;
              this.$emit('update');
            } else {
              this.error = true;
              this.open = !this.open;
              this.message = 'Hubo un error con la solicitud, inténtalo más tarde';
            }
          });
      } else {
        createTemplate(params)
          .then(res => {
            if (res.isSuccess) {
              this.success = true;
              this.message = 'Email actualizado exitosamente';
              this.emailText = text;
              this.open = !this.open;
              this.$emit('update');
            } else {
              this.error = true;
              this.open = !this.open;
              this.message = 'Hubo un error con la solicitud, inténtalo más tarde';
            }
          });
      }
    },
    saveSubject() {
      if (this.$refs.form.validate()) {
        const params = {
          body: this.emailText,
          category: this.created ? this.category : this.categoryName[this.category],
          subject: this.subject,
        };
        if (this.created) {
          updateTemplate(params)
            .then(res => {
              if (res.isSuccess) {
                this.success = true;
                this.message = 'Email actualizado exitosamente';
                this.$emit('update');
              } else {
                this.error = true;
                this.message = 'Hubo un error con la solicitud, inténtalo más tarde';
              }
            });
        } else {
          createTemplate(params)
            .then(res => {
              if (res.isSuccess) {
                this.success = true;
                this.message = 'Email actualizado exitosamente';
                this.$emit('update');
              } else {
                this.error = true;
                this.message = 'Hubo un error con la solicitud, inténtalo más tarde';
              }
            });
        }
      }
    },
    restoreOriginal() {
      switch (this.category) {
      case 1:
        this.subject = this.assignmentSubject;
        this.emailText = this.assigmentBody;
        break;
      case 2:
        this.subject = this.pendingSubject;
        this.emailText = this.pendingBody;
        break;
      case 3:
        this.subject = this.expirationSubject;
        this.emailText = this.expirationBody;
        break;
      case 4:
        this.subject = this.answeredSubject;
        this.emailText = this.answeredBody;
        break;

      default:
        break;
      }
    },
    deactive() {
      this.loading = true;
      changeKgStateTemplate(this.category)
        .then(res => {
          if (res.isSuccess) {
            this.loading = false;
            this.success = true;
            this.message = 'Se ha restaurado el correo original';
            this.restoreOriginal();
            this.$emit('update');
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>

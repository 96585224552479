/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import { decamelizeKeys } from 'humps';

export async function createBilling(params) {
  try {
    const res = await axios.post('/company/billing/create_transaction', decamelizeKeys(params));

    return { status: 200, response: res.data.data.response };
  } catch (e) {
    const badRequest = 400;

    if (e.response.status === badRequest) {
      return { status: 400, msg: 'Data Error' };
    }

    return { status: 500, msg: 'Server Error' };
  }
}

export function getCompanyInvoices() {
  return axios.get('/api/v1/ecommerce/get_company_invoices')
    .then(res => res.data)
    .catch(() => null);
}

export function createStockLoads(params) {
  return axios.post('/api/v1/company/kc_plans/create_stock_loads', { stock_loads: params })
    .then(res => res.data)
    .catch(() => null);
}

export function getKcPlans() {
  return axios.get('/api/v1/company/kc_plans/get_kc_plans')
    .then(res => res.data)
    .catch(() => null);
}

export async function getStockLoads(token) {
  try {
    const res = await axios.get(`/api/v1/company/kc_plans/stock_loads/${token}`);

    return res.data;
  } catch (e) {
    const badRequest = 400;

    if (e.response.status === badRequest) {
      return { status: 400, msg: 'Data Error' };
    }

    return { status: 500, msg: 'Server Error' };
  }
}
<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <ms-icons
            :ms-id="6"
            height="40"
            width="40"
            class="text-haunter"
          />
        </v-col>
        <v-col
          cols="12"
          md="11"
          class="d-flex align-center"
        >
          <span>Evaluaciones Gamificadas</span>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <kg-settings />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import KgSettings from './kg-settings.vue';
import MsIcons from '../../../../ms-icons.vue';

export default {
  components: {
    KgSettings,
    MsIcons,
  },
};
</script>

<style>

</style>

import { getCompanyInvoices } from '../api/ecommerce';

const initialState = {
  USD: 125,
  TAX_VALUE: 0.19, // IVA
  PROCESS_ALERT_CONST: 50, // Cantidad de procesos necesarios para ofrecer un mejor precio.
  billingData: null,
  CART: JSON.parse(localStorage.getItem('kcCart')) || [],
};

export const mutations = {
  setUSD(state, payload) {
    state.USD = payload;
  },
  setBillingData(state, payload) {
    state.billingData = payload;
  },
  setCart(state, payload) {
    state.CART = payload;

    localStorage.setItem('kcCart', JSON.stringify(state.CART));
  },
  addToCart(state, payload) {
    state.CART.push(payload);

    localStorage.setItem('kcCart', JSON.stringify(state.CART));
  },
  deleteFromCart(state, payload) {
    const deleted = state.CART.indexOf(payload);
    state.CART.splice(deleted, 1);

    localStorage.setItem('kcCart', JSON.stringify(state.CART));
  },
};

export const actions = {
  getCompanyInvoice({ commit }) {
    getCompanyInvoices()
      .then(res => {
        if (res.data.company_invoices.length > 0) commit('setBillingData', res.data.company_invoices);
      });
  },
};

export const getters = {
  getUSD(state) {
    return state.USD;
  },
  getTaxValue(state) {
    return state.TAX_VALUE;
  },
  getProcessAlert(state) {
    return state.PROCESS_ALERT_CONST;
  },
  getBillingData(state) {
    return state.billingData;
  },
  getCart(state) {
    return state.CART;
  },
};
export default {
  state: { ...initialState },
  mutations,
  actions,
  getters,
};

<template>
  <div>
    <custom-email
      :company="company"
      :ki-expiration="kiExpiration"
      :category="category"
      :created="created"
      :active="isActive"
      @update="update"
    />
  </div>
</template>

<script>
import CustomEmail from './custom-email/custom-email.vue';

export default {
  components: {
    CustomEmail,
  },
  props: {
    kiTemplates: {
      type: Array,
      default: () => [],
    },
    category: {
      type: Number,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      created: false,
      kiExpiration: null,
      categoryName: null,
      isActive: false,
    };
  },
  mounted() {
    this.categoryName = { 1: 'assignment', 2: 'pending', 3: 'expiration', 4: 'answered' };
    this.isActive = this.kiExpiration?.state === 'active';
  },
  beforeMount() {
    this.kiExpiration = this.kiTemplates.find(t => t.category === 'expiration');
    if (this.kiExpiration) this.created = true;
  },
  methods: {
    update() {
      this.$emit('update');
    },
  },
};
</script>

<style>

</style>

/* eslint-disable no-use-before-define */
import axios from 'axios';
import Vue from 'vue/dist/vue.esm';
import { camelizeKeys } from 'humps';

const config = {
  duration: 5000,
  position: 'top',
  queue: true,
}

const SERVER_ERROR_CODE = 500;
export default function setup() {
  axios.interceptors.response.use((config) => config, (err) => {
    let text;
    const { response: { status, data } } = err;

    if(data) {
      const { humanMessage, errors } = camelizeKeys(data);
      text = getErrorMessages(errors) || humanMessage;  
    }

    if (!text) {
      if (status >= SERVER_ERROR_CODE) {
        text = 'Algo ha ocurrido con el servidor. <a href="/">Refresca</a> tu navegador';
      } else if(status != 401) {
        text = 'Has ingresado información no válida. Intenta nuevamente.';
      }
    }

    if(text) Vue.$toast.open({ message: `<b class="fs-6">Ops!</b><br>${text}`, type: 'error', ...config });

    return Promise.reject(err);
  });
}

function getErrorMessages(errors) {
  let text = '';
  if (errors) Object.values(errors).forEach((e) => text += e.join(', '));

  return text;
}

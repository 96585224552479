import * as sessionApi from '../api/session';

const emptySet = new Set();
const initialState = {
  steps: [],
  completedTours: emptySet,
  userId: null,
  currentTourName: '',
  onTour: false,
};

export const mutations = {
  setSteps(state, payload) {
    state.steps = payload;
  },
  setOnTour(state, payload) {
    state.onTour = payload;
  },
  setCompletedTours(state, payload) {
    state.completedTours = new Set(state.completedTours.add(payload));
  },
  setCurrentTourName(state, payload) {
    state.currentTourName = payload;
  },
  setUserId(state, payload) {
    state.userId = payload;
  },
};

export const actions = {
  completeTour(_, payload) {
    sessionApi.update(payload);
  },
  hasCompletedTour({ commit, getters }, payload) {
    const { completedTour } = payload;
    const userId = getters.getUserId;
    const updatedPayload = { ...payload, userId };

    return new Promise((resolve, reject) => {
      sessionApi.find(updatedPayload).then(({ data: { tourCompleted } }) => {
        if (tourCompleted) {
          resolve(true);
        } else {
          resolve(false);
        }
      }).catch((err) => reject(err))
        .finally(() => {
          commit('setCompletedTours', completedTour);
        });
    });
  },

  setCurrentTour({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit('setCurrentTourName', payload);
        resolve(payload);
      } catch (error) {
        reject(error);
      }
    });
  },
};

export const getters = {
  getCompleteTours(state) {
    return state.completedTours;
  },
  getUserId(state) {
    return state.userId;
  },
  steps(state) {
    return state.steps;
  },
  currentTourName(state) {
    return state.currentTourName;
  },
};
export default {
  state: { ...initialState },
  mutations,
  actions,
  getters,
};

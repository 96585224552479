<template>
  <button
    class="btn"
    :class="[styleClass, classProp]"
    :style="[{width: width}, height, minWidth]"
    @click="$emit('click')"
    :disabled="disabled || loading"
  >
    <slot />
  </button>
</template>
<script>
export default {
  props: {
    /** Asigna el color al botón
     *
     * Por default es verde
     *
     * ❗type: String
     */
    color: {
      type: String,
      default: 'primary',
    },
    /** Estado de carga del botón
     *
     * Bloquea el uso cuando loading = true
     *
     * ❗OBLIGATORIO, se deberá utilizar cuando el uso de botón conlleve llamadas AJAX
     *
     * ❗type: Boolean
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /** Deshabilita el botón
     *
     * Estado por defecto es false
     *
     * ❗type: Boolean
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** Clases que se añadiran en la propiedad class
     *
     * Deben ser clases de Vuetify
     *
     * ❗type: String
     */
    classProp: {
      type: String,
      default: '',
    },
    /** Alto del botón
     *
     * Por defecto es 40, estándar en la aplicación
     *
     * ❗type: String
     */
    height: {
      type: String,
      default: '40',
    },
    /** Ancho del botón
     *
     * Por defecto es 160, estándar en la aplicación
     *
     * ❗type: String
     */
    width: {
      type: String,
      default: '160',
    },
    /** Ancho mínimo
     *
     * Por defecto es undefined, estándar en la aplicación
     *
     * ❗type: String
     */
    minWidth: {
      type: String,
      default: undefined,
    },
    /** Define si el botón es oultined o no
     *
     * Por defecto es falso
     *
     * ❗type: boolean
     */
    outlined: {
      type: Boolean,
      default: false,
    },
    textNone: {
      type: Boolean,
      default: false,
    },
    sm: Boolean, 
    lg: Boolean
  },
  computed: {
    styleClass() {
      const outline = this.outlined ? 'outline-' : '';
      const size = this.sm ? ' btn-sm' : (this.lg ? ' btn-lg' : '')
      return [`btn-${outline}${this.color}${size}`]
    }
  }
};
</script>
<style lang="scss" scoped>
  .btn {
    margin-block: auto;
  }
</style>

<template>
  <div>
    <div class="km-settings-header">
      <h1 class="km-settings-header__title">
        Firma Mailing
      </h1>
    </div>

    <warn-box
      v-if="warnBoxMessage"
      :is-red="!hasSucceeded"
      :is-green="hasSucceeded"
      :text="warnBoxMessage"
    />

    <div class="km-settings-body">
      <div class="km-settings-body__row">
        <p class="km-settings-body__toggle-text">
          Firma {{ this.actionText }}
        </p>
        <template>
          <div
            class="toggle"
            v-tooltip="tooltipText"
          >
            <label class="email-template-status__toggle">
              <input
                type="checkbox"
                v-model="active"
                @change="changeState"                
              >
              <span class="email-template-status__slider" />
            </label>
          </div>
        </template>
      </div>
      <div
        class="km-settings-body__row km-settings-body__row--no-flex"
      >
        <hr>
        <div
          class="email-template-modal__body-message email-template-modal__email-body"
          @click="focusContentEditor"
        >
          <h3 class="email-template-modal__middle__title">
            Firma
          </h3>
          <editor-content
            class="email-template-modal__email-input email-template-modal__email-input-body"
            :editor="contentEditor"
          />
        </div>
      </div>
      <div class="km-settings-footer__row d-flex justify-content-end gap-2">
        <button
          class="btn btn-outline-primary"
          @click="back"
          >
          Volver
        </button>
        <button
          class="btn btn-primary"
          @click="send"
          :disabled="loading || !active"
          v-tooltip="buttonTooltip"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import {
  createSignature,
  updateSignature,
  activateSignature,
  deactivateSignature
} from '../../../../api/keymailer/index';
import { Editor, EditorContent } from 'tiptap';
import WarnBox from '../../../keyscoring/global/WarnBox';

export default {
  components: {
    EditorContent,
    WarnBox,
  },
  props: {
    signature: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      okSubmit: false,
      contentEditor: null,
      defaultEditorText: '<p>Ingrese su firma.</p>',
      active: false,
      warnBoxMessage: null,
      hasSucceeded: true,
    };
  },
  methods: {
    back() {
      this.$emit('input', false);
    },
    setData() {
      this.active = this.signature ? this.signature.active : false;
      this.defaultEditorText = this.signature ? this.messageToHTML(this.signature.content) : '';
    },
    messageToHTML(msg) {
      // Reemplazar saltos de líneas por tags de párrafo
      const formattedMsg = `<p>${msg.replace(/\n/g, '</p><p>')}</p>`;

      return formattedMsg;
    },
    jsonToMessage(jsonMsg) {
      /*
       * Pasa la versión JSON del texto en el editor de tiptap a un texto plano
       * para que KeyMailer lo entienda
       */
      let unformattedMsg = '';
      for (const paragraph of jsonMsg.content) {
        // Agregar salto de línea por cada párrafo
        unformattedMsg += '\n';
        if (!paragraph.content) {
          continue;
        }
        for (const element of paragraph.content) {
          unformattedMsg += element.text;
        }
      }

      return unformattedMsg.slice(1);
    },
    send() {
      this.loading = true;
      if (this.signature) {
        const content = this.jsonToMessage(this.contentEditor.getJSON());
        updateSignature(this.signature.id, content).then(res => {
          if (res.isSuccess) {
            this.$emit('change-signature', res.data.signature);
            this.hasSucceeded = true;
            this.warnBoxMessage = 'La firma ha sido modificada exitosamente.';
          } else {
            this.hasSucceeded = false;
            this.warnBoxMessage = 'Hubo un error al actualizar la firma. Intente nuevamente.';
          }
          this.loading = false;
        });
      }
      else {
        const content = this.jsonToMessage(this.contentEditor.getJSON());
        createSignature(content).then(res => {
          if (res.isSuccess) {
            this.$emit('change-signature', res.data.signature);
            this.hasSucceeded = true;
            this.warnBoxMessage = 'La firma ha sido creada exitosamente.';
          } else {
            this.hasSucceeded = false;
            this.warnBoxMessage = 'Hubo un error al crear la firma. Intente nuevamente.';
          }
          this.loading = false;
        });
      }
    },
    changeState() {
      if (this.active && this.signature) {
        activateSignature(this.signature.id).then(res => {
          this.$emit('change-signature', res.data.signature);
        });
      }
      else if (this.signature) {
        deactivateSignature(this.signature.id).then(res => {
          this.$emit('change-signature', res.data.signature);
        });
      }
    },
    focusContentEditor() {
      this.contentEditor.focus();
    }
  },
  computed: {
    tooltipText() {
      if (this.active) {
        return 'Desactivar';
      }

      return 'Activar';
    },
    buttonTooltip() {
      if (this.active) {
        return '';
      }

      return 'La firma está desactivada';
    },
    actionText() {
      if (this.active) {
        return 'activada';
      }

      return 'desactivada';
    },
  },
  mounted() {
    this.setData();
    this.contentEditor = new Editor({
      content: this.defaultEditorText,
      editorProps: { handlePaste: () => true },
      onFocus: () => { this.showTagMenuOf = 'subject'; },
    });
  },
  beforeDestroy() {
    this.contentEditor.destroy();
  },
};
</script>

<style lang="scss" scoped>
.km-settings-header {
  display: flex;
  margin-bottom: 20px;

  &__title {
    margin-right: auto;
    font-size: 22px;
  }
}

.km-settings-body {
  background: #fff;
  padding: 40px;
  border-radius: 5px;

  &__toggle-text {
    margin: 0 15px 0 0;
    font-size: 15px;
    width: 130px;
  }

  &__content-text {
    font-size: 12px;
  }

  &__row {
    display: flex;
    margin-bottom: 20px;

    &--no-flex {
      display: block;
      width: 50%;
      min-width: 600px;
      margin-bottom: 15px;
    }
  }
}

.km-settings-footer {
  &__row {
    width: 50%;
    min-width: 600px;
  }

  &__back-btn {
    font-size: 14px;
    padding: 8px 44.7px;
    border-radius: 5px;
    margin-right: 15px;
    float: right;
  }
}

.signature-input {
  background: #f2f2f2;
  padding: 12px 36px;
}

.toggle {
  padding-top: 4px;
}

.email-template-status {
  &__toggle {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 13px;

    &-label {
      padding-right: 10px;
    }

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(189, 198, 216, .37);
    transition: .4s;
    border-radius: 34px;

    &::before {
      position: absolute;
      content: '';
      height: 13px;
      width: 13px;
      background-color: rgba(189, 198, 216, 1);
      transition: .4s;
      border-radius: 50%;
    }
  }

  &__toggle input:checked + &__slider {
    background-color: #9898FF;

    &::before {
      background-color: rgba(34, 34, 191, 1);
      transform: translateX(24px);
    }
  }
}

.email-template-modal {
  color: #263d49;

  &__body {
    margin: 1em 2em;
    display: grid;
    column-gap: 3em;
    grid-template-columns: 58% auto;
    grid-template-rows: 1fr 1fr 1fr 1fr 3fr;
  }

  &__middle {
    margin-bottom: 30px;

    &__title {
      margin-bottom: .3rem;
      font-weight: 500;
      font-size: 16px;
    }
  }

  &__email-body {
    margin: .2rem 0 1.5rem;
  }

  &__email-input {
    border: 0;
    /* border-radius: .5rem; */
    background-color: #f2f2f2;
    width: 100%;
    overflow: auto;
    font-size: 13px;
    padding: 1em;

    &:focus,
    &:hover {
      outline: none;
    }
  }

  &__email-input-body {
    height: 10rem;
  }

  &__send-button {
    float: right;
    border-radius: 5px;
    padding: 8.8px 40px;
    font-size: 14px;
  }
}
</style>

<template>
  <div class="tests-own">
    <p
      class="tests-own__text"
      :class="{'tests-own__text--yellow' : isYellow,
               'tests-own__text--red' : isRed,
               'tests-own__text--green' : isGreen,
               'js-empty-tests-error' : hasntCheckedTests}"
    >
      {{ text }}
    </p>
  </div>
</template>

<script>

export default {
  name: 'WarnBox',
  props: {
    isRed: {
      default: false,
      type: Boolean,
    },
    isYellow: {
      default: false,
      type: Boolean,
    },
    isGreen: {
      default: false,
      type: Boolean,
    },
    text: {
      default: '',
      type: String,
    },
    hasntCheckedTests: Boolean,
  },
};
</script>

<style lang="scss" scoped>
    @import 'style/style';

    .tests-own {
      &__text {
        padding: 1.3em;
        margin: 0;
        color: $text-color;
        border: 1px solid;
        border-left-width: 6px;

        &--yellow {
          background-color: var(--t-warning-container);
          border-color: $yellow;
        }

        &--red {
          background-color: rgba(var(--t-danger-container-rgb), .5);
          border-color: $red;
        }

        &--green {
          background-color: rgba(var(--t-success-container-rgb), .5);
          border-color: var(--t-success);
        }
      }
    }
</style>

<template>
  <div class="settings-container">
    <div class="title-row">
      <div class="title-row__title-container">
        <page-title class="mb-2">
          Configuración Pruebas psicométricas
        </page-title>
        <page-subtitle class="mb-3">
          Acá podrás configurar algunas funciones
        </page-subtitle>
      </div>
    </div>
    <p
      class="flag-notification"
      :class="{ 'flag-notification--error': errorFlag, ' flag-notification--success': successFlag }"
      v-if="errorFlag || successFlag"
    >
      {{ message }}
    </p>
    <div class="settings">
      <template v-if="settings">
        
        <settings-row
          title="Asignación de prueba confidencial"
          style="text-align: justify;"
          subtitle="Postulante no podrá ver el resultado de su(s) prueba(s)"
          :setting="settings.confidential"
          @toggle-settings="updateSettings"
        />
        <settings-row
          title="Consultar parentesco"
          style="text-align: justify;"
          subtitle="Pregunta al postulante si tiene algún parentesco dentro de la organización"
          :setting="settings.relation"
          @toggle-settings="updateSettings"
        />
        <settings-row
          title="Consultar renta"
          style="text-align: justify;"
          subtitle="Pregunta al postulante sus pretensiones de renta"
          :setting="settings.rent"
          @toggle-settings="updateSettings"
        />
        <settings-row
          title="Rendición mobile"
          style="text-align: justify;"
          subtitle="Habilita rendición desde un dispositivo móvil (celular, tablet)"
          mobile
          :setting="settings.mobile"
          @toggle-settings="updateSettings"
        />
        <expiration-row
          :setting="settings.expiration"
          @change-days="changeExpirationDays"
        />
        <reuse-row
          :setting="settings.reuseTestDuration"
          @change-months="changeTestDuration"
        />
        <settings-row          
          title="Permitir nuevas rendiciones"
          style="text-align: justify;"
          subtitle="Los Postulantes podrán rendir nuevamente una prueba, aún cuando sus resultados
          estén válidos. Activar esta función puede afectar la validez metodológica de las pruebas
          y provocar efecto aprendizaje (El cambio de esta configuración no es retroactivo, es decir, que aplicará sólo para las nuevas asignaciones)."
          :setting="settings.retakeTest"
          @toggle-settings="updateSettings"
        />
        <div
          class="save-button-container"
          v-if="currentUser.userRole === 'Administrador'"
        >
          <btn
            :disabled="errors"
            :loading="false"
            class="btn save-button-container__save-btn"
            @click="updateInputSettings"
            color="primary"
          >
            Guardar cambios
          </btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import apiCalls from '../../../api/ks-settings';
import SettingsRow from '../settings_keyscoring/components/settings-row';
import ExpirationRow from '../settings_keyscoring/components/expiration-row';
import ReuseRow from '../settings_keyscoring/components/reuse-row';
import btn from '../../ui/btn';
import PageTitle from '../../ui/page-title';
import PageSubtitle from '../../ui/page-subtitle';

function action(activated) {
  return activated ? 'activada' : 'desactivada';
}

const settingName = {
  'confidential': 'confidencial',
  'relation': 'parentesco',
  'rent': 'renta',
  'mobile': 'mobile',
  'retake_test': 'nuevas rendiciones',
  'reuse_test_duration': 'duración de pruebas',
};

export default {
  components: {
    SettingsRow,
    ExpirationRow,
    ReuseRow,
    btn,
    PageTitle,
    PageSubtitle,
  },
  props: {
    currentUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      settings: null,
      successFlag: false,
      errorFlag: false,
      message: '',
      expirationDays: null,
      email: null,
      errors: false,
      reuseTestDuration: 6,
    };
  },
  beforeMount() {
    this.setSettings();
  },
  methods: {
    setSettings() {
      apiCalls.getSettings()
        .then(res => {
          this.settings = res.settings;
          this.expirationDays = res.settings.expiration.value;
        })
        .catch(() => {
          this.errorFlag = true;
          this.message = 'No es posible mostrar las cofiguraciones. Intenta nuevamente';
        });
      apiCalls.getEmail()
        .then(res => {
          this.email = res.data.email;
        })
        .catch(() => {
          this.email = null;
        });
    },
    updateSettings(setting) {
      const { name, value } = setting;
      const newState = !value;
      apiCalls.updateSetting(name, newState)
        .then(() => {
          // new snackbar
          this.$store.dispatch('addToast', {
            title: '¡Éxito!',
            text: `La configuración ${settingName[name]} fue ${action(newState)} exitosamente`,
            type: 'success',
          })

          this.setSettings();
        })
        .catch(() => {
          // new snackbar
          this.$store.dispatch('addToast', {
            title: 'Ups!',
            text: `La configuración ${settingName[name]} no fue ${action(newState)}. Intenta nuevamente`,
            type: 'error',
          })
        });
    },
    updateInputSettings() {
      const days = this.expirationDays;
      const subject = this.email.subject;
      // Agregar reuseTestDuration en los parámetros:
      apiCalls.updateInputSetting(days, subject)
        .then(() => {
          this.$store.dispatch('addToast', {
            title: '¡Éxito!',
            text: 'La configuración fue cambiada exitosamente',
            type: 'success',
          })
          this.setSettings();
        })
        .catch(() => {
          this.$store.dispatch('addToast', {
            title: 'Ups!',
            text: 'La configuración no pudo ser modificada. Intenta nuevamente',
            type: 'error',
          })
        });
    },
    changeExpirationDays(days) {
      this.expirationDays = days;
    },
    changeTestDuration(months) {
      this.reuseTestDuration = months;
    },
    changeSubject(subject) {
      this.errors = false;
      if (subject.length < 1) {
        this.errors = true;
      }
      this.email = { ...this.email, subject };
    },
    cleanFlags() {
      this.successFlag = false;
      this.errorFlag = false;
      this.message = '';
    },
  },
};
</script>

<style lang="scss" scoped>

html {
  scroll-behavior: smooth;
}

.settings-container {
  margin: 10px auto 0;
  max-width: 1000px;
}

.title-row {
  display: flex;

  &__title {
    margin-bottom: 1px;
  }

  &__subtitle {
    margin-top: 1px;
  }
}

.save-button-container {
  display: flex;

  &__save-btn {
    margin: 20px 0 0 auto;
  }
}

.settings {
  padding: 80px;
  background-color: #fff;
  border-radius: 4px;
}
</style>

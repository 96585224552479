import axios from 'axios';
import { camelizeKeys } from 'humps';

function getKiExpirationDays() {
  return axios
    .get('/api/v1/ki/ki_settings')
    .then(res => camelizeKeys(res.data))
    .catch(err => {
      throw new Error(err);
    });
}

function updateKiExpirationDays(days) {
  return axios
    .patch('/api/v1/ki/ki_settings/1', { 'expirationDays': days })
    .then(res => camelizeKeys(res.data))
    .catch(err => {
      throw new Error(err);
    });
}

export default {
  getKiExpirationDays,
  updateKiExpirationDays,
};
